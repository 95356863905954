import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import tetherApi from '../../api/tether';
import React, { useEffect, useState } from 'react';

function InvitationForm() {
	const {
		register,
		handleSubmit,
		watch,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({
		defaultValues: {
			sendMethod: 'email',
			role: 'admin',
			groupRole: 'admin',
		},
	});

	let navigate = useNavigate();
	const watchSendMethod = watch('sendMethod');
	const watchRole = watch('role', 'admin');
	const watchGroupId = watch('groupId', '');
	const watchGroupRole = watch('groupRole', 'admin');
	const [groups, setGroups] = useState(null);

	useEffect(() => {
		if (!groups) {
			fetchGroups();
		}
	}, [groups]);

	const fetchGroups = async () => {
		const groupsResponse = await tetherApi.getGroups(1, 1000);

		setGroups(groupsResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitInvitation(data);

		if (result) {
			alertService.success('Invitation sent!');

			navigate('/invitations');
		}
	};

	const submitInvitation = async (data) => {
		const formData = new FormData(document.getElementById('invitation-form'));

		if (data.email === '' || data.email === null) {
			formData.delete('email');
		}

		if (data.phone === '' || data.phone === null) {
			formData.delete('phone');
		}

		if (data.groupId === '' || data.groupid === null) {
			formData.delete('groupId');
			formData.delete('groupRole');
		}

		return await tetherApi.createInvitation(formData);
	};

	return (
		<form className="p-12 space-y-8" id="invitation-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 sm:space-y-5">
				<div>
					<div>
						<h3 className="mb-6 text-3xl tracking-tight font-semibold text-gray-900">New Invitation</h3>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Invite</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select if you want send the invitation by email or SMS.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
									Send Via*
								</label>
								<div className="mt-2">
									<select
										{...register('sendMethod')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="send-method-email" className="font-medium" value="email">
											Email
										</option>
										<option key="send-method-text-message" value="phone">
											SMS (Text Message)
										</option>
									</select>
								</div>
							</div>
							<div className={watchSendMethod === 'email' ? 'col-span-full' : 'hidden'}>
								<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
									Email
								</label>
								<div className="mt-2">
									<input
										{...register('email')}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={
											errors.email?.message ? 'mt-2 text-sm text-red-600 font-regular' : 'hidden'
										}
										id="email-error"
									>
										{errors.email?.message}
									</p>
								</div>
							</div>
							<div className={watchSendMethod === 'phone' ? 'col-span-full' : 'hidden'}>
								<label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
									Phone
								</label>
								<div className="mt-2">
									<input
										{...register('phone')}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md  "
									/>
									<p
										className={errors.phone?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="phone-error"
									>
										{errors.phone?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Add further user details for the invitation
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label
									htmlFor="firstName"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									First Name*
								</label>
								<div className="mt-2">
									<input
										{...register('firstName', {
											required: 'First Name is required',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.firstName?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="firstName-error"
									>
										{errors.firstName?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
									Last Name*
								</label>
								<div className="mt-2">
									<input
										{...register('lastName', {
											required: 'Last Name is required',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.lastName?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="lastName-error"
									>
										{errors.lastName?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
									User Role*
								</label>
								<div className="mt-2">
									<select
										{...register('role')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="role-admin" value="admin">
											Staff Admin
										</option>
										<option key="role-leader" value="leader">
											Group Admin
										</option>
										<option key="role-member" value="member">
											Member
										</option>
									</select>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										{watchRole === 'admin' && 'Full access to Tether account and admin portal'}
										{watchRole === 'leader' &&
											'Access to public and leader-specific content published by your organization'}
										{watchRole === 'member' &&
											'Access to public content published by your organization'}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="groupId" className="block text-sm font-medium leading-6 text-gray-900">
									Group
								</label>
								<div className="mt-2">
									<select
										{...register('groupId')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="group-id-blank" value="">
											Select a Group...
										</option>
										{groups?.map((group) => (
											<option key={`group-id-${group.id}`} value={group.id}>
												{group.name}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className={watchGroupId !== '' ? 'col-span-full' : 'hidden'}>
								<label
									htmlFor="groupRole"
									className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
								>
									Role
								</label>
								<div className="mt-2">
									<select
										{...register('groupRole')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="role-admin" value="admin">
											Admin
										</option>
										<option key="role-member" value="member">
											Member
										</option>
									</select>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										{watchGroupRole === 'admin' &&
											'Access to update, choose plans, and participate in the group'}
										{watchGroupRole === 'member' && 'Access to view and participate in the group'}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<Link
						to="/invitations"
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>
		</form>
	);
}

export default observer(InvitationForm);
