import { makeObservable, observable, runInAction, action, autorun } from 'mobx';

import tetherApi from '../api/tether';

class UserStore {
	user = {};
	jwt = null;

	constructor() {
		makeObservable(this, {
			user: observable,
			setUser: action,
			jwt: observable,
			setJwt: action,
		});

		autorun(this.fetchUser);
		runInAction(this.fetchUser);
	}

	fetchUser = async () => {
		if (this.getJwt()) {
			const userResponse = await tetherApi.me();
			if (userResponse?.data) {
				this.setUser(userResponse.data);
				return userResponse.data;
			}
		}
	};

	setUser = (userData) => {
		this.user = userData;
	};

	getJwt = () => {
		return localStorage.getItem('jwt');
	};

	setJwt = (jwt) => {
		this.jwt = jwt;
		localStorage.setItem('jwt', jwt);
	};

	removeJwt = () => {
		this.jwt = null;
		localStorage.removeItem('jwt');
	};
}

const userStore = new UserStore();

export default userStore;
