import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import tetherApi from '../../api/tether';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Metric, Text, BarChart, Grid, Card, Col } from '@tremor/react';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import Pagination from '../Pagination';

function PathwaySectionStats() {
	const { pathwayId, pathwaySectionId } = useParams();
	const [pathway, setPathway] = useState(null);
	const [pathwaySection, setPathwaySection] = useState(null);
	const [pathwayViews, setPathwayViews] = useState(0);
	const [pathwayCompletions, setPathwayCompletions] = useState(0);
	const [countsByMonth, setCountsByMonth] = useState([]);
	const [sentimentCounts, setSentimentCounts] = useState({});
	const [responses, setResponses] = useState([]);
	const [responsePage, setResponsePage] = useState(1);
	const [totalResponses, setTotalResponses] = useState(0);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			if (pathwayId && pathwaySectionId) {
				const pw = await fetchPathway(pathwayId);
				const pws = await fetchPathwaySection(pathwayId, pathwaySectionId);
				await fetchPathwayViews(pathwayId, pathwaySectionId);
				await fetchTotalCompletions(pw, pws);
				await fetchEngagementOverTime(pathwayId, pathwaySectionId);
				await fetchSentiments(pathwayId, pathwaySectionId);
			}
		};
		fetchData();
	}, [pathwayId]);

	useEffect(() => {
		fetchResponses(pathwayId);
	}, [responsePage, pathwayId]);

	const fetchPathway = async (pathwayId) => {
		const pathwayResponse = await tetherApi.getPathway(pathwayId);

		setPathway(pathwayResponse.data);
		return pathwayResponse.data;
	};

	const fetchPathwaySection = async (pathwayId, pathwaySectionId) => {
		const pathwaySectionResponse = await tetherApi.getPathwaySection(pathwayId, pathwaySectionId);

		setPathwaySection(pathwaySectionResponse.data);
		return pathwaySectionResponse.data;
	};

	const fetchPathwayViews = async (pathwayId, pathwaySectionId) => {
		const viewsResponse = await tetherApi.getPathwayViewsCount(pathwayId, pathwaySectionId);
		setPathwayViews(viewsResponse ?? 0);
	};

	const fetchTotalCompletions = async (pw, pws) => {
		const completionsResponse = await tetherApi.getTotalPathwayCompletions(
			pw.id,
			moment(pws.createdAt).format('YYYY-MM-DD'),
			pws.id,
		);
		setPathwayCompletions(completionsResponse ?? 0);
	};

	const fetchEngagementOverTime = async (pathwayId, pathwaySectionId) => {
		const completedCounts = [];
		const currentMonthCompletedCount = await tetherApi.getTimeBoundedPathwayCompletions(
			pathwayId,
			moment().startOf('month').format('YYYY-MM-DD'),
			undefined,
			pathwaySectionId,
		);
		const currentMonthStartedCount = await tetherApi.getTimeBoundedPathwayStarts(
			pathwayId,
			moment().startOf('month').format('YYYY-MM-DD'),
			undefined,
			pathwaySectionId,
		);
		completedCounts.push({
			month: moment().format('MMM'),
			Completions: currentMonthCompletedCount ?? 0,
			Started: currentMonthStartedCount ?? 0,
		});
		const offsets = [1, 2, 3, 4, 5];
		for await (const offset of offsets) {
			const momentForMonth = moment().subtract(offset, 'month');
			const startDate = momentForMonth.startOf('month').format('YYYY-MM-DD');
			const endDate = momentForMonth.endOf('month').format('YYYY-MM-DD');
			const count = await tetherApi.getTimeBoundedPathwayCompletions(
				pathwayId,
				startDate,
				endDate,
				pathwaySectionId,
			);
			const started = await tetherApi.getTimeBoundedPathwayStarts(
				pathwayId,
				startDate,
				endDate,
				pathwaySectionId,
			);
			completedCounts.unshift({
				month: momentForMonth.format('MMM'),
				Completions: count ?? 0,
				Started: started ?? 0,
			});
		}
		setCountsByMonth(completedCounts);
	};

	const fetchSentiments = async (pathwayId, pathwaySectionId) => {
		const sentiments = await tetherApi.getPathwaySectionsSentiments(pathwayId, pathwaySectionId);
		setSentimentCounts(sentiments);
	};

	const fetchResponses = async (pathwayId, pathwaySectionId) => {
		const anonResponses = await tetherApi.getPathwayAnonymousResponses(
			pathwayId,
			pathwaySectionId,
			responsePage,
			10,
		);
		setResponses(anonResponses.data);
		setTotalResponses(anonResponses.total);
		setStartIndex(anonResponses.total > 0 ? 10 * (responsePage - 1) + 1 : 0);
		setEndIndex(10 * (responsePage - 1) + anonResponses.data.length);
	};

	if (!pathway || !pathwaySection) {
		return <div></div>;
	}

	return (
		<div className="px-8">
			<div className="flex flex-row pt-8">
				{pathway?.imageUrl ? (
					<img
						src={pathway.secondaryImageUrl ?? pathway.imageUrl}
						className="w-24 h-24 rounded-lg object-cover"
					/>
				) : (
					<div className="w-24 h-24 rounded-lg bg-gray-300"></div>
				)}
				<div className="pl-8 pt-4">
					<p className="text-2xl pb-.5 font-semibold text-gray-700 ">{pathwaySection.name}</p>
					<p className="text-base font-regular text-gray-500 ">{`Published ${moment(
						pathwaySection.createdAt,
					).format('MMMM Do, YYYY')} at ${moment(pathwaySection.createdAt).format('h:mma')}`}</p>
				</div>
			</div>
			<Grid numItemsLg={6} className="gap-6 mt-6">
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Starts</Text>
								<Metric className="font-medium">{pathwayViews}</Metric>
							</div>
						</div>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Completions</Text>
								<Metric className="font-medium">{pathwayCompletions}</Metric>
							</div>
						</div>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div>
							<Text className="font-regular">Section Rating</Text>
							{/* <Text className="font-regular">Average of sections</Text> */}
							<div className="flex flex-row pt-4">
								{Object.keys(sentimentCounts).map((key) => (
									<div className="flex flex-col items-center px-1" key={key}>
										<Text className="font-regular">{capitalizeFirstLetter(key)}</Text>
										<Text className="font-semibold">{`${
											sentimentCounts[key].percentage
												? sentimentCounts[key].percentage.toFixed(1)
												: 0
										}%`}</Text>
									</div>
								))}
							</div>
						</div>
					</Card>
				</Col>
			</Grid>
			<Grid numItemsLg={6} className="gap-6 mt-6">
				<Col className="h-full" numColSpanLg={4}>
					<Card className="h-full">
						<Text className="font-regular">Section Engagement</Text>
						{/* <Text className="font-regular mb-4">*Plan sections completed</Text> */}
						<BarChart
							className="mt-6"
							data={countsByMonth}
							index="month"
							categories={['Started', 'Completions']}
							colors={[
								'violet',
								'blue',
								'blue',
								'sky',
								'cyan',
								'slate',
								'green',
								'pink',
								'orange',
								'red',
							]}
							yAxisWidth={48}
							// showTooltip={false}
							showLegend={false}
						/>
					</Card>
				</Col>
			</Grid>
			<Grid numItemsLg={6} className="gap-6 mt-6">
				<Col numColSpanLg={6}>
					<Card>
						<Text className="font-regular mb-4">Anonymous Responses</Text>
						{responses.map((resp) => (
							<Text className="mb-2 font-regular" key={resp.id}>{`"${resp.mood.body}"`}</Text>
						))}
						<Pagination
							page={responsePage}
							setPage={setResponsePage}
							numPages={Math.ceil(totalResponses / 10)}
							startIndex={startIndex}
							endIndex={endIndex}
							total={totalResponses}
						/>
					</Card>
				</Col>
			</Grid>
		</div>
	);
}

export default observer(PathwaySectionStats);
