import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import 'react-datepicker/dist/react-datepicker.css';
import Confirm from '../Confirm';
import { humanize } from '../../utils/stringUtils';

function ImportForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({});

	const { importRecordId } = useParams();
	const [importRecord, setImportRecord] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (importRecordId && !importRecord && orgsStore.orgs.length) {
			fetchImport(importRecordId);
		}

		setSuccessMessage(importRecordId ? 'Import updated!' : 'Import created!');
	}, [importRecord, orgsStore.currentOrg, deleteConfirm]);

	const fetchImport = async (importRecordId) => {
		const importRecordResponse = await tetherApi.getImport(importRecordId);

		setImportRecord(importRecordResponse.data);

		reset(importRecordResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitImport(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/imports');
		}
	};

	const submitImport = async (data) => {
		const formData = new FormData(document.getElementById('importRecord-form'));

		if (data['csvFile'][0]) {
			formData.append('csvFile', data['csvFile'][0]);
		}

		return await tetherApi.createImport(formData);
	};

	const deleteImport = async () => {
		const result = await tetherApi.deleteImport(importRecordId);

		if (result) {
			alertService.success('Import deleted');

			navigate('/imports');
		}
	};

	return (
		<form className="p-10" id="importRecord-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-lg leading-6 font-medium text-gray-900 ">
							{importRecordId ? 'View' : 'New'} Import
						</h3>
					</div>

					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
						<div
							className={
								importRecordId
									? 'hidden'
									: 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
							}
						>
							<label
								htmlFor="orgId"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Organization
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<select
									{...register('orgId')}
									className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
								>
									<option key="step-type-blank" value="">
										Select an Org...
									</option>
									{orgsStore?.orgs?.map((org) => (
										<option key={`org-${org.id}`} value={org.id}>
											{org.name}
										</option>
									))}
								</select>
								<p
									className={errors.orgId?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="practice-error"
								>
									{errors.orgId?.message}
								</p>
							</div>
						</div>

						<div
							className={
								importRecordId
									? 'hidden'
									: 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
							}
						>
							<label
								htmlFor="importType"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Import Type
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<select
									{...register('importType', { required: 'Import Type is required' })}
									className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
								>
									<option key="importType-invitations" value="invitations">
										Invitations
									</option>
									<option key="importType-org_destroy" value="org_destroy">
										Delete
									</option>
								</select>
								<p
									className={errors.importType?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="importType-error"
								>
									{errors.importType?.message}
								</p>
							</div>
						</div>

						<div className={importRecordId ? 'hidden' : 'mt-6 sm:mt-5 space-y-6 sm:space-y-5'}>
							<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
								<label
									htmlFor="csvFile"
									className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
								>
									CSV File
								</label>
								<div className="mt-1 sm:mt-0 sm:col-span-2">
									<input
										{...register('csvFile')}
										type="file"
										name="csvFile"
										id="csvFile"
										accept=".csv"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm  "
									/>
									<p className="mt-2 text-sm text-gray-500 ">Accepts .csv</p>

									<div className={importRecord?.csvFile ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={importRecord?.csvFile}
											alt=""
											className={importRecord?.csvFile ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
						</div>

						<div
							className={
								importRecordId
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="description"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Status
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">{humanize(importRecord?.status)}</div>
						</div>

						<div
							className={
								importRecordId
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="description"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Preview Stats
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<div
									key="preview_not_complete"
									className={
										importRecord && Object.keys(importRecord.previewStats).length > 0
											? 'hidden'
											: ''
									}
								>
									Preview not yet complete
								</div>

								{Object.entries(importRecord?.previewStats || {}).map(([key, value]) => (
									<div
										key={`previewStats-${key}`}
										className="bg-white  py-6 sm:grid sm:grid-cols-3 sm:gap-4"
									>
										<dt className="text-sm font-medium leading-6 text-gray-900 ">
											{humanize(key)}
										</dt>
										<dd className="mt-1 text-sm leading-6 text-gray-700  sm:col-span-2 sm:mt-0">
											{value}
										</dd>
									</div>
								))}
							</div>
						</div>

						<div
							className={
								importRecordId
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="description"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Import Stats
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<div
									key="import_not_complete"
									className={
										importRecord && Object.keys(importRecord.importStats).length > 0 ? 'hidden' : ''
									}
								>
									Import not yet complete
								</div>

								{Object.entries(importRecord?.importStats || {}).map(([key, value]) => (
									<div
										key={`importStats-${key}`}
										className="bg-white  py-6 sm:grid sm:grid-cols-3 sm:gap-4"
									>
										<dt className="text-sm font-medium leading-6 text-gray-900 ">
											{humanize(key)}
										</dt>
										<dd className="mt-1 text-sm leading-6 text-gray-700  sm:col-span-2 sm:mt-0">
											{value}
										</dd>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							importRecordId &&
							importRecord?.status !== 'import_processing' &&
							importRecord?.status !== 'import_complete'
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						to="/imports"
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
						type="submit"
						disabled={!isDirty || isSubmitting}
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Import"
				body="This will delete the Import and cannot be undone. Are you sure?"
				onConfirm={deleteImport}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(ImportForm);
