import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import { Link } from 'react-router-dom';
import { humanize } from '../../utils/stringUtils';
import Pagination from '../Pagination';

function OrgsList() {
	const [page, setPage] = useState(1);
	const [total] = useState(0);
	const [numPages] = useState(1);
	const [startIndex] = useState(0);
	const [endIndex] = useState(0);
	const [orgs, setOrgs] = useState([]);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			setOrgs(orgsStore.orgs);
		}
	}, [orgsStore.orgs]);

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-3xl font-semibold text-gray-900">All Organizations</h1>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="/orgs/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
					>
						New Organization
					</Link>
				</div>
			</div>
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="">
									<tr>
										<th
											scope="col"
											className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
										>
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Tier
										</th>
										{/* <th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Description
										</th> */}
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											City
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											State
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Edit</span>
										</th>
									</tr>
								</thead>

								<tbody className="divide-y divide-gray-200 bg-white">
									{orgs.map((org, orgIdx) => (
										<tr key={org.id} className={orgIdx % 2 === 0 ? undefined : ''}>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
												{org.name}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{humanize(org.tier)}
											</td>
											{/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{org.description}
											</td> */}
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{org.city}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{org.state}
											</td>
											<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
												<Link
													to={`/orgs/${org.id}`}
													className="text-neutral-800 hover:text-neutral-900"
												>
													Edit
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(OrgsList);
