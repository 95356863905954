import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import orgsStore from '../../stores/OrgsStore';
import { observer } from 'mobx-react-lite';
import tetherApi from '../../api/tether';
import 'react-datepicker/dist/react-datepicker.css';
import { Metric, Text, Grid, Card, Col } from '@tremor/react';

function LeaderResourceStats() {
	const { leaderResourceId } = useParams();
	const [leaderResource, setLeaderResource] = useState(null);
	const [resourceVisits, setResourceVisits] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			if (leaderResourceId) {
				await fetchLeaderResource(leaderResourceId);
				await fetchLeaderResourceVisits(leaderResourceId);
			}
		};
		fetchData();
	}, [leaderResourceId]);

	const fetchLeaderResource = async (leaderResourceId) => {
		const leaderResourceResponse = await tetherApi.getLeaderResource(leaderResourceId);

		setLeaderResource(leaderResourceResponse.data);
	};

	const fetchLeaderResourceVisits = async (leaderResourceId) => {
		const resourceViewResponse = await tetherApi.getAhoyMetricsCount({
			org_id: orgsStore.currentOrg.id,
			resource_id: leaderResourceId,
		});
		setResourceVisits(resourceViewResponse ?? 0);
	};

	if (!leaderResource) {
		return <div></div>;
	}

	return (
		<div className="px-8">
			<Grid numItemsLg={8} className="gap-6 mt-6">
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Visits</Text>
								<Metric className="font-medium">{resourceVisits}</Metric>
							</div>
						</div>
					</Card>
				</Col>
			</Grid>
		</div>
	);
}

export default observer(LeaderResourceStats);
