import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination';

function CheckInPeriodImagesList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [checkInPeriodImages, setCheckInPeriodImages] = useState([]);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchCheckInPeriodImages();
		}
	}, [page, orgsStore.currentOrg]);

	const fetchCheckInPeriodImages = async () => {
		const checkInPeriodImagesResponse = await tetherApi.getCheckInPeriodImages(page, perPage);

		setCheckInPeriodImages(checkInPeriodImagesResponse.data);

		const totalCount = checkInPeriodImagesResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + checkInPeriodImagesResponse.data.length);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900 ">Check In Period Images</h1>
					<p className="mt-2 text-sm text-gray-700 ">
						A list of all Check In Period Images that rotate across Check In Periods
					</p>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="/check_in_period_images/new"
						className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
					>
						Add Check In Period Image
					</Link>
				</div>
			</div>
			<div className="mt-8 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300 ">
								<thead className="bg-gray-50 ">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6"
										>
											ID
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
										>
											Description
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
										>
											Preview
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Edit</span>
										</th>
									</tr>
								</thead>
								<tbody className="bg-white ">
									{checkInPeriodImages.map((checkInPeriodImage, checkInPeriodImageIdx) => (
										<tr
											key={checkInPeriodImage.id}
											className={checkInPeriodImageIdx % 2 === 0 ? undefined : 'bg-gray-50 '}
										>
											<td className="py-4 pl-4 pr-3 text-sm text-gray-900  sm:pl-6">
												{checkInPeriodImage.id}
											</td>
											<td className="px-3 py-4 text-sm text-gray-500 ">
												{checkInPeriodImage.description}
											</td>
											<td className="px-3 py-4 text-sm text-gray-500 ">
												<img
													className="w-16"
													alt={checkInPeriodImage.description}
													src={checkInPeriodImage.imageUrl}
												/>
											</td>
											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
												<Link
													to={`/check_in_period_images/${checkInPeriodImage.id}`}
													className="text-neutral-800 hover:text-neutral-900"
												>
													Edit
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(CheckInPeriodImagesList);
