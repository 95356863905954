import { makeObservable, observable, runInAction, action, autorun } from 'mobx';

import userStore from './UserStore';
import tetherApi from '../api/tether';

class OrgsStore {
	orgs = [];
	currentOrg = null;

	constructor() {
		makeObservable(this, {
			orgs: observable,
			currentOrg: observable,
			setOrgs: action,
			setCurrentOrg: action,
			selectOrg: action,
		});

		autorun(this.fetchOrgs);
		runInAction(this.fetchOrgs);
	}

	fetchOrgs = async () => {
		if (userStore.user?.id) {
			if (userStore.user.isSuperUser === true) {
				const orgsResponse = await tetherApi.getOrgs();

				if (orgsResponse?.data) {
					this.setOrgs(orgsResponse.data);
				}

				if (localStorage.getItem('orgId')) {
					this.selectOrg(localStorage.getItem('orgId'));
				} else {
					this.setCurrentOrg(this.orgs[0]);
				}
			} else {
				this.setOrgs(userStore.user.orgs);

				if (localStorage.getItem('orgId')) {
					this.selectOrg(localStorage.getItem('orgId'));
				} else {
					this.setCurrentOrg(this.orgs[0]);
				}
			}
		}
	};

	setOrgs = (orgsData) => {
		this.orgs = orgsData;
	};

	setCurrentOrg = (orgData) => {
		if (orgData?.id) {
			this.currentOrg = orgData;
			localStorage.setItem('orgId', this.currentOrg?.id);
			localStorage.setItem('orgTier', this.currentOrg?.tier);
		} else if (userStore.user?.isSuperUser) {
			this.currentOrg = null;
			localStorage.setItem('orgId', undefined);
			localStorage.setItem('orgTier', undefined);
		}
	};

	removeCurrentOrg = () => {
		localStorage.removeItem('orgId');
		localStorage.removeItem('orgTier');
	};

	getOrgTier = () => {
		return localStorage.getItem('orgTier');
	};

	getOrgStatus = () => {
		return localStorage.getItem('orgStatus');
	};

	selectOrg = (orgId) => {
		const orgAtIndex = this.orgs.find((org) => parseInt(org.id) === parseInt(orgId));

		this.setCurrentOrg(orgAtIndex);
	};
}

const orgsStore = new OrgsStore();

export default orgsStore;
