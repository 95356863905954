import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import React from 'react';

export default function Pagination({ page, setPage, numPages, startIndex, endIndex, total, onPageChange }) {
	const [paginationPages, setPaginationPages] = React.useState([]);
	React.useEffect(() => {
		let newPaginationPages = [];
		const pagesArray = Array.from({ length: numPages }, (_, i) => i + 1);
		if (page <= 5) {
			newPaginationPages = pagesArray.slice(0, 10);
		} else {
			newPaginationPages = pagesArray.slice(page - 5, page + 5);
			if (newPaginationPages.length !== 10) {
				newPaginationPages = pagesArray.slice(pagesArray.length - 10);
			}
		}
		setPaginationPages(newPaginationPages);
	}, [page, numPages]);

	const handleNext = (event) => {
		event.preventDefault();

		if (onPageChange) {
			onPageChange();
		}

		setPage(page + 1);
	};

	const handlePrev = (event) => {
		event.preventDefault();

		if (onPageChange) {
			onPageChange();
		}

		setPage(page - 1);
	};

	const handlePage = (event) => {
		event.preventDefault();

		if (onPageChange) {
			onPageChange();
		}

		const buttonPage = parseInt(event.target.dataset.page);

		setPage(buttonPage);
	};

	return (
		<div className="flex items-center justify-between bg-white py-6">
			<div className="flex-1 flex justify-between sm:hidden">
				<a
					href="/"
					className={
						page === 1
							? 'hidden'
							: 'relative inline-flex items-center px-4 py-2 border border-gray-300  text-sm font-medium rounded-md text-gray-700  bg-white  hover:bg-gray-50  '
					}
					onClick={handlePrev}
				>
					Previous
				</a>
				<a
					href="/"
					className={
						page === numPages
							? 'hidden'
							: 'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300  text-sm font-medium rounded-md text-gray-700  bg-white  hover:bg-gray-50  '
					}
					onClick={handleNext}
				>
					Next
				</a>
			</div>
			<div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
				<div className="hidden sm:block">
					<p className="text-sm text-gray-700">
						Showing <span className="font-medium">{startIndex}</span> to{' '}
						<span className="font-medium">{endIndex}</span> of <span className="font-medium">{total}</span>{' '}
						results
					</p>
				</div>
				<div>
					<nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
						<a
							href="/"
							className={
								page === 1
									? 'hidden'
									: 'relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
							}
							onClick={handlePrev}
						>
							<span className="sr-only">Previous</span>
							<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
						</a>
						{paginationPages.map((pg, i) => (
							<a
								href="/"
								key={`page-${i}`}
								aria-current="page"
								className={
									pg === page
										? 'relative z-10 inline-flex items-center bg-neutral-800 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-800'
										: 'bg-white  border-gray-300  text-gray-500  hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm'
								}
								data-page={pg}
								onClick={handlePage}
							>
								{pg}
							</a>
						))}
						<a
							href="/"
							className={
								page === numPages
									? 'hidden'
									: 'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300  bg-white text-sm font-medium text-gray-500  hover:bg-gray-50  '
							}
							onClick={handleNext}
						>
							<span className="sr-only">Next</span>
							<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
						</a>
					</nav>
				</div>
			</div>
		</div>
	);
}
