import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { alertService } from '../../services/alert.service';
import Confirm from '../Confirm';
import Pagination from '../Pagination';

function ExportsList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [exports, setExports] = useState([]);
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [deleteExportId, setDeleteExportId] = useState(null);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchExports();
		}
	}, [page, orgsStore.currentOrg]);

	const tz = moment.tz.guess();

	const fetchExports = async () => {
		const exportsResponse = await tetherApi.getExports(page, perPage);

		setExports(exportsResponse.data);

		const totalCount = exportsResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + exportsResponse.data.length);
	};

	const deleteExport = async () => {
		if (deleteExportId) {
			const result = await tetherApi.deleteExport(deleteExportId);

			if (result) {
				alertService.success('Export deleted!');
			}

			setDeleteExportId(null);
			setDeleteConfirm(false);

			fetchExports();
		}
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-2xl font-semibold text-black  pt-1">Exports</h1>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
			</div>

			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{exports.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No members to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No members are available.
										</p>
										<Link
											to="/users/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New Member
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Type
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Total
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Processed
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Created By
											</th>
											{/* <th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Started
										</th> */}
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Completed
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Action
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{exports.map((exportRecord, exportRecordIdx) => (
											<tr
												key={exportRecord.id}
												className={exportRecordIdx % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
													{exportRecord.exportTypeLabel}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xs truncate">
													{exportRecord.totalCount}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
													{exportRecord.processedCount}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-sm truncate">
													{exportRecord.user?.email}
												</td>
												{/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-sm truncate">
												{exportRecord.startedAt &&
													moment
														.tz(exportRecord.startedAt, tz)
														.format('MMMM Do, YYYY h:MM A')}
											</td> */}
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate font-regular">
													{exportRecord.completedAt &&
														moment
															.tz(exportRecord.completedAt, tz)
															.format('MMMM Do, YYYY h:MM A')}
												</td>
												<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 font-regular">
													<button
														type="button"
														onClick={() => {
															setDeleteConfirm(true);
															setDeleteExportId(exportRecord.id);
														}}
														className="mr-3 justify-center py-1 px-2 border border-transparent text-sm font-medium rounded-md text-red-800 bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
													>
														Delete
													</button>
													<Link
														to={exportRecord.csvFileUrl}
														className={
															exportRecord.csvFileUrl
																? 'inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-2 py-1 text-sm font-medium text-green-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto'
																: 'invisible inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-2 py-1 text-xs font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto'
														}
													>
														Download
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>

			<Confirm
				isOpen={deleteConfirm}
				title="Remove Export"
				body="This will remove the export record and its data. Are you sure?"
				onConfirm={deleteExport}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</div>
	);
}

export default observer(ExportsList);
