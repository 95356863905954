import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';

function AccessCodeForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm();

	const { pathwayId } = useParams();
	const { accessCodeId } = useParams();
	const [accessCode, setAccessCode] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (accessCodeId && !accessCode && orgsStore.orgs.length) {
			fetchAccessCode(accessCodeId);
		}

		setSuccessMessage(accessCodeId ? 'AccessCode updated!' : 'AccessCode created!');
	}, [accessCode, orgsStore.currentOrg]);

	const fetchAccessCode = async (accessCodeId) => {
		const accessCodeResponse = await tetherApi.getAccessCode(pathwayId, accessCodeId);

		setAccessCode(accessCodeResponse.data);

		reset(accessCodeResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitAccessCode(data);

		if (result) {
			alertService.success(successMessage);

			navigate(`/plans/${pathwayId}/access_codes`);
		}
	};

	const submitAccessCode = async (data) => {
		const formData = new FormData(document.getElementById('accessCode-form'));
		if (data.urlString && data.urlString.slice(0, 8) !== 'https://') {
			formData.set('urlString', `https://${data.urlString}`);
		}

		formData.append('isFeatured', data.isFeatured);

		if (data.id) {
			return await tetherApi.updateAccessCode(data.id, formData);
		} else {
			return await tetherApi.createAccessCode(pathwayId, formData);
		}
	};

	const deleteAccessCode = async () => {
		const result = await tetherApi.deleteAccessCode(pathwayId, accessCodeId);

		if (result) {
			alertService.success('AccessCode deleted');

			navigate(`/plans/${pathwayId}/access_codes`);
		}
	};

	return (
		<form
			className="p-12 space-y-8 divide-y divide-gray-200 "
			id="accessCode-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="mb-6 text-3xl  font-semibold text-gray-900 ">
							{accessCodeId ? 'Edit' : 'New'} Access Code
						</h3>
					</div>
					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
							<label
								htmlFor="name"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Name*
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('name', {
										required: 'Name is required',
										maxLength: 'Name must be 255 characters or less',
										disabled: accessCodeId?.length ? true : false,
									})}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.name?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="name-error"
								>
									{errors.name?.message}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							accessCodeId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						to={`/plans/${pathwayId}/access_codes`}
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting || accessCodeId}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Generate
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete AccessCode"
				body="This will delete the AccessCode and cannot be undone. Are you sure?"
				onConfirm={deleteAccessCode}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(AccessCodeForm);
