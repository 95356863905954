export function humanize(s) {
	if (s) {
		return titleCase(underscoresToSpaces(camelToSnake(s)));
	} else {
		return '';
	}
}

export function formatPhoneNumber(phoneNumberString) {
	const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return `(${match[1]}) ${match[2]}-${match[3]}`;
	}
	return phoneNumberString;
}

export function titleCase(s) {
	return s
		.toLowerCase()
		.split(' ')
		.map(function (word) {
			return word.replace(word[0], word[0].toUpperCase());
		})
		.join(' ');
}

export function underscoresToSpaces(s) {
	return s.replace(/_/g, ' ');
}

export function camelToSnake(s) {
	return s.replace(/[A-Z]/g, (c) => {
		return '_' + c.toLowerCase();
	});
}

export function truncate(s, length, ending = '...') {
	if (length == null) {
		length = 100;
	}
	if (ending == null) {
		ending = '...';
	}
	if (s.length > length) {
		return s.substring(0, length - ending.length) + ending;
	} else {
		return s;
	}
}

export function formatMoney(cents) {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	}).format(cents / 100.0);
}

export function capitalizeFirstLetter(string) {
	return string
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.substring(1))
		.join(' ');
}

export function getAhoyScreenLabel(string) {
	switch (string) {
		case 'allResources':
			return 'All Resources';
		case 'resourceFolder':
			return 'Resource Folder';
		case 'challenge':
			return 'Challenge Details';
		case 'collection':
			return 'Collection Details';
		case 'explore':
			return 'Explore';
		case 'filteredExplore':
			return 'Filtered Explore';
		case 'orgHabitDetails':
			return 'Habit Details';
		case 'pathwayOverview':
			return 'Plan';
		case 'pathwaySection':
			return 'Plan Section';
		case 'pathwaySectionStep':
			return 'Plan Section Step';
		case 'pathwaySectionSteps':
			return 'Plan Section All Steps';
		case 'practiceOverview':
			return 'Practice Overview';
		case 'practiceSteps':
			return 'Practice Steps';
		case 'courseSectionSteps':
			return 'Course Section All Steps';
		case 'courseSectionStep':
			return 'Course Section Step';
		case 'courseSection':
			return 'Course Section';
		case 'courseOverview':
			return 'Course';
	}
}
