import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useRedirectToHttps = () => {
	let location = useLocation();

	useEffect(() => {
		if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
			const host = window.location.host;

			window.location.replace('https://' + host + location.pathname);
		}
	});
};

export default useRedirectToHttps;
