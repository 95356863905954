import { HomeIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { humanize } from '../utils/stringUtils';

function BreadCrumbs() {
	const [pages, setPages] = useState([]);

	const location = useLocation();

	useEffect(() => {
		if (pages) {
			setPages(buildPagesFromLocation());
		}
	}, [location]);

	// Special handling for entities that don't have their own index view
	const partsWithoutIndexView = [
		'plan_sections',
		'plan_section_steps',
		'practice_steps',
		'leader_resource_folders',
		'assessment_questions',
		'assessment_action_links',
		'collection_items',
	];

	const buildPagesFromLocation = () => {
		const parts = location.pathname.split('/');

		let deepLink = '';
		let prevPart = null;

		let pages = parts
			.filter((part) => {
				return !['', 'dashboard'].includes(part);
			})
			.map((part) => {
				deepLink = deepLink + `/${part}`;

				let name = '';

				// Special handling for entities that don't have their own index view
				if (!partsWithoutIndexView.includes(part)) {
					if (part.match(/\d+/)) {
						// Editing a particular record
						name = `Edit ${humanize(prevPart.replace(/s$/, ''))}`;
					} else if (part === 'new') {
						name = `Add ${humanize(prevPart.replace(/s$/, ''))}`;
					} else {
						name = humanize(part);
					}
				}

				prevPart = part;

				return {
					name: name,
					href: deepLink,
				};
			})
			.filter((page) => page.name !== '');

		return pages;
	};

	// if (window.location.href.includes('/dashboard') || window.location.pathname === '/') {
	// 	return <div className="md:h-8"></div>;
	// }

	if (window.location.href.includes('/dashboard') || window.location.pathname === '/') {
		return (
			<nav className="flex border-b pb-4 px-4 md:px-12" aria-label="Breadcrumb">
				<ol role="list" className="flex w-full max-w-screen-xl space-x-4 h-8">
					<li className="flex">
						<div className="flex items-center">
							<Link to="/" className="text-gray-400 hover:text-gray-500">
								<HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
							</Link>
						</div>
					</li>
					<li className="flex">
						<div className="flex items-center">
							<ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
							<Link className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 capitalize">
								Home
							</Link>
						</div>
					</li>
				</ol>
			</nav>
		);
	}

	return (
		<nav className="flex border-b pb-4 px-4 md:px-12" aria-label="Breadcrumb">
			<ol role="list" className="flex w-full max-w-screen-xl space-x-4 h-8">
				<li className="flex">
					<div className="flex items-center">
						<Link to="/" className="text-gray-400 hover:text-gray-500">
							<HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
							<span className="sr-only">Home</span>
						</Link>
					</div>
				</li>
				{pages.map((page) => (
					<li key={page.name} className="flex">
						<div className="flex items-center">
							<ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
							<Link
								to={page.href}
								className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 capitalize"
								aria-current={page.current ? 'page' : undefined}
							>
								{page.name}
							</Link>
						</div>
					</li>
				))}
			</ol>
		</nav>
	);
}

export default observer(BreadCrumbs);
