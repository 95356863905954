import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';

function GoalForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({});

	const { goalId } = useParams();
	const [goal, setGoal] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (goalId && !goal && orgsStore.orgs.length) {
			fetchGoal(goalId);
		}

		setSuccessMessage(goalId ? 'Goal updated!' : 'Goal created!');
	}, [goal, orgsStore.currentOrg, deleteConfirm]);

	const fetchGoal = async (goalId) => {
		const goalResponse = await tetherApi.getGoal(goalId);

		setGoal(goalResponse.data);

		reset(goalResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitGoal(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/goals');
		}
	};

	const submitGoal = async (data) => {
		const formData = new FormData(document.getElementById('goal-form'));

		if (data.id) {
			return await tetherApi.updateGoal(data.id, formData);
		} else {
			return await tetherApi.createGoal(formData);
		}
	};

	const deleteGoal = async () => {
		const result = await tetherApi.deleteGoal(goalId);

		if (result) {
			alertService.success('Goal deleted');

			navigate('/goals');
		}
	};

	return (
		<form className="p-12 space-y-8 divide-y divide-gray-200 " id="goal-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-lg leading-6 font-medium text-gray-900 ">{goalId ? 'Edit' : 'New'} Goal</h3>
					</div>

					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="title"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Title
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('title', { required: 'Title is required' })}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
								/>
								<p
									className={errors.title?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="title-error"
								>
									{errors.title?.message}
								</p>
							</div>
						</div>

						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="description"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Description
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<textarea
									{...register('description', {
										required: 'Overview is required',
									})}
									rows={3}
									className="max-w-lg shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300  rounded-md  "
								/>
								<p
									className={errors.description?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="description-error"
								>
									{errors.description?.message}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							goalId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						type="button"
						to="/goals"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Goal"
				body="This will delete this Goal and cannot be undone. Are you sure?"
				onConfirm={deleteGoal}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(GoalForm);
