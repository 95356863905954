import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';

function IntegrationsIndex() {
	useEffect(() => {}, [orgsStore.currentOrg]);

	const clientId = process.env.REACT_APP_PLANNING_CENTER_CLIENT_ID;
	const redirectUri = process.env.REACT_APP_PLANNING_CENTER_REDIRECT_URI;
	const connectUrl = `https://api.planningcenteronline.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=people%20groups`;

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<h3 className="text-lg leading-6 font-medium text-gray-900 ">Integrations</h3>
			<div className="bg-white  px-4 py-5 sm:px-6">
				<p className="text-md mt-4">
					<a href={connectUrl}>Connect Planning Center</a>
				</p>
			</div>
		</div>
	);
}

export default observer(IntegrationsIndex);
