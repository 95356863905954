import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';
import 'react-datepicker/dist/react-datepicker.css';
import userStore from '../../stores/UserStore';
import moment from 'moment';
import { Metric, Text, Grid, Card, Col } from '@tremor/react';
import ReactDatePicker from 'react-datepicker';

function UserForm() {
	const {
		handleSubmit,
		reset,
		register,
		watch,
		formState: { isSubmitting, isDirty, errors },
	} = useForm();

	const { userId } = useParams();
	const [user, setUser] = useState(null);
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	const [showPhone, setShowPhone] = useState(false);
	const [orgPathways, setOrgPathways] = useState([]);
	const [addingPlan, setAddingPlan] = useState(false);
	const [userPlans, setUserPlans] = useState([]);
	const [selectedPlanId, setSelectedPlanId] = useState(null);
	const [planSelectedStartDate, setPlanSelectedStartDate] = useState(moment().format('YYYY-MM-DD'));
	const [addPlanLoading, setAddPlanLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState('Details');

	let navigate = useNavigate();

	const watchRole = watch('role', 'admin');

	useEffect(() => {
		const fetchData = async () => {
			if (userId && !user && orgsStore.orgs.length) {
				await fetchUser(userId);
				await fetchPathways();
			}
		};
		fetchData();
	}, [user, orgsStore.currentOrg]);

	const fetchPathways = async () => {
		let allPathways = [];
		const { data: pathwaysPage, total } = await tetherApi.getPathways(1, 100);
		allPathways = allPathways.concat(pathwaysPage);
		if (total > 100) {
			const { data: pathwaysPage2 } = await tetherApi.getPathways(2, 100);
			allPathways = allPathways.concat(pathwaysPage2);
		}
		setOrgPathways(allPathways);
	};

	const fetchUser = async (userId) => {
		const userResponse = await tetherApi.getUser(userId);

		setUser(userResponse.data);

		reset(userResponse.data);
		if (userResponse.data.plans) {
			setUserPlans(userResponse.data.plans.reverse());
		}
		return userResponse.data;
	};

	const onSubmit = async (data) => {
		const result = await submitUser(data);

		if (result) {
			alertService.success('User updated!');

			navigate('/users');
		}
	};

	const noOrgSelected = () => {
		return orgsStore.currentOrg === null;
	};

	const submitUser = async (data) => {
		const formData = new FormData(document.getElementById('user-form'));

		formData.append('isOwner', data.isOwner);

		return await tetherApi.updateUser(data.id, formData);
	};

	const deleteUser = async () => {
		const result = await tetherApi.deleteUser(userId);

		if (result) {
			alertService.success('User is queued for deletion');

			navigate('/users');
		}
	};

	const renderDelete = () => {
		return (
			<button
				type="button"
				onClick={() => setDeleteConfirm(true)}
				className="mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
			>
				Delete
			</button>
		);
		// }
	};

	const renderFirstName = () => {
		if (userStore.user.isSuperUser === true) {
			return (
				<div className="mt-2">
					<input
						{...register('firstName', {
							required: 'First Name is required',
						})}
						type="text"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
					/>
					<p className={errors.firstName?.message ? 'mt-2 text-sm text-red-600' : 'hidden'} id="name-error">
						{errors.firstName?.message}
					</p>
				</div>
			);
		} else {
			return <div className="mt-2 sm:mt-0 sm:col-span-2  font-regular text-base">{user?.firstName}</div>;
		}
	};

	const renderOrgs = () => {
		if (userStore.user.isSuperUser === true) {
			return (
				<div className="col-span-full">
					<label htmlFor="orgs" className="block text-sm font-medium leading-6 text-gray-900">
						Organizations
					</label>
					<div className="mt-2">{user?.orgs?.join(', ')}</div>
				</div>
			);
		}
	};

	const renderLastName = () => {
		if (userStore.user.isSuperUser === true) {
			return (
				<div className="mt-2">
					<input
						{...register('lastName', {
							required: 'Last Name is required',
						})}
						type="text"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
					/>
					<p className={errors.lastName?.message ? 'mt-2 text-sm text-red-600' : 'hidden'} id="name-error">
						{errors.lastName?.message}
					</p>
				</div>
			);
		} else {
			return <div className="mt-2 sm:mt-0 sm:col-span-2  font-regular text-base">{user?.lastName}</div>;
		}
	};

	const renderEmail = () => {
		if (userStore.user.isSuperUser === true) {
			return (
				<div className="mt-2">
					<input
						{...register('email', {
							required: 'Email is required',
						})}
						type="text"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
					/>
					<p className={errors.email?.message ? 'mt-2 text-sm text-red-600' : 'hidden'} id="name-error">
						{errors.email?.message}
					</p>
				</div>
			);
		} else {
			return (
				<div className={`mt-2 sm:mt-0 font-regular text-base sm:col-span-2${showEmail ? ' ' : ' blur-sm'} `}>
					{user?.email}
				</div>
			);
		}
	};

	const renderPhone = () => {
		if (userStore.user.isSuperUser === true) {
			return (
				<div className="mt-2">
					<input
						{...register('phone', {
							required: 'Phone is required',
						})}
						type="text"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
					/>
					<p className={errors.phone?.message ? 'mt-2 text-sm text-red-600' : 'hidden'} id="name-error">
						{errors.phone?.message}
					</p>
				</div>
			);
		} else {
			return (
				<div className={`mt-1 sm:mt-0 font-regular text-base sm:col-span-2${showPhone ? ' ' : ' blur-sm'} `}>
					{user?.phone}
				</div>
			);
		}
	};

	function classNames(...classes) {
		return classes.filter(Boolean).join(' ');
	}

	const renderDetails = () => {
		return (
			<>
				<div className="space-y-8 divide-y divide-gray-300  sm:space-y-5">
					<div>
						<div className="mt-8">
							<h3 className="text-2xl leading-6 font-semibold text-gray-900">
								{user?.firstName} {user?.lastName}
							</h3>
							<Grid numItemsLg={6} className={user?.plans?.length ? 'gap-6 mt-8' : 'hidden'}>
								<Col numColSpanLg={2}>
									<Card>
										<div className="max-w-sm flex flex-row">
											<div>
												<Text className="font-regular">Habits Completed</Text>
												<Metric className="font-medium">{user?.stats.habitsCompleted}</Metric>
											</div>
										</div>
									</Card>
								</Col>
								<Col numColSpanLg={2}>
									<Card>
										<div className="max-w-sm flex flex-row">
											<div>
												<Text className="font-regular">Practices Completed</Text>
												<Metric className="font-medium">
													{user?.stats.practicesCompleted}
												</Metric>
											</div>
										</div>
									</Card>
								</Col>
								<Col numColSpanLg={2}>
									<Card>
										<div className="max-w-sm flex flex-row">
											<div>
												<Text className="font-regular">Last Active</Text>
												<Metric className="font-medium">
													{user?.appOpenedAt === null
														? 'Never'
														: moment(user?.appOpenedAt).format('LL')}
												</Metric>
											</div>
										</div>
									</Card>
								</Col>
							</Grid>
						</div>
						<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
							<div>
								<h2 className="text-base font-semibold leading-7 text-gray-900">About</h2>
								<p className="mt-1 text-sm leading-6 text-gray-600">Edit the name and user role.</p>
							</div>
							<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
								<div className="col-span-full">
									<label
										htmlFor="status"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										First Name*
									</label>

									{renderFirstName()}
								</div>
								<div className="col-span-full">
									<label
										htmlFor="description"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Last Name*
									</label>

									{renderLastName()}
								</div>
								<div className={noOrgSelected() ? 'hidden' : 'col-span-full'}>
									<label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
										Role
									</label>
									<div className="mt-2">
										<select
											{...register('role')}
											className="max-w-lg block w-full focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
										>
											<option key="role-admin" value="admin">
												Staff Admin
											</option>
											<option key="role-leader" value="leader">
												Group Admin
											</option>
											<option key="role-member" value="member">
												Member
											</option>
										</select>
										<p className="mt-2 text-sm text-gray-500  font-regular">
											{watchRole === 'admin' && 'Unrestricted Access'}
											{watchRole === 'leader' && 'Access to Public and Group Admin Content'}
											{watchRole === 'member' && 'Access to Public Content'}
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
							<div>
								<h2 className="text-base font-semibold leading-7 text-gray-900">Contact</h2>
								<p className="mt-1 text-sm leading-6 text-gray-600">
									Edit the email address and phone.
								</p>
							</div>
							<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
								<div onClick={() => setShowEmail(!showEmail)} className="col-span-full cursor-pointer">
									<label
										htmlFor="description"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Email*
									</label>

									{renderEmail()}
								</div>
								<div onClick={() => setShowPhone(!showPhone)} className="col-span-full cursor-pointer">
									<label
										htmlFor="description"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Phone*
									</label>

									{renderPhone()}
								</div>
							</div>
						</div>

						<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
							<div>
								<h2 className="text-base font-semibold leading-7 text-gray-900">Account</h2>
								<p className="mt-1 text-sm leading-6 text-gray-600">
									Access user account details and information.
								</p>
							</div>
							<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
								<div className="col-span-full">
									<label
										htmlFor="joinedAt"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Joined
									</label>
									<div className="block w-full mt-2">{moment(user?.joinedAt).format('LL')}</div>
								</div>
								<div className="col-span-full">
									<label
										htmlFor="lastActive"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Last Active
									</label>
									<div className="block w-full mt-2">
										{user?.appOpenedAt === null ? 'Never' : moment(user?.appOpenedAt).format('LL')}
									</div>
								</div>
								<div className="col-span-full">{renderOrgs()}</div>
							</div>
						</div>
					</div>
				</div>
				{renderExtras()}
			</>
		);
	};

	const renderGroupsTab = () => {
		return (
			<div className={user?.groups?.length ? '' : 'hidden'}>
				<div className="mt-8 space-y-8 sm:space-y-5">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-2xl font-semibold text-gray-900 ">Groups</h1>
						</div>
					</div>
				</div>

				<div className="">
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Name
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Role
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Users
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												View
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{user?.groups?.map((group, groupIdx) => (
											<tr key={user.id} className={groupIdx % 2 === 0 ? undefined : 'bg-white'}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
													{group.name}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
													{group.is_leader ? 'Leader' : 'Member'}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
													{group.count}
												</td>
												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/groups/${group.id}`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														View
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderContentTab = () => {
		return (
			<div>
				<div className="mt-4 space-y-8 sm:space-y-5">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-2xl text-gray-900 font-semibold">Content</h1>
						</div>
						<div>
							{addingPlan ? (
								<div className="mt-6 flex flex-row">
									<div className="mt-1 sm:mt-0 sm:col-span-2">
										<select
											className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md font-regular mr-6"
											onChange={(event) => {
												setSelectedPlanId(event.target.value);
											}}
										>
											<option key="step-type-blank" value="">
												Select a Plan...
											</option>
											{orgPathways?.map((pathway) => (
												<option key={`practice-${pathway.id}`} value={pathway.id}>
													{pathway.name}
												</option>
											))}
										</select>
									</div>
									<ReactDatePicker
										dateFormat={'yyyy-MM-dd'}
										selected={planSelectedStartDate ? moment(planSelectedStartDate).toDate() : null}
										onChange={(date) => {
											setPlanSelectedStartDate(moment(date).format('YYYY-MM-DD'));
										}}
										calendarClassName="datepicker-calendar"
										type="text"
										autocomplete="off"
										className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md font-regular"
									/>
									<button
										type="button"
										disabled={!selectedPlanId || !user}
										className={
											'disabled:opacity-25 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 ml-6'
										}
										onClick={async () => {
											setAddPlanLoading(true);
											await tetherApi.addPathwaySchedule(selectedPlanId, {
												userId: user.id,
												startDate: planSelectedStartDate,
											});
											setAddPlanLoading(false);
											setAddingPlan(false);
											setPlanSelectedStartDate(moment().format('YYYY-MM-DD'));
											fetchUser(userId);
										}}
									>
										{addPlanLoading ? 'Loading...' : 'Schedule Plan'}
									</button>
								</div>
							) : (
								<div className="mt-6">
									<button
										type="button"
										className={
											'inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900'
										}
										onClick={() => setAddingPlan(true)}
									>
										Schedule Plan
									</button>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className={user?.plans?.length ? '' : 'hidden'}>
					<div className="mt-4 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="y-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Name
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Started At
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Completed At
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{userPlans.map((plan, planIdx) => (
											<tr key={user.id} className={planIdx % 2 === 0 ? undefined : 'bg-white'}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
													{plan.name}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
													{plan.startedAt}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
													{plan.completedAt}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderExtras = () => {
		return (
			<div className="pt-8">
				<div className="flex justify-end">
					{renderDelete()}
					<Confirm
						isOpen={deleteConfirm}
						title="Delete User"
						body="This will delete this User and cannot be undone. Are you sure?"
						onConfirm={deleteUser}
						onCancel={() => setDeleteConfirm(false)}
					/>
					<Link
						type="button"
						to="/users"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white py-2 px-4 mr-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Back
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className={
							'disabled:opacity-25 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900'
						}
					>
						Save
					</button>
				</div>
			</div>
		);
	};

	const renderContent = () => {
		switch (selectedTab) {
			case 'Details':
				return renderDetails();
			case 'Groups':
				return renderGroupsTab();
			case 'Content':
				return renderContentTab();
		}
	};

	const tabs = ['Details', 'Groups', 'Content'];

	return (
		<form className="px-8 py-2" id="user-form" onSubmit={handleSubmit(onSubmit)}>
			<div>
				<div className="sm:hidden">
					<label htmlFor="tabs" className="sr-only">
						Select a tab
					</label>
					<select
						id="tabs"
						name="tabs"
						defaultValue={'Details'}
						className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
					>
						{tabs.map((tab) => (
							<option key={`${tab}-option`}>{tab}</option>
						))}
					</select>
				</div>
				<div className="hidden sm:block">
					<div className="border-b border-gray-200">
						<nav aria-label="Tabs" className="-mb-px flex space-x-8">
							{tabs.map((tab) => (
								<a
									key={`${tab}-link`}
									className={classNames(
										tab === selectedTab
											? 'border-blue-500 text-blue-600'
											: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
										'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium cursor-pointer',
									)}
									onClick={() => {
										setSelectedTab(tab);
									}}
								>
									{tab}
								</a>
							))}
						</nav>
					</div>
				</div>
				{renderContent()}
			</div>
		</form>
	);
}

export default observer(UserForm);
