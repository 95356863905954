import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

function ChallengeForm() {
	const {
		register,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { isSubmitting, isDirty, errors },
	} = useForm();

	const { challengeId } = useParams();
	const [challenge, setChallenge] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [orgHabits, setOrgHabits] = useState([]);
	const [authors, setAuthors] = useState([]);
	const [fetchingOrgHabits, setFetchingOrgHabits] = useState(false);
	const [fetchingAuthors, setFetchingAuthors] = useState(false);

	const watchStartDate = watch('startDate');
	const watchEndDate = watch('endDate');

	let navigate = useNavigate();

	useEffect(() => {
		const getData = async () => {
			if (!authors.length && !fetchingOrgHabits) {
				setFetchingAuthors(true);
				await fetchAuthors();
			}
			if (!orgHabits.length && !fetchingAuthors) {
				setFetchingOrgHabits(true);
				await fetchOrgHabits();
			}

			if (challengeId && !challenge && orgsStore.orgs.length) {
				fetchChallenge(challengeId);
			}
			setSuccessMessage(challengeId ? 'Challenge updated!' : 'Challenge created!');
		};
		getData();
	}, [orgHabits, challenge, orgsStore.currentOrg, authors]);

	// let tier = orgsStore.getOrgTier();

	const fetchChallenge = async (challengeId) => {
		const challengeResponse = await tetherApi.getChallenge(challengeId);

		if (challengeResponse.data.authorId) {
			setValue('authorId', challengeResponse.data.authorId);
		}
		if (challengeResponse.data.orgHabitId) {
			setValue('orgHabitId', challengeResponse.data.orgHabitId);
		}

		setChallenge(challengeResponse.data);

		reset(challengeResponse.data);
	};

	const renderIsPublic = () => {
		if (orgsStore.currentOrg?.isPublisher === true) {
			return (
				<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
					<label htmlFor="isPublic" className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2">
						Public
					</label>
					<div className="mt-1 sm:mt-0 sm:col-span-2">
						<input
							{...register('isPublic')}
							type="checkbox"
							className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300  rounded font-regular"
						/>
						<p className="mt-2 text-sm text-gray-500  font-regular">
							Select if you want the challenge to be publicly available to <strong>all</strong> users on
							Tether
						</p>
					</div>
				</div>
			);
		}
	};

	const onSubmit = async (data) => {
		const result = await submitChallenge(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/challenges');
		}
	};

	const fetchOrgHabits = async () => {
		const orgHabitsResponse = await tetherApi.getOrgHabits(1, 100);
		setOrgHabits(orgHabitsResponse.data);
	};

	const fetchAuthors = async () => {
		const authorsResponse = await tetherApi.getAuthors(1, 100);
		setAuthors(authorsResponse.data);
	};

	const submitChallenge = async (data) => {
		const formData = new FormData(document.getElementById('challenge-form'));

		formData.append('isFeatured', data.isFeatured);

		if (data.id) {
			return await tetherApi.updateChallenge(data.id, formData);
		} else {
			return await tetherApi.createChallenge(formData);
		}
	};

	const deleteChallenge = async () => {
		const result = await tetherApi.deleteChallenge(challengeId);

		if (result) {
			alertService.success('Challenge deleted');

			navigate('/challenges');
		}
	};

	return (
		<form
			className="p-12 space-y-8 divide-y divide-gray-200 "
			id="challenge-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="space-y-8 divide-gray-200  sm:space-y-5">
				<div>
					<h3 className="text-3xl font-semibold  text-gray-900 ">{challengeId ? 'Edit' : 'New'} Challenge</h3>
				</div>
				<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200   sm:pt-5">
						<label htmlFor="status" className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2">
							Status
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<select
								{...register('status', { required: 'Status is required' })}
								className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
							>
								<option key="status-draft" value="draft">
									Draft
								</option>
								<option key="status-published" value="published">
									Published
								</option>
								<option key="status-published-private" value="published-private">
									Private
								</option>
							</select>
							<p
								className={errors.status?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
								id="status-error"
							>
								{errors.status?.message}
							</p>
						</div>
					</div>

					{renderIsPublic()}

					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
						<label htmlFor="title" className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2">
							Title
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<input
								{...register('title', {
									required: 'Title is required',
									maxLength: 'Title must be 255 characters or less',
								})}
								type="text"
								className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
							/>
							<p
								className={errors.name?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
								id="name-error"
							>
								{errors.title?.message}
							</p>
						</div>
					</div>
				</div>
				<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
						<label
							htmlFor="description"
							className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
						>
							Description
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<input
								{...register('description', {
									required: 'Description is required',
									maxLength: 'Description must be 255 characters or less',
								})}
								type="text"
								className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
							/>
							<p
								className={errors.description?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
								id="name-error"
							>
								{errors.description?.message}
							</p>
						</div>
					</div>
				</div>

				<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
						<label htmlFor="image" className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2">
							Image (optional)
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<input
								{...register('image')}
								type="file"
								name="image"
								id="image"
								accept=".png,.jpg,.jpeg"
								className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
							/>
							<p className="mt-2 text-sm text-gray-500  font-regular">Accepts .png or .jpg</p>

							<div className={challenge?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
								<img src={challenge?.imageUrl} alt="" className={challenge?.imageUrl ? '' : 'hidden'} />
							</div>
						</div>
					</div>
				</div>

				<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
					<label htmlFor="isFeatured" className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2">
						Featured
					</label>
					<div className="mt-1 sm:mt-0 sm:col-span-2">
						<input
							{...register('isFeatured')}
							type="checkbox"
							className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300  rounded"
						/>
						<p className="mt-2 text-sm text-gray-500  font-regular">
							Select this if you want to feature the challenge on the Explore screen
						</p>
					</div>
				</div>

				<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
					<label htmlFor="startDate" className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2">
						Start Date
					</label>
					<div className="mt-1 sm:mt-0 sm:col-span-2">
						<ReactDatePicker
							{...register('startDate', { required: 'Start Date is required' })}
							dateFormat={'yyyy-MM-dd'}
							selected={watchStartDate ? moment(watchStartDate).toDate() : null}
							onChange={(date) => setValue('startDate', date)}
							calendarClassName="datepicker-calendar"
							type="text"
							autocomplete="off"
							className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
						/>
						<p className="mt-2 text-sm text-red-600">{errors.startDate?.message}</p>
					</div>
				</div>

				<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
					<label htmlFor="endDate" className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2">
						End Date
					</label>
					<div className="mt-1 sm:mt-0 sm:col-span-2">
						<ReactDatePicker
							{...register('endDate', { required: 'End Date is required' })}
							dateFormat={'yyyy-MM-dd'}
							selected={watchEndDate ? moment(watchEndDate).toDate() : null}
							onChange={(date) => setValue('endDate', date)}
							calendarClassName="datepicker-calendar"
							type="text"
							autocomplete="off"
							className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
						/>
						<p className="mt-2 text-sm text-red-600">{errors.endDate?.message}</p>
					</div>
				</div>

				<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
					<label htmlFor="orgHabitId" className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2">
						Habit
					</label>
					<div className="mt-1 sm:mt-0 sm:col-span-2">
						<select
							{...register('orgHabitId')}
							className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
						>
							<option key="step-type-blank" value="">
								Select a Habit...
							</option>
							{orgHabits?.map((orgHabit) => (
								<option key={`orgHabit-${orgHabit.id}`} value={orgHabit.id}>
									{orgHabit.title}
								</option>
							))}
						</select>
						<p className="mt-2 text-sm text-gray-500  font-regular">
							Habit cannot be changed after Habit has been published
						</p>
						<p
							className={errors.orgHabitId?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
							id="orgHabit-error"
						>
							{errors.orgHabitId?.message}
						</p>
					</div>
				</div>

				<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
					<label htmlFor="authorId" className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2">
						Author
					</label>
					<div className="mt-1 sm:mt-0 sm:col-span-2">
						<select
							{...register('authorId', {
								required: 'Author is required',
							})}
							className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
						>
							<option key="step-type-blank" value="">
								Select an Author...
							</option>
							{authors.map((author) => (
								<option key={`author-${author.id}`} value={author.id}>
									{author.name}
								</option>
							))}
						</select>
						<p className="mt-2 text-sm text-gray-500  font-regular">
							Author cannot be changed after Habit has been published
						</p>
						<p
							className={errors.authorId?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
							id="author-error"
						>
							{errors.authorId?.message}
						</p>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							challengeId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						to="/challenges"
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Challenge"
				body="This will delete the Challenge and cannot be undone. Are you sure?"
				onConfirm={deleteChallenge}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(ChallengeForm);
