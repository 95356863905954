import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';

function CheckInPeriodImageForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm();

	const { checkInPeriodImageId } = useParams();
	const [checkInPeriodImage, setCheckInPeriodImage] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (checkInPeriodImageId && !checkInPeriodImage && orgsStore.orgs.length) {
			fetchCheckInPeriodImage(checkInPeriodImageId);
		}

		setSuccessMessage(checkInPeriodImageId ? 'Check In Period Image updated!' : 'Check In Period Image created!');
	}, [checkInPeriodImage, orgsStore.currentOrg]);

	const fetchCheckInPeriodImage = async (checkInPeriodImageId) => {
		const checkInPeriodImageResponse = await tetherApi.getCheckInPeriodImage(checkInPeriodImageId);

		setCheckInPeriodImage(checkInPeriodImageResponse.data);

		reset(checkInPeriodImageResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitCheckInPeriodImage(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/check_in_period_images');
		}
	};

	const submitCheckInPeriodImage = async (data) => {
		const formData = new FormData(document.getElementById('check-in-period-image-form'));

		formData.append('image', data.image[0]);

		if (data.id) {
			return await tetherApi.updateCheckInPeriodImage(data.id, formData);
		} else {
			return await tetherApi.createCheckInPeriodImage(formData);
		}
	};

	const deleteCheckInPeriodImage = async () => {
		const result = await tetherApi.deleteCheckInPeriodImage(checkInPeriodImageId);

		if (result) {
			alertService.success('Check In Period Image deleted');

			navigate('/check_in_period_images');
		}
	};

	return (
		<form
			className="p-12 space-y-8 divide-y divide-gray-200 "
			id="check-in-period-image-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-lg leading-6 font-medium text-gray-900 ">
							{checkInPeriodImageId ? 'Edit' : 'New'} Check In Period Image
						</h3>
					</div>

					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="description"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Description (optional)
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('description', {
										maxLength: 'Description must be 255 characters or less',
									})}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
								/>
								<p
									className={errors.description?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="description-error"
								>
									{errors.description?.message}
								</p>
							</div>
						</div>

						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="cover-photo"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Image
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('image', { required: 'Image is required' })}
									type="file"
									name="image"
									id="image"
									className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm  "
								/>
								<p className="mt-2 text-sm text-gray-500 ">Accepts .png or .jpg</p>
								<p
									className={errors.image?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="image-error"
								>
									{errors.image?.message}
								</p>

								<div className={checkInPeriodImage?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
									<img
										src={checkInPeriodImage?.imageUrl}
										alt=""
										className={checkInPeriodImage?.imageUrl ? '' : 'hidden'}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							checkInPeriodImageId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						to="/checkInPeriodImages"
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Check In Period Image"
				body="This will delete the Check In Period Image record and cannot be undone. Are you sure?"
				onConfirm={deleteCheckInPeriodImage}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(CheckInPeriodImageForm);
