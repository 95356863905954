import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { useParams } from 'react-router-dom';
import Pagination from '../Pagination';
import moment from 'moment';

function AccessCodeRedemptionsList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [accessCodeRedemptions, setAccessCodeRedemptions] = useState([]);
	const { pathwayId, accessCodeId } = useParams();

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchAccessCodeRedemptions();
		}
	}, [page, orgsStore.currentOrg]);

	const fetchAccessCodeRedemptions = async () => {
		const accessCodeRedemptionsResponse = await tetherApi.getAccessCodeRedemptions(
			pathwayId,
			accessCodeId,
			page,
			perPage,
		);

		setAccessCodeRedemptions(accessCodeRedemptionsResponse.data);

		const totalCount = accessCodeRedemptionsResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + accessCodeRedemptionsResponse.data.length);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="mt-8 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden md:rounded-lg">
							<table className="min-w-full">
								<thead className="bg-gray-50 ">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-regular text-gray-900  sm:pl-6"
										>
											Name
										</th>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-regular text-gray-900  sm:pl-6"
										>
											Email
										</th>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-regular text-gray-900  sm:pl-6"
										>
											Date
										</th>
									</tr>
								</thead>
								<tbody className="bg-white ">
									{accessCodeRedemptions.map((accessCodeRedemption, accessCodeRedemptionIdx) => (
										<tr
											key={accessCodeRedemption.id}
											className={accessCodeRedemptionIdx % 2 === 0 ? undefined : 'bg-gray-50 '}
										>
											<td className="py-4 pl-4 pr-3 text-sm text-gray-900  sm:pl-6 max-w-xs truncate font-regular">
												{accessCodeRedemption.redeemer.firstName}{' '}
												{accessCodeRedemption.redeemer.lastName}
											</td>
											<td className="py-4 pl-4 pr-3 text-sm text-gray-900  sm:pl-6 max-w-xs truncate font-regular">
												{accessCodeRedemption.redeemer.email}
											</td>
											<td className="py-4 pl-4 pr-3 text-sm text-gray-900  sm:pl-6 max-w-xs truncate font-regular">
												{moment(accessCodeRedemption.createdAt).format('YYYY-MM-DD')}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(AccessCodeRedemptionsList);
