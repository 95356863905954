import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ChromePicker } from 'react-color';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import userStore from '../../stores/UserStore';
// import Confirm from '../Confirm';

function OrgForm() {
	let navigate = useNavigate();
	let location = useLocation();

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({
		defaultValues: {
			hexColor: '#007AFF',
			country: 'United States',
			timezone: 'Central Time (US & Canada)',
			isSearchable: true,
		},
	});

	const { orgId = location.pathname.includes('/new') ? null : orgsStore.currentOrg?.id } = useParams();
	const [org, setOrg] = useState(null);
	const [timezones, setTimezones] = useState([]);
	const [successMessage, setSuccessMessage] = useState('');
	const [displayHexPicker, setDisplayHexPicker] = useState(false);
	const [unmonitoredFieldChanged, setUnmonitoredFieldChanged] = useState(false);

	const watchHexColor = watch('hexColor');

	useEffect(() => {
		if (!timezones.length) {
			fetchTimezones();
		}

		if (orgId && !org && orgsStore.orgs.length && timezones.length) {
			fetchOrg(orgId);
		}

		setSuccessMessage(orgId ? 'Organization updated!' : 'Organization created!');
	}, [orgsStore.orgs, timezones]);

	const fetchOrg = async (orgId) => {
		const orgResponse = await tetherApi.getOrg(orgId);

		setOrg(orgResponse.data);

		reset(orgResponse.data);
	};

	const fetchTimezones = async () => {
		const timezonesResponse = await tetherApi.getTimezones();
		if (timezonesResponse?.data) {
			setTimezones(timezonesResponse.data);
		}
	};

	const handleHexSelection = (color) => {
		setValue('hexColor', color.hex);

		setUnmonitoredFieldChanged(true);
	};

	const onSubmit = async (data) => {
		const result = await submitOrg(data);

		if (result) {
			alertService.success(successMessage);

			if (location.pathname.includes('/settings')) {
				navigate('/settings');
			} else {
				navigate('/orgs');
			}
		}
	};

	const submitOrg = async (data) => {
		const formData = new FormData(document.getElementById('org-form'));

		if (data.image[0]) {
			formData.append('image', data.image[0]);
		}

		formData.append('isSearchable', data.isSearchable);
		formData.append('isPublisher', data.isPublisher);
		// formData.append('groupsCustomDomain', data.groupsCustomDomain);
		formData.append('appSettings[enableChannels]', data.appSettings.enableChannels);
		formData.append('appSettings[enableLeaderResources]', data.appSettings.enableLeaderResources);
		formData.append('appSettings[enableSurveys]', data.appSettings.enableSurveys);
		formData.append('appSettings[enableHabits]', data.appSettings.enableHabits);
		formData.append('appSettings[enableCourses]', data.appSettings.enableCourses);
		formData.append('appSettings[enablePlans]', data.appSettings.enablePlans);
		formData.append('appSettings[enablePractices]', data.appSettings.enablePractices);
		formData.append('appSettings[hideTetherContent]', data.appSettings.hideTetherContent);
		formData.append('appSettings[hideTetherBranding]', data.appSettings.hideTetherBranding);
		formData.append('appSettings[preventNewGroups]', data.appSettings.preventNewGroups);
		formData.append('appSettings[preventPublicSignups]', data.appSettings.preventPublicSignups);

		if (data.id) {
			// console.log('update', formData);
			return await tetherApi.updateOrg(data.id, formData);
		} else {
			// console.log('CREATING');
			return await tetherApi.createOrg(formData);
		}
	};

	const renderSuperUserFields = () => {
		if (userStore.user.isSuperUser === true) {
			return (
				<>
					<div className="col-span-full">
						<label htmlFor="stripeCustomerId" className="block text-sm font-medium leading-6 text-gray-900">
							ID
						</label>
						<div className="mt-2">
							<label htmlFor="stripeCustomerId" className="block text-md leading-6 text-gray-900">
								{org?.id}
							</label>
						</div>
					</div>
					<div className="col-span-full">
						<label htmlFor="stripeCustomerId" className="block text-sm font-medium leading-6 text-gray-900">
							Stripe Customer Id
						</label>
						<div className="mt-2">
							<input
								{...register('stripeCustomerId', {
									maxLength: '',
								})}
								type="text"
								className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
								disabled={org?.stripeCustomerId?.length}
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="launchHub" className="block text-sm font-medium leading-6 text-gray-900">
							Launch Hub
						</label>
						<div className="mt-2">
							<input
								{...register('launchHub', {
									maxLength: '',
								})}
								type="text"
								className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="tier" className="block text-sm font-medium leading-6 text-gray-900">
							Tier*
						</label>
						<div className="mt-2">
							<select
								{...register('tier', { required: true })}
								className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								placeholder="Tether Pro"
							>
								<option key="tier-tether-pro" value="pro">
									Pro
								</option>
								<option key="tier-tether-basic" value="basic">
									Essentials
								</option>
							</select>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
							Status*
						</label>
						<div className="mt-2">
							<select
								{...register('status', { required: true })}
								className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								placeholder="Tether Pro"
							>
								<option key="active" value="active">
									Active
								</option>
								<option key="paused" value="paused">
									Paused
								</option>
								<option key="cancelled" value="cancelled">
									Cancelled
								</option>
							</select>
						</div>
					</div>
					<div className="col-span-full">
						<div className="relative flex gap-x-3">
							<div className="flex h-6 items-center">
								<input
									{...register('isPublisher')}
									type="checkbox"
									className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
								/>
							</div>
							<div className="text-sm leading-6">
								<label htmlFor="candidates" className="font-medium text-gray-900">
									Publisher
								</label>
								<p className="text-gray-500">
									If selected, the workspace can publish content to all Tether users
								</p>
							</div>
						</div>
					</div>
				</>
			);
		}
	};

	return (
		<form className="p-12 space-y-8" id="org-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-xl font-semibold text-black ">{orgId ? 'Settings' : 'New Organization'}</h3>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit your name, description, website, and more.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
									Name*
								</label>
								<div className="mt-2">
									<input
										{...register('name', {
											required: 'Name is required',
											maxLength: 'Name must be 255 characters or less',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.name?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="name-error"
									>
										{errors.name?.message}
									</p>
									<p className="mt-3 text-sm leading-6 text-gray-600">
										Write a description about your workspace
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
									Description*
								</label>
								<div className="mt-2">
									<textarea
										{...register('description', {
											required: 'Description is required',
											maxLength: 'Name must be 255 characters or less',
										})}
										type="text"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
									/>
									<p className="mt-3 text-sm leading-6 text-gray-600">
										Write a description about your workspace
									</p>
									<p
										className={errors.description?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="description-error"
									>
										{errors.description?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
									Website
								</label>
								<div className="mt-2">
									<input
										{...register('url', {
											maxLength: 'Name must be 255 characters or less',
										})}
										type="text"
										placeholder="https://yoursite.org"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.url?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="url-error"
									>
										{errors.url?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
									Subdomain*
								</label>
								<div className="mt-2">
									<input
										{...register('subdomain', {
											required: 'Subdomain is required',
											maxLength: 'Subdomain must be 255 characters or less',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.name?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="name-error"
									>
										{errors.name?.message}
									</p>
									<p className="mt-3 text-sm leading-6 text-gray-600">
										Your personalized tether.site domain
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="groupsCustomDomain"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Custom Domain
								</label>
								<div className="mt-2">
									<input
										{...register('groupsCustomDomain', {
											maxLength: 'Custom Domain must be 255 characters or less',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={
											errors.groupsCustomDomain?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
										}
										id="custom-domain-error"
									>
										{errors.groupsCustomDomain?.message}
									</p>
									<p className="mt-3 text-sm leading-6 text-gray-600">
										In your DNS, create a new CNAME record and point it to your subdomain
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="timezone" className="block text-sm font-medium leading-6 text-gray-900">
									Timezone*
								</label>
								<div className="mt-2">
									<select
										{...register('timezone', {
											required: 'Timezone is required',
										})}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="timezone-blank" value="">
											Select a Timezone...
										</option>
										{timezones.map((timezone) => (
											<option key={`timezone-${timezone}`} value={timezone}>
												{timezone}
											</option>
										))}
									</select>
									<p
										className={errors.timezone?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="timezone-error"
									>
										{errors.timezone?.message}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Brand</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit your brand and creative assets like images, colors, and more.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="hexColor" className="block text-sm font-medium leading-6 text-gray-900">
									Brand Color*
								</label>
								<div className="mt-2 relative">
									<input
										{...register('hexColor', {
											required: 'Hex Color is required',
										})}
										type="text"
										className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<div
										className="absolute top-px left-64 pr-3 flex items-center h-9 w-16 rounded-r-md border-l border-gray-400"
										style={{ backgroundColor: watchHexColor }}
										onClick={() => {
											setDisplayHexPicker(!displayHexPicker);
										}}
									></div>
									<ChromePicker
										disableAlpha={true}
										color={{ hex: watchHexColor }}
										onChange={handleHexSelection}
										className={displayHexPicker ? 'absolute z-10 ml-24 mt-2' : 'hidden'}
									/>
									<p
										className={errors.hexColor?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="hexColor-error"
									>
										{errors.hexColor?.message}
									</p>
								</div>
							</div>

							<div className="col-span-full">
								<label
									htmlFor="cover-photo"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Square Image - (1080 x 1080)
								</label>
								<div className="mt-2">
									<input
										{...register('image')}
										type="file"
										name="image"
										id="image"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500 ">Accepts .png, .jpg, .jpeg</p>

									<div className={org?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img src={org?.imageUrl} alt="" className={org?.imageUrl ? '' : 'hidden'} />
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="coverImage"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Cover Photo - (1800 x 600)
								</label>
								<div className="mt-2">
									<input
										{...register('coverImage')}
										type="file"
										name="coverImage"
										id="coverImage"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500 ">Accepts .png, .jpg, .jpeg</p>

									<div className={org?.coverImageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={org?.coverImageUrl}
											alt=""
											className={org?.coverImageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="wordMarkImage"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Wordmark Logo
								</label>
								<div className="mt-2">
									<input
										{...register('wordMarkImage')}
										type="file"
										name="wordMarkImage"
										id="wordMarkImage"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500 ">Accepts .png, .jpg, .jpeg</p>

									<div
										className={
											org?.wordMarkImageUrl
												? 'max-w-lg flex mt-5 w-80 bg-neutral-900 rounded-md p-4'
												: 'hidden'
										}
									>
										<img
											src={org?.wordMarkImageUrl}
											alt=""
											className={org?.wordMarkImageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Features</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select what features you want your users to access
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							{/* <div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.enableChannels')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Enable Channels
										</label>
										<p className="text-gray-500">
											If checked, the channels feature will be enabled
										</p>
									</div>
								</div>
							</div> */}
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.enableCourses')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Enable Courses
										</label>
										<p className="text-gray-500">If checked, the courses feature will be enabled</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.enablePlans')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Enable Plans
										</label>
										<p className="text-gray-500">If checked, the plans feature will be enabled</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.enablePractices')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Enable Practices
										</label>
										<p className="text-gray-500">
											If checked, the practices feature will be enabled
										</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.enableLeaderResources')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Enable Resources
										</label>
										<p className="text-gray-500">
											If checked, the resources feature will be enabled
										</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.enableSurveys')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Enable Surveys
										</label>
										<p className="text-gray-500">If checked, the surveys feature will be enabled</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.enableHabits')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Enable Habits
										</label>
										<p className="text-gray-500">If checked, the habits feature will be enabled</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Privacy</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit your privacy features and select if you want to make your workspace private and
								invite-only.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label
									htmlFor="termsOfService"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Terms of Service
								</label>
								<div className="mt-2">
									<input
										{...register('termsOfService', {
											maxLength: 'Terms of Service URL must be 255 characters or less',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={
											errors.termsOfService?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
										}
										id="terms-of-service-error"
									>
										{errors.termsOfService?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="privacyPolicy"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Privacy Policy
								</label>
								<div className="mt-2">
									<input
										{...register('privacyPolicy', {
											maxLength: 'Privacy Policy URL must be 255 characters or less',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={
											errors.privacyPolicy?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
										}
										id="privacy-policy-error"
									>
										{errors.privacyPolicy?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('isSearchable')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Public
										</label>
										<p className="text-gray-500">
											If checked, your workspace is public and can be found by anyone
										</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.hideTetherContent')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Hide Global Library
										</label>
										<p className="text-gray-500">
											If checked, you will restrict access to Tether's Global Content Library
										</p>
									</div>
								</div>
							</div>
							{/* <div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.hideTetherBranding')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Hide Tether Branding
										</label>
										<p className="text-gray-500">
											If checked, you will hide all Tether branding from your workspace
										</p>
									</div>
								</div>
							</div> */}
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.preventNewGroups')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Prevent New Groups
										</label>
										<p className="text-gray-500">
											If checked, you will prevent users from creating new groups. Groups will
											only be able to be created by admins.
										</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.preventPublicSignups')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Prevent User Signups
										</label>
										<p className="text-gray-500">
											If checked, you will prevent new users from signing up to your workspace.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Features</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit what features you want your users to access
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.enableChannels')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Enable Channels
										</label>
										<p className="text-gray-500">
											If checked, the channels feature will be enabled
										</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('appSettings.enableLeaderResources')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Enable Resources
										</label>
										<p className="text-gray-500">
											If checked, the resources feature will be enabled
										</p>
									</div>
								</div>
							</div>
						</div>
					</div> */}
					{userStore.user.isSuperUser === true && (
						<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
							<div>
								<h2 className="text-base font-semibold leading-7 text-gray-900">Super Admin</h2>
							</div>
							<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
								{renderSuperUserFields()}
							</div>
						</div>
					)}
					<div className="">
						{/* <div className="col-span-full">
							<label
								htmlFor="addressLine1"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Address Line 1 (optional)
							</label>
							<div className="mt-2">
								<input
									{...register('addressLine1', {
										maxLength: 'Name must be 255 characters or less',
									})}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.addressLine1?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="addressLine1-error"
								>
									{errors.addressLine1?.message}
								</p>
							</div>
						</div>

						<div className="col-span-full">
							<label
								htmlFor="addressLine2"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Address Line 2 (optional)
							</label>
							<div className="mt-2">
								<input
									{...register('addressLine2', {
										maxLength: 'Name must be 255 characters or less',
									})}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.addressLine2?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="addressLine2-error"
								>
									{errors.addressLine2?.message}
								</p>
							</div>
						</div>

						<div className="col-span-full">
							<label
								htmlFor="city"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								City (optional)
							</label>
							<div className="mt-2">
								<input
									{...register('city', {
										maxLength: 'Name must be 255 characters or less',
									})}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.city?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="city-error"
								>
									{errors.city?.message}
								</p>
							</div>
						</div>

						<div className="col-span-full">
							<label
								htmlFor="state"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								State (optional)
							</label>
							<div className="mt-2">
								<input
									{...register('state', {
										maxLength: 'Name must be 255 characters or less',
									})}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.state?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="state-error"
								>
									{errors.state?.message}
								</p>
							</div>
						</div>

						<div className="col-span-full">
							<label
								htmlFor="postalCode"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Postal Code (optional)
							</label>
							<div className="mt-2">
								<input
									{...register('postalCode', {
										maxLength: 'Name must be 255 characters or less',
									})}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.postalCode?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="postalCode-error"
								>
									{errors.postalCode?.message}
								</p>
							</div>
						</div>

						<div className="col-span-full">
							<label
								htmlFor="country"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Country (optional)
							</label>
							<div className="mt-2">
								<input
									{...register('country', {
										maxLength: 'Name must be 255 characters or less',
									})}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.country?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="country-error"
								>
									{errors.country?.message}
								</p>
							</div>
						</div> */}
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					{/* {userStore.user.isSuperUser === true && (
						<button
							type="button"
							onClick={() => setDeleteConfirm(true)}
							className={
								orgHabitId
									? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
									: 'hidden'
							}
						>
							Delete
						</button>
					)} */}
					<Link
						type="button"
						to="/dashboard"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={(!isDirty && !unmonitoredFieldChanged) || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
						onClick={() => {
							setTimeout(() => {
								window.location.reload();
							}, 2000);
						}}
					>
						Save
					</button>
				</div>
			</div>
			{/* <Confirm
				isOpen={deleteConfirm}
				title="Delete Habit"
				body="This will delete the Habit for all Users and cannot be undone. Are you sure?"
				onConfirm={deleteOrgHabit}
				onCancel={() => setDeleteConfirm(false)}
			/> */}
		</form>
	);
}

export default observer(OrgForm);
