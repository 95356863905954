import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination';

function AuthorsList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [authors, setAuthors] = useState([]);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchAuthors();
		}
	}, [page, orgsStore.currentOrg]);

	const fetchAuthors = async () => {
		const authorsResponse = await tetherApi.getAuthors(page, perPage);

		setAuthors(authorsResponse.data);

		const totalCount = authorsResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + authorsResponse.data.length);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-2xl font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-0 lg:pb-0">Authors</h1>
				</div>
				<div className="xs:mt-0 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="/authors/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 xs:w-full xs:mt-4 lg:mt-0"
					>
						New Author
					</Link>
				</div>
			</div>
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-white">
									<tr>
										<th
											scope="col"
											className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
										>
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Website
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Image
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Featured
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Edit
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{authors.map((author, authorIdx) => (
										<tr key={author.id} className={authorIdx % 2 === 0 ? undefined : 'bg-white'}>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
												{author.name}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{author.urlString}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												<img className="w-8 h-8" alt={author.name} src={author.imageUrl} />
											</td>
											<td className="px-3 py-4 text-sm text-gray-500 font-regular">
												{author.isFeatured ? 'Yes' : 'No'}
											</td>
											<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
												<Link
													to={`/authors/${author.id}`}
													className="font-medium text-neutral-800 hover:text-neutral-900"
												>
													Edit
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(AuthorsList);
