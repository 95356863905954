import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';

function AssessmentActionLinkForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({});

	const { assessmentId, assessmentActionLinkId } = useParams();
	const [assessmentActionLink, setAssessmentActionLink] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (assessmentActionLinkId && !assessmentActionLink && orgsStore.orgs.length) {
			fetchAssessmentActionLink(assessmentId, assessmentActionLinkId);
		}

		setSuccessMessage(assessmentActionLinkId ? 'Link updated!' : 'Link created!');
	}, [assessmentActionLink, orgsStore.currentOrg]);

	const fetchAssessmentActionLink = async (assessmentId, assessmentActionLinkId) => {
		const assessmentActionLinkResponse = await tetherApi.getAssessmentActionLink(
			assessmentId,
			assessmentActionLinkId,
		);

		setAssessmentActionLink(assessmentActionLinkResponse.data);

		reset(assessmentActionLinkResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitAssessmentActionLink(data);

		if (result) {
			alertService.success(successMessage);

			navigate(`/assessments/${assessmentId}`);
		}
	};

	const submitAssessmentActionLink = async (data) => {
		const formData = new FormData(document.getElementById('assessment-action-link-form'));

		if (data.id) {
			return await tetherApi.updateAssessmentActionLink(assessmentId, data.id, formData);
		} else {
			return await tetherApi.createAssessmentActionLink(assessmentId, formData);
		}
	};

	const deleteAssessmentActionLink = async () => {
		const result = await tetherApi.deleteAssessmentActionLink(assessmentId, assessmentActionLinkId);

		if (result) {
			alertService.success('Link deleted');

			navigate(`/assessments/${assessmentId}`);
		}
	};

	return (
		<form
			className="p-12 space-y-8 divide-y divide-gray-200 "
			id="assessment-action-link-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-3xl font-semibold  leading-6 text-gray-900 ">
							{assessmentActionLinkId ? 'Edit' : 'New'} Link
						</h3>
					</div>

					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200   sm:pt-8">
						<label
							htmlFor="linkType"
							className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2 font-regular"
						>
							Link Type
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<select
								{...register('linkType', { required: 'Link Type is required' })}
								className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
							>
								<option key="linkType-email" value="email">
									Email
								</option>
								<option key="linkType-phon" value="phone">
									Phone
								</option>
								<option key="linkType-sms" value="sms">
									SMS
								</option>
								<option key="linkType-web" value="web">
									Web
								</option>
							</select>
							<p
								className={errors.linkType?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
								id="linkType-error"
							>
								{errors.linkType?.message}
							</p>
						</div>
					</div>

					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="label"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2 font-regular"
							>
								Label
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('label', { required: 'Label is required' })}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={
										errors.label?.message ? 'mt-2 text-sm text-red-600 font-regular' : 'hidden'
									}
									id="label-error"
								>
									{errors.label?.message}
								</p>
							</div>
						</div>
					</div>

					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="link"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Value
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('link', { required: 'Link is required' })}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.link?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="link-error"
								>
									{errors.link?.message}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className="mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
					>
						Delete
					</button>

					<Link
						type="button"
						to={`/assessments/${assessmentId}`}
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Link"
				body="This will delete this Link and cannot be undone. Are you sure?"
				onConfirm={deleteAssessmentActionLink}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(AssessmentActionLinkForm);
