import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import vimeoApi from '../../api/vimeo';
import Confirm from '../Confirm';
import { ProgressBar } from '../ProgressBar';
import { stepTypeLabels } from '../../utils/humanReadableLabels';

function PracticeStepForm() {
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		reset,
		watch,
		formState: { isSubmitting, isDirty, errors },
	} = useForm();

	const [stepTypes, setStepTypes] = useState({});
	const { practiceId, practiceStepId } = useParams();
	const [practiceStep, setPracticeStep] = useState({});
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [unmonitoredFieldChanged, setUnmonitoredFieldChanged] = useState(false);
	const [vimeoUploadProgress, setVimeoUploadProgress] = useState(false);
	const [vimeoUploading, setVimeoUploading] = useState(false);
	const [initialStepType, setInitialStepType] = useState(null);

	const answerCount = 12;
	const listItemCount = 5;

	let navigate = useNavigate();

	const watchStepType = watch('stepType');

	const typeFields = {
		audio: ['title', 'subtitle', 'image', 'audioFiles'],
		file: ['title', 'file'],
		html: ['title', 'html'],
		image: ['title', 'image'],
		'image-landscape': ['title', 'image'],
		link: ['title', 'link', 'accessRole'],
		list: ['title', 'subtitle', 'listItems'],
		'multiple-choice': ['title', 'body', 'answers'],
		quote: ['body', 'addendum', 'authorName'],
		scripture: ['body', 'addendum'],
		text: ['title', 'body', 'subtitle'],
		'text-response': ['body', 'subtitle'],
		video: ['videoFiles'],
		timer: ['time'],
		'video-landscape': ['title', 'videoFiles', 'landscape'],
	};

	const answerableStepTypes = ['multiple-choice', 'text-response'];

	useEffect(() => {
		if (!Object.keys(stepTypes).length) {
			fetchStepTypes();
		}

		if (practiceStepId && !practiceStep.id && Object.keys(stepTypes).length && orgsStore.orgs.length) {
			fetchPracticeStep(practiceId, practiceStepId);
		}

		setSuccessMessage(practiceStepId ? 'Step updated!' : 'Step created!');
	}, [stepTypes, practiceStep, vimeoUploadProgress, orgsStore.currentOrg]);

	// Poll for video transcoding completion
	// Commented out because this causes an issue when trying to add a video to an existing step.
	// useInterval(async () => {
	//   if(practiceStep?.id && practiceStep?.videoFiles?.length && practiceStep?.videoFiles[0]?.status === 'transcoding') {
	//     fetchPracticeStep(practiceId, practiceStepId)
	//   }
	// }, 3000)

	const handleHtmlChange = (content, delta, source, editor) => {
		setValue('html', editor.getHTML());

		setPracticeStep({ ...practiceStep });

		setUnmonitoredFieldChanged(true);
	};

	const fetchStepTypes = async () => {
		const stepTypesResponse = await tetherApi.getStepTypes('PracticeStep');

		setStepTypes(stepTypesResponse.data);
	};

	const fetchPracticeStep = async (practiceId, practiceStepId) => {
		const practiceStepResponse = await tetherApi.getPracticeStep(practiceId, practiceStepId);

		setPracticeStep(practiceStepResponse.data);

		setInitialStepType(practiceStepResponse.data.stepType);

		reset(practiceStepResponse.data);
	};

	const handleHtmlBodyChange = (content, delta, source, editor) => {
		setValue('body', editor.getHTML());

		setPracticeStep({ ...practiceStep });

		setUnmonitoredFieldChanged(true);
	};

	const handleVideoFileChange = async (event) => {
		const file = event.target.files[0];

		if (file) {
			setVimeoUploadProgress(0);
			setVimeoUploading(true);

			const preSignedResponse = await tetherApi.createPreSignedVimeoUpload({
				size: file.size,
			});

			vimeoApi.uploadVideo(
				file,
				preSignedResponse,
				(bytesUploaded, bytesTotal) => {
					setVimeoUploadProgress(parseInt((bytesUploaded * 100) / bytesTotal));
				},
				(vimeoId) => {
					practiceStep.videoFiles ||= [];
					practiceStep.videoFiles.push({
						url: `https://vimeo.com/${vimeoId}`,
						externalId: vimeoId,
						service: 'vimeo',
						status: 'transcoding',
					});

					setPracticeStep({ ...practiceStep });

					setUnmonitoredFieldChanged(true);

					setVimeoUploading(false);
				},
				(error) => {
					alertService.error(error);

					setVimeoUploading(false);
				},
			);
		}
	};

	const onSubmit = async (data) => {
		const result = await submitPracticeStep(data);

		if (result) {
			alertService.success(successMessage);

			navigate(`/practices/${practiceId}`);
		}
	};

	const submitPracticeStep = async (data) => {
		const formData = new FormData(document.getElementById('practice-step-form'));

		const allowedFields = typeFields[watchStepType].concat(['id', 'index', 'stepType']);

		let actualLink = data.link;
		if (actualLink && actualLink.slice(0, 8) !== 'https://') {
			formData.set('link', `https://${actualLink}`);
		}

		if (data.image[0]) {
			formData.append('image', data.image[0]);
		}

		if (data.body) {
			formData.append('body', data.body);
		}

		if (data.file[0]) {
			formData.append('file', data.file[0]);
		}

		if (getValues('html') !== null && getValues('html') !== undefined && getValues('html') !== '') {
			formData.append('html', getValues('html'));
		}

		if (getValues('timeInSeconds') !== null) {
			formData.append('time_in_seconds', getValues('timeInSeconds'));
		}
		if (getValues('enableOnPdf') !== null) {
			formData.append('enable_on_pdf', getValues('enableOnPdf'));
		}

		// Toss out values that do not apply to the selected type
		for (const [key] of Object.entries(data)) {
			if (!allowedFields.includes(key)) {
				formData.delete(key);
			}
		}

		if (data.id) {
			return await tetherApi.updatePracticeStep(practiceId, data.id, formData);
		} else {
			return await tetherApi.createPracticeStep(practiceId, formData);
		}
	};

	const deletePracticeStep = async () => {
		const result = await tetherApi.deletePracticeStep(practiceId, practiceStepId);

		if (result) {
			alertService.success('Step deleted');

			navigate(`/practices/${practiceId}`);
		}
	};

	return (
		<form
			className="p-12 space-y-8 divide-y divide-gray-200 "
			id="practice-step-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-3xl font-semibold  text-gray-900 ">
							{practiceStepId ? 'Edit' : 'New'} Step
						</h3>
					</div>

					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="practiceType"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Step Type
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<select
									{...register('stepType', {
										required: 'Step Type is required',
									})}
									className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								>
									<option key="step-type-blank" value="">
										Select a Content Type...
									</option>
									{Object.entries(stepTypes).map(([key, value]) => (
										<option key={`stepType-${key}`} value={value}>
											{stepTypeLabels[value]}
										</option>
									))}
								</select>
								<p
									className={
										initialStepType &&
										answerableStepTypes.includes(initialStepType) &&
										!answerableStepTypes.includes(watchStepType)
											? 'mt-2 text-sm text-gray-500 '
											: 'hidden'
									}
								>
									<ExclamationTriangleIcon
										className="inline-block h-6 w-6 mr-2 text-red-600"
										aria-hidden="true"
									/>
									Changing Step Type will remove all existing responses for this Step.
								</p>
								<p
									className={errors.stepType?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="step-type-error"
								>
									{errors.stepType?.message}
								</p>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('title')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="title"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								{`Title${
									watchStepType === 'image' ||
									watchStepType === 'html' ||
									watchStepType === 'list' ||
									watchStepType === 'multiple-choice' ||
									watchStepType === 'text'
										? ' (optional)'
										: ''
								}`}
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('title', {
										maxLength: 'Title must be 255 characters or less',
									})}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.title?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="title-error"
								>
									{errors.title?.message}
								</p>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('subtitle')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="subtitle"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								{`Subtitle${watchStepType === 'list' || watchStepType === 'text' ? ' (optional)' : ''}`}
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('subtitle')}
									type="text"
									name="subtitle"
									id="subtitle"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
								/>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('body')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label htmlFor="body" className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2">
								{['multiple-choice', 'text-response'].includes(watchStepType) ? 'Question' : 'Body'}
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								{['multiple-choice', 'text-response'].includes(watchStepType) ? (
									<div>
										<ReactQuill
											value={getValues('body') || ''}
											onChange={handleHtmlBodyChange}
											modules={{
												toolbar: [
													[{ header: [false, 1, 2, 3] }],
													['bold', 'italic', 'underline'],
													[
														'link',
														'blockquote',
														{ list: 'ordered' },
														{ list: 'bullet' },
														{ indent: '-1' },
														{ indent: '+1' },
													],
													[{ align: ['', 'center', 'right'] }],
												],
											}}
											formats={[
												'header',
												'bold',
												'italic',
												'link',
												'underline',
												'script',
												'blockquote',
												'list',
												'align',
												'indent',
											]}
											name="html"
											rows="3"
											className="w-full h-80 overflow-scroll shadow-sm block focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-200  rounded-md  font-regular"
											theme="snow"
										/>

										<p
											className={errors.body?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
											id="html-error"
										>
											{errors.body?.message}
										</p>
									</div>
								) : (
									<div>
										<textarea
											{...register('body')}
											rows={3}
											className="max-w-lg shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300  rounded-md   font-regular"
										/>
										<p
											className={errors.body?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
											id="body-error"
										>
											{errors.body?.message}
										</p>
									</div>
								)}
							</div>
						</div>

						<div
							className={
								'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
							}
						>
							<label
								htmlFor="enableOnPdf"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Show on PDF?
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('enableOnPdf')}
									type="checkbox"
									name="enableOnPdf"
									id="enableOnPdf"
									defaultChecked={true}
									className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300  rounded"
								/>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('time')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label htmlFor="time" className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2">
								Time in Seconds
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('timeInSeconds')}
									type="number"
									className="max-w-lg shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.body?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="body-error"
								>
									{errors.body?.message}
								</p>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('link')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="link"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Link
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('link')}
									type="text"
									name="link"
									id="link"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('addendum')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="addendum"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								{`Addendum${watchStepType === 'quote' ? ' (optional)' : ''}`}
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('addendum')}
									type="text"
									name="addendum"
									id="addendum"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
								/>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('html')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="html"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								HTML
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<ReactQuill
									value={getValues('html') || ''}
									onChange={handleHtmlChange}
									modules={{
										toolbar: [
											[{ header: [false, 1, 2, 3] }],
											['bold', 'italic', 'underline'],
											[
												'link',
												'blockquote',
												{ list: 'ordered' },
												{ list: 'bullet' },
												{ indent: '-1' },
												{ indent: '+1' },
											],
											[{ align: ['', 'center', 'right'] }],
										],
									}}
									formats={[
										'header',
										'bold',
										'italic',
										'link',
										'underline',
										'script',
										'blockquote',
										'list',
										'align',
										'indent',
									]}
									name="html"
									rows="3"
									className="w-full h-80 overflow-scroll shadow-sm block focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-200  rounded-md  font-regular"
									theme="snow"
								/>

								<p
									className={errors.html?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="html-error"
								>
									{errors.html?.message}
								</p>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('authorName')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="authorName"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								{`Author Name${watchStepType === 'quote' ? ' (optional)' : ''}`}
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('authorName')}
									type="text"
									name="authorName"
									id="authorName"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
								/>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('answers')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="answers"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Answers
								<p className="mt-2 text-sm text-gray-500 ">Empty answers will be ignored</p>
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								{[...Array(answerCount)].map((e, i) => (
									<div
										className="mt-1 relative rounded-md shadow-sm w-80"
										key={`answer-${i}-wrapper`}
									>
										<input
											key={`answer-${i}`}
											type="text"
											name="answers[]"
											id={`answer-${i}`}
											data-index={i}
											value={practiceStep?.answers?.[i]}
											onChange={(event) => {
												if (
													practiceStep?.answers?.[parseInt(event.currentTarget.dataset.index)]
												) {
													practiceStep.answers[parseInt(event.currentTarget.dataset.index)] =
														event.currentTarget.value;

													setPracticeStep({ ...practiceStep });

													setUnmonitoredFieldChanged(true);
												}
											}}
											className="max-w-lg sm:max-w-xs sm:text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 sm:text-sm border-gray-300  rounded-md my-3  "
										/>
									</div>
								))}
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('listItems')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="listItems"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								List Items
								<p className="mt-2 text-sm text-gray-500 ">Empty items will be ignored</p>
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								{[...Array(listItemCount)].map((e, i) => (
									<div
										className="mt-1 relative rounded-md shadow-sm w-80"
										key={`list-item-${i}-wrapper`}
									>
										<input
											key={`list-item-${i}`}
											type="text"
											name="listItems[]"
											id={`list-item-${i}`}
											data-index={i}
											value={practiceStep?.listItems?.[i]}
											onChange={(event) => {
												practiceStep.listItems[parseInt(event.currentTarget.dataset.index)] =
													event.currentTarget.value;

												setPracticeStep({ ...practiceStep });

												setUnmonitoredFieldChanged(true);
											}}
											className="max-w-lg sm:max-w-xs sm:text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 sm:text-sm border-gray-300  rounded-md my-3  "
										/>
									</div>
								))}
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('image')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="image"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								{`Image${watchStepType === 'image' ? '' : ' (optional)'}`}
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('image')}
									type="file"
									name="image"
									id="image"
									accept=".png,.jpg,.jpeg"
									className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50 file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
								/>
								<p className="mt-2 text-sm text-gray-500  font-regular">Accepts .png or .jpg</p>

								<div className={practiceStep?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
									<img
										src={practiceStep?.imageUrl}
										alt=""
										className={practiceStep?.imageUrl ? '' : 'hidden'}
									/>
								</div>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('image-landscape')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="image"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Image
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('image-landscape')}
									type="file"
									name="image-landscape"
									id="image-landscape"
									accept=".png,.jpg,.jpeg"
									className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50 font-regular file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm  "
								/>
								<p className="mt-2 text-sm text-gray-500  font-regular">Accepts .png or .jpg</p>

								<div className={practiceStep?.imageLandscapeUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
									<img
										src={practiceStep?.imageLandscapeUrl}
										alt=""
										className={practiceStep?.imageLandscapeUrl ? '' : 'hidden'}
									/>
								</div>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('file')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label htmlFor="file" className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2">
								File
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('file')}
									type="file"
									name="file"
									id="file"
									accept="application/pdf"
									className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm  "
								/>
								<p className="mt-2 text-sm text-gray-500 ">Accepts .pdf</p>

								<div className={practiceStep?.fileUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
									<a href={practiceStep?.fileUrl} className={practiceStep?.fileUrl ? '' : 'hidden'}>
										View File
									</a>
								</div>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('audioFiles')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="audioFiles"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Audio
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								{practiceStep?.audioFiles?.map((audioFile, audioFileIdx) => (
									<div
										className={
											audioFile._destroy
												? 'hidden'
												: 'bg-white  shadow sm:rounded-lg max-w-lg mb-5'
										}
										key={`audio-file-${audioFileIdx}-wrapper`}
									>
										<div className="px-4 py-5 sm:p-6">
											<div className="mt-2 sm:flex sm:items-start sm:justify-between">
												<input
													type="hidden"
													name="audioFilesDestroy[]"
													id={`audio-file-destroy-${audioFile.id}`}
													value={audioFile.id}
													disabled="disabled"
												/>
												<div className="max-w-xl text-sm text-gray-500 ">
													<p>
														<Plyr
															{...{
																type: 'audio',
																sources: [
																	{
																		src: practiceStep?.audioFileUrls[audioFileIdx],
																		type: 'audio/mp3',
																	},
																],
															}}
														/>
													</p>
												</div>
												<div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
													<XCircleIcon
														key={`audio-file-${audioFileIdx}-remove`}
														data-audio-file-id={`${audioFile.id}`}
														data-audio-file-index={`${audioFileIdx}`}
														className="h-5 w-5 text-gray-400"
														aria-hidden="true"
														onClick={(event) => {
															practiceStep.audioFiles[
																parseInt(event.currentTarget.dataset.audioFileIndex)
															]._destroy = true;

															document
																.getElementById(
																	`audio-file-destroy-${event.currentTarget.dataset.audioFileId}`,
																)
																.removeAttribute('disabled');

															setUnmonitoredFieldChanged(true);

															setPracticeStep({ ...practiceStep });
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								))}

								<div
									className={
										practiceStep?.audioFiles?.length && practiceStep.audioFiles[0]._destroy !== true
											? 'hidden'
											: 'mt-1 sm:mt-0 sm:col-span-2'
									}
								>
									<input
										type="file"
										name="audioFilesCreate[]"
										id="audio-file-upload"
										accept=".mp3"
										onChange={() => setUnmonitoredFieldChanged(true)}
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm  "
									/>
									<p className="mt-2 text-sm text-gray-500 ">Accepts .mp3</p>
								</div>
							</div>
						</div>

						<div
							className={
								typeFields[watchStepType]?.includes('videoFiles')
									? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
									: 'hidden'
							}
						>
							<label
								htmlFor="videoFiles"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Video
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								{practiceStep?.videoFiles?.map((videoFile, videoFileIdx) => (
									<div
										className={videoFile._destroy ? 'hidden' : 'mt-1 relative rounded-md w-80'}
										key={`video-file-${videoFileIdx}-wrapper`}
									>
										<input type="hidden" name={'videoFilesAttributes[][id]'} value={videoFile.id} />
										<input type="hidden" name={'videoFilesAttributes[][service]'} value="vimeo" />
										<input
											type="hidden"
											name={'videoFilesAttributes[][externalId]'}
											value={videoFile.externalId}
										/>
										<input
											type="hidden"
											name={'videoFilesAttributes[][url]'}
											value={videoFile.url}
										/>
										<input
											type="hidden"
											name={'videoFilesAttributes[][status]'}
											value={videoFile.status}
										/>
										<input
											type="hidden"
											name={'videoFilesAttributes[][_destroy]'}
											value={videoFile._destroy || false}
										/>

										{/* Shown immediately after successful upload and before save */}
										<div className={videoFile.id ? 'hidden' : ''}>
											Upload Complete
											<p className="mt-2 text-sm text-gray-500 ">
												Click Save to continue and process video
											</p>
										</div>

										{/* Shown after successful save, but before video has been transcoded */}
										<div
											className={videoFile.id && videoFile.status !== 'available' ? '' : 'hidden'}
										>
											Video Processing
											<span
												className={
													'inline-block absolute mx-2 animate-spin rounded-full border-4 border-t-blue-500 w-6 h-6'
												}
											></span>
										</div>

										{/* Shown once video has been transcoded and is available in Vimeo */}
										<iframe
											className={
												videoFile.id && videoFile.status === 'available' ? 'h-60' : 'hidden'
											}
											key={videoFile.id + '-' + videoFile.status}
											src={`https://player.vimeo.com/video/${videoFile.externalId}?h=61fed5eeb7&badge=0&autopause=0&player_id=0&app_id=58479`}
											frameBorder="0"
											allow="fullscreen"
											allowFullScreen
											title="Video Upload"
										/>

										<div
											className={
												videoFile.status === 'available'
													? 'absolute top-0 right-0 pr-3 flex items-center'
													: 'hidden'
											}
										>
											<XCircleIcon
												key={`video-file-${videoFileIdx}-remove`}
												data-video-file-index={`${videoFileIdx}`}
												className="h-5 w-5 text-gray-400"
												aria-hidden="true"
												onClick={(event) => {
													practiceStep.videoFiles[
														parseInt(event.currentTarget.dataset.videoFileIndex)
													]._destroy = true;

													setUnmonitoredFieldChanged(true);

													setPracticeStep({ ...practiceStep });
												}}
											/>
										</div>
									</div>
								))}

								<div
									className={
										practiceStep?.videoFiles?.length && practiceStep.videoFiles[0]._destroy !== true
											? 'hidden'
											: ''
									}
								>
									<input
										type="file"
										name=""
										id="videoFileInput"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm  "
										onChange={handleVideoFileChange}
									/>
									<p
										className={
											practiceStep?.videoFiles?.length ? 'hidden' : 'mt-2 text-sm text-gray-500 '
										}
									>
										{typeFields[watchStepType]?.includes('landscape')
											? 'Accepts .mp4, .mov, .wmv, .avi, or .flv. Landscape recommended.'
											: 'Accepts .mp4, .mov, .wmv, .avi, or .flv. Portrait recommended.'}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							practiceStepId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						to={`/practices/${practiceId}`}
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={(!isDirty && !unmonitoredFieldChanged) || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Step"
				body="This will delete all content for the Step including user responses and cannot be undone. Are you sure?"
				onConfirm={deletePracticeStep}
				onCancel={() => setDeleteConfirm(false)}
			/>

			<ProgressBar isOpen={vimeoUploading} title="Uploading" percentComplete={vimeoUploadProgress} />

			<script src="https://player.vimeo.com/api/player.js" />
		</form>
	);
}

export default observer(PracticeStepForm);
