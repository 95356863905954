import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import userStore from '../../stores/UserStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';

function AuthorForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm();

	const { authorId } = useParams();
	const [author, setAuthor] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (authorId && !author && orgsStore.orgs.length) {
			fetchAuthor(authorId);
		}

		setSuccessMessage(authorId ? 'Author updated!' : 'Author created!');
	}, [author, orgsStore.currentOrg]);

	const fetchAuthor = async (authorId) => {
		const authorResponse = await tetherApi.getAuthor(authorId);

		setAuthor(authorResponse.data);

		reset(authorResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitAuthor(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/authors');
		}
	};

	const submitAuthor = async (data) => {
		const formData = new FormData(document.getElementById('author-form'));
		if (data.urlString && data.urlString.slice(0, 8) !== 'https://') {
			formData.set('urlString', `https://${data.urlString}`);
		}

		formData.append('isFeatured', data.isFeatured);

		if (data.id) {
			return await tetherApi.updateAuthor(data.id, formData);
		} else {
			return await tetherApi.createAuthor(formData);
		}
	};

	const deleteAuthor = async () => {
		const result = await tetherApi.deleteAuthor(authorId);

		if (result) {
			alertService.success('Author deleted');

			navigate('/authors');
		}
	};

	const renderIsFeatured = () => {
		if (userStore.user.isSuperUser === true) {
			return (
				<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
					<div>
						<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
						<p className="mt-1 text-sm leading-6 text-gray-600">Select whether to feature this author.</p>
					</div>
					<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
						<div className="col-span-full">
							<div className="relative flex gap-x-3 ">
								<div className="flex h-6 items-center">
									<input
										{...register('isFeatured')}
										type="checkbox"
										className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
									/>
								</div>
								<div className="text-sm leading-6">
									<label htmlFor="comments" className="font-medium text-gray-900">
										Featured
									</label>
									<p className="text-gray-500">
										Select this if you want to feature the author on the Explore Screen.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};

	return (
		<form className="p-12 space-y-8" id="author-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 sm:space-y-5">
				<div>
					<div>
						<h3 className="mb-6 text-3xl  font-semibold text-gray-900 ">
							{authorId ? 'Edit' : 'New'} Author
						</h3>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">Edit the name, bio, and website.</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
									Name*
								</label>
								<div className="mt-2">
									<input
										{...register('name', {
											required: 'Name is required',
											maxLength: 'Name must be 255 characters or less',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.name?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="name-error"
									>
										{errors.name?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="bio" className="block text-sm font-medium leading-6 text-gray-900">
									Bio
								</label>
								<div className="mt-2">
									<textarea
										{...register('bio')}
										rows={5}
										placeholder="Short bio of the author"
										className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.bio?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="bio-error"
									>
										{errors.bio?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="urlString"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Website
								</label>
								<div className="mt-2">
									<input
										{...register('urlString', {
											maxLength: 'URL must be 255 characters or less',
										})}
										type="text"
										placeholder="https://"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.urlString?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="url-string-error"
									>
										{errors.urlString?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Images</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">Edit the image and cover photo.</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="image" className="block text-sm font-medium leading-6 text-gray-900">
									Image*
								</label>
								<div className="mt-2">
									<input
										{...register('image', {
											required: !author?.imageUrl ? 'Image is required' : false,
										})}
										type="file"
										name="image"
										id="image"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50 file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">Accepts .png or .jpg</p>
									<p
										className={errors.image?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="image-error"
									>
										{errors.image?.message}
									</p>

									<div className={author?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={author?.imageUrl}
											alt=""
											className={author?.imageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="coverImage"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Cover Image
								</label>
								<div className="mt-2">
									<input
										{...register('coverImage')}
										type="file"
										name="coverImage"
										id="coverImage"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50 font-regular file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm  "
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">Accepts .png or .jpg</p>

									<div className={author?.coverImage ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={author?.coverImage}
											alt=""
											className={author?.coverImage ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>{renderIsFeatured()}</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							authorId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						to="/authors"
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Author"
				body="This will delete the Author and cannot be undone. Are you sure?"
				onConfirm={deleteAuthor}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(AuthorForm);
