import { Combobox } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';

export default function SearchBox({ onQueryChange, onSearchCancel, items = [], itemText }) {
	const [query, setQuery] = React.useState('');

	const handleQueryChange = (event) => {
		setQuery(event.target.value);

		onQueryChange(event.target.value);
	};
	const onCancel = () => {
		setQuery('');
		onSearchCancel();
	};

	return (
		<div className="grid justify-items-end">
			<Combobox onChange={(person) => (window.location = person.url)}>
				<div className="relative bg-gray-100 xs:w-full lg:w-96 rounded-lg">
					{/* <MagnifyingGlassIcon
						className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
						aria-hidden="true"
					/> */}
					<Combobox.Input
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
						placeholder={`Search ${itemText}...`}
						onChange={handleQueryChange}
					/>
					{!!query && (
						<XCircleIcon
							className="absolute right-2 top-2 pointer h-5 w-5 text-gray-400"
							onClick={onCancel}
						/>
					)}
				</div>

				{query !== '' && items.length === 0 && (
					<p className="p-4 text-sm text-gray-500 ">{`No ${itemText} found.`}</p>
				)}
			</Combobox>
		</div>
	);
}
