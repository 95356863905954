import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';

function AssessmentQuestionForm() {
	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({});

	const { assessmentId, assessmentQuestionId } = useParams();
	const [assessmentQuestion, setAssessmentQuestion] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [readOnly, setReadOnly] = useState(false);

	let navigate = useNavigate();

	const watchAssessmentQuestionType = watch('assessmentQuestionType');

	function classNames(...classes) {
		return classes.filter(Boolean).join(' ');
	}

	useEffect(() => {
		if (assessmentQuestionId && !assessmentQuestion && orgsStore.orgs.length) {
			fetchAssessmentQuestion(assessmentId, assessmentQuestionId);
		}

		setReadOnly(assessmentQuestion?.template !== null && assessmentQuestion?.template !== '');

		setSuccessMessage(assessmentQuestionId ? 'Question updated!' : 'Question created!');
	}, [assessmentQuestion, orgsStore.currentOrg]);

	const fetchAssessmentQuestion = async (assessmentId, assessmentQuestionId) => {
		const assessmentQuestionResponse = await tetherApi.getAssessmentQuestion(assessmentId, assessmentQuestionId);

		setAssessmentQuestion(assessmentQuestionResponse.data);

		reset(assessmentQuestionResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitAssessmentQuestion(data);

		if (result) {
			alertService.success(successMessage);

			navigate(`/assessments/${assessmentId}`);
		}
	};

	const reduceAnswerIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var answer = assessmentQuestion.assessmentQuestionAnswers[parseInt(currentIndex)];

		await tetherApi.updateAssessmentQuestionAnswer(assessmentQuestionId, answer.id, { index: answer.index - 1 });

		fetchAssessmentQuestion(assessmentId, assessmentQuestionId);
	};

	const increaseAnswerIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var answer = assessmentQuestion.assessmentQuestionAnswers[parseInt(currentIndex)];

		await tetherApi.updateAssessmentQuestionAnswer(assessmentQuestionId, answer.id, { index: answer.index + 1 });

		fetchAssessmentQuestion(assessmentId, assessmentQuestionId);
	};

	const submitAssessmentQuestion = async (data) => {
		const formData = new FormData(document.getElementById('assessment-question-form'));

		if (data.template === '' || data.template === null) {
			formData.delete('template');
		}

		if (data.id) {
			return await tetherApi.updateAssessmentQuestion(assessmentId, data.id, formData);
		} else {
			return await tetherApi.createAssessmentQuestion(assessmentId, formData);
		}
	};

	const deleteAssessmentQuestion = async () => {
		const result = await tetherApi.deleteAssessmentQuestion(assessmentId, assessmentQuestionId);

		if (result) {
			alertService.success('Question deleted');

			navigate(`/assessments/${assessmentId}`);
		}
	};

	return (
		<form
			className="p-12 space-y-8 divide-y divide-gray-200 "
			id="assessment-question-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-3xl leading-6 font-semibold  text-gray-900 ">
							{assessmentQuestionId ? 'Edit' : 'New'} Question
						</h3>
					</div>

					<div className="pb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200   sm:pt-8">
						<label
							htmlFor="template"
							className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
						>
							Template
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<select
								{...register('template')}
								disabled={assessmentQuestionId}
								className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
							>
								<option key="template-none" value="">
									Short Answer
								</option>
								<option key="template-agreement" value="agreement">
									Agreement (Agree, Disagree)
								</option>
								<option key="template-frequency" value="frequency">
									Frequency (Always, Never)
								</option>
								<option key="template-quality" value="quality">
									Quality (Very Good, Very Poor)
								</option>
								<option key="template-happiness" value="happiness">
									Emojis: Happiness Scale
								</option>
								<option key="template-thumbs-up-down" value="thumbs_up_down">
									Emojis: Thumbs Up / Down Scale
								</option>
								<option key="template-yes-no" value="yes_no">
									Yes No N/A
								</option>
							</select>
							<p
								className={errors.template?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
								id="template-error"
							>
								{errors.template?.message}
							</p>
						</div>
					</div>

					<div
						className={
							readOnly
								? 'hidden'
								: 'pb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
						}
					>
						<label
							htmlFor="assessmentQuestionType"
							className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
						>
							Question Type
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<select
								{...register('assessmentQuestionType', {
									required: 'Question Type is required',
								})}
								className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
							>
								<option key="assessmentQuestionType-short-answer" value="short_answer">
									Short Answer
								</option>
								<option key="assessmentQuestionType-multiple-choice" value="multiple_choice">
									Multiple Choice
								</option>
								<option
									key="assessmentQuestionType-multiple-choice-emoji"
									value="multiple_choice_emoji"
								>
									Multiple Choice Emoji
								</option>
							</select>
							<p
								className={
									errors.assessmentQuestionType?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
								}
								id="assessmentQuestionType-error"
							>
								{errors.assessmentQuestionType?.message}
							</p>
						</div>
					</div>

					<div
						className={
							readOnly || watchAssessmentQuestionType === 'short_answer'
								? 'hidden'
								: 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5'
						}
					>
						<label
							htmlFor="allowMultipleSelections"
							className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
						>
							Allow Multiple Selections
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<input
								{...register('allowMultipleSelections')}
								type="checkbox"
								className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300  rounded"
							/>
						</div>
					</div>

					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="question"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Question
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('question', {
										required: 'Question is required',
									})}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.question?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="question-error"
								>
									{errors.question?.message}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className="mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
					>
						Delete
					</button>

					<Link
						type="button"
						to={`/assessments/${assessmentId}`}
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<div className={assessmentQuestionId && watchAssessmentQuestionType !== 'short_answer' ? '' : 'hidden'}>
				<div className="pt-8">
					<div className="sm:flex sm:items-center">
						<h1 className="text-2xl font-semibold  text-gray-900 ">Answers</h1>
						<div className={readOnly ? 'hidden' : 'mt-4 sm:mt-0 sm:ml-16 sm:flex-none'}>
							<Link
								to={`/assessments/${assessmentId}/assessment_questions/${assessmentQuestionId}/assessment_question_answers/new`}
								className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 my-4 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
							>
								Add Answer
							</Link>
						</div>
					</div>
				</div>

				<div className="mt-8 flex flex-col">
					<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
							<div className="overflow-hidden shadow md:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300 ">
									<thead className="bg-gray-50 ">
										<tr>
											<th
												scope="col"
												className="py-3.5 pl-4 pr-3 text-left text-sm font-regular text-gray-900  sm:pl-6"
											>
												Answer
											</th>
											<th
												scope="col"
												className={
													readOnly
														? 'px-3 py-3.5 text-left text-sm font-regular text-gray-900 '
														: 'hidden'
												}
											>
												Score
											</th>
											<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
												<span className="sr-only">Edit</span>
											</th>
										</tr>
									</thead>
									<tbody className="bg-white ">
										{assessmentQuestion?.assessmentQuestionAnswers?.map(
											(assessmentQuestionAnswer, i) => (
												<tr
													key={assessmentQuestionAnswer.id}
													className={i % 2 === 0 ? undefined : 'bg-gray-50 '}
												>
													<td className="py-4 pl-4 pr-3 text-sm text-gray-900  sm:pl-6 max-w-md truncate font-regular">
														<ArrowUpCircleIcon
															data-index={i}
															className={classNames(
																readOnly ? 'hidden' : '',
																i === 0
																	? 'opacity-25 inline-block h-5 w-5 mr-4'
																	: 'cursor-pointer inline-block h-5 w-5 mr-4',
															)}
															onClick={i === 0 ? null : reduceAnswerIndex}
														/>
														<ArrowDownCircleIcon
															data-index={i}
															className={classNames(
																readOnly ? 'hidden' : '',
																i ===
																	assessmentQuestion.assessmentQuestionAnswers
																		.length -
																		1
																	? 'opacity-25 inline-block h-5 w-5 mr-4'
																	: 'cursor-pointer inline-block h-5 w-5 mr-4',
															)}
															onClick={
																i ===
																assessmentQuestion.assessmentQuestionAnswers.length - 1
																	? null
																	: increaseAnswerIndex
															}
														/>
														{assessmentQuestionAnswer.answer}
													</td>
													<td
														className={
															readOnly
																? 'px-3 py-4 text-sm text-gray-500  max-w-sm truncate font-regular'
																: 'hidden'
														}
													>
														{assessmentQuestionAnswer.score}
													</td>
													<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
														<Link
															to={`/assessments/${assessmentId}/assessment_questions/${assessmentQuestionId}/assessment_question_answers/${assessmentQuestionAnswer.id}`}
															className={
																readOnly
																	? 'hidden'
																	: 'text-neutral-800 hover:text-neutral-900'
															}
														>
															Edit
														</Link>
													</td>
												</tr>
											),
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Question"
				body="This will delete all Assessment responses for this Question and cannot be undone. Are you sure?"
				onConfirm={deleteAssessmentQuestion}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(AssessmentQuestionForm);
