import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';
import ReactQuill from 'react-quill';

function CustomFieldForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
		watch,
		getValues,
		setValue,
	} = useForm({});

	const { customFieldId } = useParams();
	const [customField, setCustomField] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	const watchFieldType = watch('fieldType');

	let navigate = useNavigate();

	useEffect(() => {
		if (customFieldId && !customField && orgsStore.orgs.length) {
			fetchCustomField(customFieldId);
		}

		setSuccessMessage(customFieldId ? 'Link updated!' : 'Link created!');
	}, [customField, orgsStore.currentOrg, deleteConfirm]);

	const handleHtmlChange = (content, delta, source, editor) => {
		setValue('html', editor.getHTML());

		setCustomField({ ...customField });
	};

	const fetchCustomField = async (customFieldId) => {
		const customFieldResponse = await tetherApi.getCustomField(customFieldId);

		setCustomField(customFieldResponse.data);

		reset(customFieldResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitCustomField(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/links');
		}
	};

	const submitCustomField = async (data) => {
		const formData = new FormData(document.getElementById('custom-field-form'));

		let actualLink = data.link;
		if (actualLink && actualLink.slice(0, 8) !== 'https://') {
			formData.set('link', `https://${actualLink}`);
		}

		if (data.file?.[0]) {
			formData.append('attachment', data.file[0]);
		}

		if (data.html) {
			formData.append('html', data.html);
		}

		formData.append('isEditable', data.isEditable);

		if (data.id) {
			return await tetherApi.updateCustomField(data.id, formData);
		} else {
			return await tetherApi.createCustomField(formData);
		}
	};

	const deleteCustomField = async () => {
		const result = await tetherApi.deleteCustomField(customFieldId);

		if (result) {
			alertService.success('Link deleted');

			navigate('/links');
		}
	};

	return (
		<form className="p-12 space-y-8" id="custom-field-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-xl font-semibold text-gray-900 ">{customFieldId ? '' : 'New Link'}</h3>
					</div>
					<div className="flex flex-row justify-between items-center w-full">
						<div>
							<h3 className="text-3xl font-semibold text-gray-900 max-w-2xl truncate">
								{customField?.label}
							</h3>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">Edit the name and type.</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="label" className="block text-sm font-medium leading-6 text-gray-900">
									Link Label
								</label>
								<div className="mt-2">
									<input
										{...register('label', { required: 'Title is required' })}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.label?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="label-error"
									>
										{errors.label?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="fieldType"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Type
								</label>
								<div className="mt-2">
									<select
										{...register('fieldType', {
											required: 'Field Type is required',
										})}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="fieldType-blank" value="">
											Select a Type...
										</option>
										{/* <option key={'fieldType-text'} value="text">
											Text
										</option> */}
										{/* <option key={'fieldType-file'} value="attachment">
											File Upload
										</option> */}
										<option key={'fieldType-link'} value="link">
											Link
										</option>
										{/* <option key={'fieldType-html'} value="html">
											Text Editor
										</option> */}
									</select>
									<p
										className={errors.fieldType?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="fieldType-error"
									>
										{errors.fieldType?.message}
									</p>
								</div>
							</div>

							<div
								className={
									watchFieldType === 'link'
										? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 col-span-full'
										: 'hidden'
								}
							>
								<label
									htmlFor="link"
									className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
								>
									URL
								</label>
								<div className="mt-1 sm:mt-0 sm:col-span-2">
									<input
										{...register('link')}
										type="text"
										name="link"
										id="link"
										className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
								</div>
							</div>

							<div
								className={
									watchFieldType === 'attachment'
										? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 col-span-full sm:pt-5'
										: 'hidden'
								}
							>
								<label
									htmlFor="file"
									className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
								>
									File
								</label>
								<div className="mt-1 sm:mt-0 sm:col-span-2">
									<input
										{...register('attachment')}
										type="file"
										name="attachment"
										id="attachment"
										accept="application/pdf"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50 font-regular file:border file:border-gray-300 file:text-gray-700 file:mr-4 max-w-lg block w-full rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm "
									/>
									<p className="mt-2 text-sm text-gray-500 font-regular">Accepts .pdf</p>

									<div className={customField?.fileUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<a href={customField?.fileUrl} className={customField?.fileUrl ? '' : 'hidden'}>
											View File
										</a>
									</div>
								</div>
							</div>

							<div
								className={
									watchFieldType === 'html'
										? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 col-span-full'
										: 'hidden'
								}
							>
								<label
									htmlFor="html"
									className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
								>
									HTML
								</label>
								<div className="mt-1 sm:mt-0 sm:col-span-2">
									<ReactQuill
										value={getValues('html') || ''}
										onChange={handleHtmlChange}
										modules={{
											toolbar: [
												[{ header: [false, 1, 2, 3] }],
												['bold', 'italic', 'underline'],
												[
													'link',
													'blockquote',
													{ list: 'ordered' },
													{ list: 'bullet' },
													{ indent: '-1' },
													{ indent: '+1' },
												],
												[{ align: ['', 'center', 'right'] }],
											],
										}}
										formats={[
											'header',
											'bold',
											'italic',
											'link',
											'underline',
											'script',
											'blockquote',
											'list',
											'align',
											'indent',
										]}
										name="html"
										rows="3"
										className="w-full h-80 overflow-scroll shadow-sm block focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-200  rounded-md  font-regular"
										theme="snow"
									/>

									<p
										className={errors.html?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="html-error"
									>
										{errors.html?.message}
									</p>
								</div>
							</div>

							{/* {watchFieldType !== 'attachment' &&
							watchFieldType !== 'link' &&
							watchFieldType !== 'html' ? (
								<div className="col-span-full">
									<div className="relative flex gap-x-3">
										<div className="flex h-6 items-center">
											<input
												{...register('isEditable')}
												type="checkbox"
												className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
											/>
										</div>
										<div className="text-sm leading-6">
											<label htmlFor="candidates" className="font-medium text-gray-900">
												Is Editable
											</label>
											<p className="text-gray-500">Allow users to edit fields</p>
										</div>
									</div>
								</div>
							) : null} */}
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							customFieldId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						type="button"
						to="/links"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Link"
				body="This will delete the link and cannot be undone. Are you sure?"
				onConfirm={deleteCustomField}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(CustomFieldForm);
