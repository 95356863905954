import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';

function LeaderResourceForm() {
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({});

	const { leaderResourceId, leaderResourceFolderId } = useParams();
	const [leaderResource, setLeaderResource] = useState(null);
	const [leaderResourceFolders, setLeaderResourceFolders] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (!leaderResourceFolders) {
			fetchLeaderResourceFolders();
		}

		if (leaderResourceId && !leaderResource && leaderResourceFolders && orgsStore.orgs.length) {
			fetchLeaderResource(leaderResourceId);
		}

		setSuccessMessage(leaderResourceId ? 'Resource updated!' : 'Resource created!');
	}, [leaderResource, leaderResourceFolders, orgsStore.currentOrg, deleteConfirm]);

	const fetchLeaderResource = async (leaderResourceId) => {
		const leaderResourceResponse = await tetherApi.getLeaderResource(leaderResourceId);

		setLeaderResource(leaderResourceResponse.data);

		reset(leaderResourceResponse.data);
	};

	const fetchLeaderResourceFolders = async () => {
		const leaderResourcesResponse = await tetherApi.getLeaderResources();

		setLeaderResourceFolders(leaderResourcesResponse.data.leaderResourceFolders);

		if (leaderResourceFolderId) {
			setValue('leaderResourceFolderId', leaderResourceFolderId);

			setLeaderResource({ ...leaderResource });
		}
	};

	const handleLinkChange = async (event) => {
		const url = event.target.value;
		var resourceType = 'link';

		if (url.match(/\.pdf/)) {
			resourceType = 'pdf';
		} else if (url.match(/youtube\.com|youtu\.be|vimeo\.com/)) {
			resourceType = 'video';
		} else if (url.match(/\.doc|\.pages|\.xls|\.numbers/)) {
			resourceType = 'doc';
		}

		setValue('resourceType', resourceType);

		setLeaderResource({ ...leaderResource });
	};

	const onSubmit = async (data) => {
		const result = await submitLeaderResource(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/resources');
		}
	};

	const submitLeaderResource = async (data) => {
		const formData = new FormData(document.getElementById('leader-resource-form'));

		if (data.link && data.link.slice(0, 8) !== 'https://') {
			formData.set('link', `https://${data.link}`);
		}

		if (data.file[0]) {
			formData.append('file', data.file[0]);
		}

		formData.append('isFeatured', data.isFeatured);
		formData.append('isMembersOnly', data.isMembersOnly);

		if (data.id) {
			return await tetherApi.updateLeaderResource(data.id, formData);
		} else {
			return await tetherApi.createLeaderResource(formData);
		}
	};

	const deleteLeaderResource = async () => {
		const result = await tetherApi.deleteLeaderResource(leaderResourceId);

		if (result) {
			alertService.success('Resource deleted');

			navigate('/resources');
		}
	};

	return (
		<form className="p-12 space-y-8 " id="leader-resource-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-xl leading-6 font-semibold  text-gray-900 ">
							{leaderResourceId ? 'Edit' : 'New'} Resource
						</h3>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Access</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select whether to publish or keep the content private and only access if you have the
								link.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label
									htmlFor="accessRole"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									User Role
								</label>
								<div className="mt-2">
									<select
										{...register('accessRole')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="access-role-all" value="">
											All Members
										</option>
										<option key="access-role-org-owner" value="org_owner">
											Admins
										</option>
										<option key="access-role-group-leader" value="group_leader">
											Leaders
										</option>
									</select>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Only specified Users will be able to access the Resource
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the name, upload a file or share a link, and select the type.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
									Name*
								</label>
								<div className="mt-2">
									<input
										{...register('title', { required: 'Title is required' })}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md  "
									/>
									<p
										className={errors.title?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="title-error"
									>
										{errors.title?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="link" className="block text-sm font-medium leading-6 text-gray-900">
									Link
								</label>
								<div className="mt-2">
									<input
										{...register('link')}
										type="text"
										onChange={handleLinkChange}
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md  "
									/>
									<p
										className={errors.link?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="link-error"
									>
										{errors.link?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
									File
								</label>
								<div className="mt-2">
									<input
										{...register('file')}
										type="file"
										name="file"
										id="file"
										accept="application/pdf"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm  "
									/>
									<p className="mt-2 text-sm text-gray-500 ">Accepts .pdf</p>
								</div>
								<div className={leaderResource?.fileUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
									<a
										href={leaderResource?.fileUrl}
										className={leaderResource?.fileUrl ? '' : 'hidden'}
									>
										View File
									</a>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="resourceType"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Type*
								</label>
								<div className="mt-2">
									<select
										{...register('resourceType', {
											required: 'Resource Type is required',
										})}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
									>
										<option key="resourceType-blank" value="">
											Select a Type...
										</option>
										<option key="resourceType-doc" value="doc">
											Document
										</option>
										<option key="resourceType-link" value="link">
											Link
										</option>
										<option key="resourceType-pdf" value="pdf">
											PDF
										</option>
										<option key="resourceType-video" value="video">
											Video
										</option>
									</select>
									<p
										className={
											errors.resourceType?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
										}
										id="resourceType-error"
									>
										{errors.resourceType?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Brand</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the brand details like image, and select whether to upload resource to a folder.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="image" className="block text-sm font-medium leading-6 text-gray-900">
									Rectangle Image - (1800 x 1080)
								</label>
								<div className="mt-2">
									<input
										{...register('image', {
											required: false,
										})}
										type="file"
										name="image"
										id="image"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50 file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Accepts .png, .jpg, .jpeg
									</p>
									<p
										className={errors.image?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="image-error"
									>
										{errors.image?.message}
									</p>

									<div className={leaderResource?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={leaderResource?.imageUrl}
											alt=""
											className={leaderResource?.imageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="image" className="block text-sm font-medium leading-6 text-gray-900">
									Square Image - (1080 x 1080)
								</label>
								<div className="mt-2">
									<input
										{...register('secondaryImage')}
										type="file"
										name="secondaryImage"
										id="secondaryImage"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Accepts .png, .jpg, .jpeg
									</p>

									<div
										className={
											leaderResource?.secondaryImageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'
										}
									>
										<img
											src={leaderResource?.secondaryImageUrl}
											alt=""
											className={leaderResource?.secondaryImageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="leaderResourceFolderId"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Folder
								</label>
								<div className="mt-2">
									<select
										{...register('leaderResourceFolderId')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
									>
										<option key="folder-blank" value="">
											Select a Folder...
										</option>
										{leaderResourceFolders?.map((folder) => (
											<option key={`folder-${folder.id}`} value={folder.id}>
												{folder.title}
											</option>
										))}
									</select>
									<p
										className={
											errors.leaderResourceFolderId?.message
												? 'mt-2 text-sm text-red-600'
												: 'hidden'
										}
										id="leaderResourceFolderId-error"
									>
										{errors.leaderResourceFolderId?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Options</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select from a range of options including whether to feature or restrict content.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('isFeatured')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Featured
										</label>
										<p className="text-gray-500">Feature this content</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('isMembersOnly')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Members Only
										</label>
										<p className="text-gray-500">Restrict to users with accounts</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							leaderResourceId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-regular rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						type="button"
						to="/resources"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-regular text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>
			<Confirm
				isOpen={deleteConfirm}
				title="Delete Resource"
				body="This will delete all responses for this Resource and cannot be undone. Are you sure?"
				onConfirm={deleteLeaderResource}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(LeaderResourceForm);
