import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../Pagination';
import SearchBox from '../SearchBox';
import { alertService } from '../../services/alert.service';
import Confirm from '../Confirm';
import userStore from '../../stores/UserStore';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

function GroupsList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [revealedEmails, setRevealedEmails] = useState([]);
	const [endIndex, setEndIndex] = useState(0);
	const [groups, setGroups] = useState([]);
	const [exportConfirm, setExportConfirm] = useState(false);
	const [sortBy, setSortBy] = useState('name');
	const [sortOrder, setSortOrder] = useState('asc');

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchGroups();
		}
	}, [page, orgsStore.currentOrg]);

	useEffect(() => {
		fetchGroups();
	}, [sortBy, sortOrder]);

	let navigate = useNavigate();

	const fetchGroups = async () => {
		const opts = {};
		if (sortBy) {
			opts.sortBy = sortBy;
		}
		if (sortOrder) {
			opts.sortOrder = sortOrder;
		}
		const groupsResponse = await tetherApi.getGroups(page, perPage, opts);

		setGroups(groupsResponse.data);

		const totalCount = groupsResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + groupsResponse.data.length);
	};

	const createExport = async () => {
		const result = await tetherApi.createExport({ exportType: 'Group' });

		if (result) {
			alertService.success('Export is being processed!');

			navigate('/exports');
		}
	};

	const searchGroups = async (query) => {
		if (query) {
			const groupsResponse = await tetherApi.searchGroups(query);
			if (groupsResponse?.data) {
				setGroups(groupsResponse.data);
			}
		} else {
			fetchGroups();
		}
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div>
				<div className="sm:flex sm:items-center justify-between">
					<div className="mr-4">
						<h1 className="text-lg font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-2 lg:pb-0">Groups</h1>
					</div>
					<SearchBox
						onQueryChange={searchGroups}
						onSearchCancel={fetchGroups}
						items={groups}
						itemText="Groups"
					/>
					<div className="sm:mt-0 xs:ml-0 ml-4 sm:flex-none">
						<button
							type="button"
							onClick={() => {
								setExportConfirm(true);
							}}
							className={
								total === 0 || !orgsStore.currentOrg
									? 'hidden'
									: 'mr-3 inline-flex items-center justify-center rounded-lg border bg-white px-4 py-2 text-sm font-medium shadow-sm'
							}
						>
							Export
						</button>
						<Link
							to="/groups/new"
							className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 xs:mt-4 lg:mt-0"
						>
							New Group
						</Link>
					</div>
				</div>
				<div className="">
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								{groups.length === 0 ? (
									<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
										<div className="text-center">
											<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
												No groups to display
											</p>
											<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
												No groups are available.
											</p>
											<Link
												to="/groups/new"
												className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
											>
												New Group
											</Link>
										</div>
									</div>
								) : (
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-white">
											<tr>
												<th
													scope="col"
													className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0 cursor-pointer"
													onClick={() => {
														if (sortBy !== 'name') {
															setSortBy('name');
															setSortOrder('asc');
														} else {
															if (sortOrder === 'asc') {
																setSortOrder('desc');
															} else {
																setSortOrder('asc');
															}
														}
													}}
												>
													<div className="flex flex-row">
														Name
														{sortBy === 'name' ? (
															<div className="pl-2">
																{sortOrder === 'asc' ? (
																	<ChevronDownIcon
																		className="h-5 w-5"
																		aria-hidden="true"
																	/>
																) : (
																	<ChevronUpIcon
																		className="h-5 w-5"
																		aria-hidden="true"
																	/>
																)}
															</div>
														) : null}
													</div>
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
													onClick={() => {
														if (sortBy !== 'description') {
															setSortBy('description');
															setSortOrder('asc');
														} else {
															if (sortOrder === 'asc') {
																setSortOrder('desc');
															} else {
																setSortOrder('asc');
															}
														}
													}}
												>
													<div className="flex flex-row">
														Description
														{sortBy === 'description' ? (
															<div className="pl-2">
																{sortOrder === 'asc' ? (
																	<ChevronDownIcon
																		className="h-5 w-5"
																		aria-hidden="true"
																	/>
																) : (
																	<ChevronUpIcon
																		className="h-5 w-5"
																		aria-hidden="true"
																	/>
																)}
															</div>
														) : null}
													</div>
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Users
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Invite Code
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Edit
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{groups.map((group, groupIdx) => (
												<tr
													key={group.id}
													className={groupIdx % 2 === 0 ? undefined : 'bg-white'}
												>
													<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
														{group.name}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm max-w-xs truncate text-gray-500">
														{group.description}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{group.userCount}
													</td>
													<td
														onClick={() => {
															if (revealedEmails.includes(group.id)) {
																setRevealedEmails(
																	revealedEmails.filter((id) => id !== group.id),
																);
															} else {
																setRevealedEmails([...revealedEmails, group.id]);
															}
														}}
														className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate cursor-pointer ${
															revealedEmails.includes(group.id) ? '' : 'blur-sm'
														}`}
													>
														{group?.publicToken}
													</td>
													{/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{group?.publicToken}
													</td> */}
													<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
														<Link
															to={`/groups/${group.id}`}
															className="font-medium text-neutral-800 hover:text-neutral-900"
														>
															Edit
														</Link>
														{userStore.user.isSuperUser === true && (
															<Link
																to={`/groups/${group.id}/update_org`}
																className="font-medium text-neutral-800 hover:text-neutral-900 ml-10"
															>
																Edit Organization
															</Link>
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								)}
							</div>
						</div>
					</div>
				</div>
				<Pagination
					page={page}
					setPage={setPage}
					numPages={numPages}
					startIndex={startIndex}
					endIndex={endIndex}
					total={total}
				/>
			</div>
			<Confirm
				isOpen={exportConfirm}
				title="Export Users"
				body="This will create a .csv file and email it to your email address. You will also be able to view it by clicking Account > Exports. Are you sure you want to export your group list?"
				onConfirm={createExport}
				onCancel={() => setExportConfirm(false)}
			/>
		</div>
	);
}

export default observer(GroupsList);
