import React from 'react';
import userStore from '../stores/UserStore';
import orgsStore from '../stores/OrgsStore';
// import { useNavigate } from 'react-router-dom';

const BasicBanner = () => {
	// const navigate = useNavigate();
	const getTier = () => {
		if (userStore.user?.isSuperUser) {
			return 'super';
		}
		if (orgsStore.currentOrg?.tier === 'pro') {
			return 'pro';
		}
		return 'basic';
	};
	if (getTier() === 'basic' && !userStore.user?.isSuperUser) {
		return (
			<div className="absolute top-0 left-0 right-0 flex flex-row justify-center py-0">
				{/* <p>To activate your account and unlock all features, you need an</p>
				<a onClick={() => navigate('/billing')} className="cursor-pointer ml-1 text-blue-700">
					{' '}
					active subscription
				</a> */}
			</div>
		);
	}
	return <div />;
};

export default BasicBanner;
