import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { alertService } from '../../services/alert.service';

function PlanningCenterCallback() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		//Detect callback
		if (searchParams.get('code')) {
			const result = tetherApi.completePlanningCenterOAuth(searchParams.get('code'));

			if (result) {
				alertService.success('Planning Center Integration connected');

				navigate('/integrations');
			}
		} else {
			navigate('/integrations');
		}
	}, [orgsStore.currentOrg]);

	return <div className="px-4 sm:px-6 lg:px-12"></div>;
}

export default observer(PlanningCenterCallback);
