import { WebAuth } from 'auth0-js';
import { alertService } from '../services/alert.service';
import orgsStore from '../stores/OrgsStore';
import userStore from '../stores/UserStore';

export const auth0LoginPage = new WebAuth({
	clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
	redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI_LOGIN,
	responseType: 'token id_token',
});

export const auth0CreateAccount = new WebAuth({
	clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
	redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI_CREATE_ACCOUNT,
	responseType: 'token id_token',
});

export function sendCode(auth0, phone) {
	auth0.passwordlessStart(
		{
			connection: 'sms',
			send: 'code',
			phoneNumber: `+1${phone}`,
		},
		(err) => {
			if (err) {
				alertService.error('Login code could not be sent');

				return console.log(err);
			}
		},
	);
}

export function sendCodeToEmail(auth0, email) {
	auth0.passwordlessStart(
		{
			connection: 'email',
			send: 'code',
			email,
		},
		(err) => {
			if (err) {
				alertService.error('Login code could not be sent');

				return console.log(err);
			}
		},
	);
}

export function completeLogin(auth0, phone, verificationCode) {
	auth0.passwordlessLogin(
		{
			connection: 'sms',
			phoneNumber: `+1${phone}`,
			verificationCode: verificationCode,
		},
		function (err) {
			if (err) {
				alertService.warn('Invalid code');

				return console.log(err);
			}
		},
	);
}

export function completeLoginWithEmail(auth0, email, verificationCode) {
	auth0.passwordlessLogin(
		{
			connection: 'email',
			email,
			verificationCode,
		},
		function (err) {
			if (err) {
				alertService.warn('Invalid code');

				return console.log(err);
			}
		},
	);
}

export function processJwt(auth0, callback) {
	auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
		if (err) {
			alertService.error('Login could not be completed');

			return console.log(err);
		}

		callback(authResult.idToken);
	});
}

export function logout() {
	userStore.removeJwt();
	orgsStore.removeCurrentOrg();

	auth0LoginPage.logout({
		returnTo: process.env.AUTH0_REDIRECT_URI,
		clientID: process.env.AUTH0_CLIENT_ID,
	});

	window.location = '/login';
}

export async function verifyAdmin() {
	const user = await userStore.fetchUser();
	if (user && !user.isSuperUser && user.orgs) {
		if (!user.orgs.length) {
			// LOG OUT when the user isn't an admin in any orgs.
			logout();
		}
	}
}
