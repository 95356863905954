import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, useNavigate, useLocation } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import './index.css';
import App from './App.jsx';

// use-query-params adapeter for React Router 6
const RouteAdapter = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const adaptedHistory = React.useMemo(
		() => ({
			replace(location) {
				navigate(location, { replace: true, state: location.state });
			},
			push(location) {
				navigate(location, { replace: false, state: location.state });
			},
		}),
		[navigate],
	);
	return children({ history: adaptedHistory, location });
};

const root = document.getElementById('root');
root.classList.add('h-screen');
// root.classList.add('dark:bg-black-5');
root.classList.add('bg-white');
render(
	<BrowserRouter>
		<QueryParamProvider ReactRouterRoute={RouteAdapter}>
			<App />
		</QueryParamProvider>
	</BrowserRouter>,
	root,
);
