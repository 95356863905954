import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../Pagination';

function AccessCodesList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [accessCodes, setAccessCodes] = useState([]);
	const [pathway, setPathway] = useState(null);
	const [passcode, setPasscode] = useState('');
	const [detailsUrl, setDetailsUrl] = useState('');
	const [detailsUrlLabel, setDetailsUrlLabel] = useState('');
	const [publicPasscodeDetailsUrl, setPublicPasscodeDetailsUrl] = useState('');
	const [publicDetailsUrlLabel, setPublicDetailsUrlLabel] = useState('');
	const { pathwayId } = useParams();

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchAccessCodes();
			fetchPathway();
		}
	}, [page, orgsStore.currentOrg]);

	const fetchPathway = async () => {
		if (pathwayId) {
			const pathwayResponse = await tetherApi.getPathway(pathwayId);
			if (pathwayResponse) {
				setPathway(pathwayResponse.data);
				if (pathwayResponse?.data?.passcode) {
					setPasscode(pathwayResponse.data.passcode);
				}
				if (pathwayResponse?.data?.detailsUrl) {
					setDetailsUrl(pathwayResponse.data.detailsUrl);
				}
				if (pathwayResponse?.data?.detailsUrlLabel) {
					setDetailsUrlLabel(pathwayResponse.data.detailsUrlLabel);
				}
				if (pathwayResponse?.data?.publicPasscodeDetailsUrl) {
					setPublicPasscodeDetailsUrl(pathwayResponse.data.publicPasscodeDetailsUrl);
				}
				if (pathwayResponse?.data?.publicDetailsUrlLabel) {
					setPublicDetailsUrlLabel(pathwayResponse.data.publicDetailsUrlLabel);
				}
			}
		}
	};

	const fetchAccessCodes = async () => {
		const accessCodesResponse = await tetherApi.getAccessCodes(pathwayId, page, perPage);

		if (accessCodesResponse) {
			setAccessCodes(accessCodesResponse.data);

			const totalCount = accessCodesResponse.total;
			const pagesCount = Math.ceil(totalCount / perPage);

			setTotal(totalCount);
			setNumPages(pagesCount);
			setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
			setEndIndex(perPage * (page - 1) + accessCodesResponse.data.length);
		}
	};

	return (
		<div className="pt-6 px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-3xl  font-semibold text-gray-900 ">Access Codes</h1>
				</div>
			</div>

			<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900">Member Access</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600">Set the primary passcode for members.</p>
				</div>
				<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
					<div className="col-span-full">
						<label htmlFor="accessRole" className="block text-sm font-medium leading-6 text-gray-900">
							Primary Passcode
						</label>
						<div className="mt-2">
							<input
								value={passcode}
								onChange={(e) => {
									setPasscode(e.target.value);
								}}
								type="text"
								name="passcode"
								placeholder="E.g. TETHER"
								id="passcode"
								className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md  "
							/>
							<p className="mt-2 text-sm text-gray-500  font-regular">
								Case senstive. Set this to lock the plan for users
							</p>
						</div>
					</div>
					<div className="col-span-full">
						<label htmlFor="detailsUrl" className="block text-sm font-medium leading-6 text-gray-900">
							Website
						</label>
						<div className="mt-2">
							<input
								value={detailsUrl}
								onChange={(e) => {
									setDetailsUrl(e.target.value);
								}}
								type="text"
								name="detailsUrl"
								placeholder=""
								id="detailsUrl"
								className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md  "
							/>
							<p className="mt-2 text-sm text-gray-500  font-regular">
								Add a link to let the user learn more why it's locked
							</p>
						</div>
					</div>
					<div className="col-span-full">
						<label htmlFor="detailsUrl" className="block text-sm font-medium leading-6 text-gray-900">
							Button Label
						</label>
						<div className="mt-2">
							<input
								value={detailsUrlLabel}
								onChange={(e) => {
									setDetailsUrlLabel(e.target.value);
								}}
								type="text"
								name="detailsUrlLabel"
								placeholder=""
								id="detailsUrlLabel"
								className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md  "
							/>
							<p className="mt-2 text-sm text-gray-500  font-regular">Purchase, Learn More etc.</p>
						</div>
					</div>
				</div>
			</div>

			<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900">Public Access</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600">Set the label and URL for public access.</p>
				</div>
				<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
					<div className="col-span-full">
						<label htmlFor="publicDetailsUrl" className="block text-sm font-medium leading-6 text-gray-900">
							Website
						</label>
						<div className="mt-2">
							<input
								value={publicPasscodeDetailsUrl}
								onChange={(e) => {
									setPublicPasscodeDetailsUrl(e.target.value);
								}}
								type="text"
								name="publicDetailsUrl"
								placeholder=""
								id="publicDetailsUrl"
								className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md  "
							/>
							<p className="mt-2 text-sm text-gray-500  font-regular">
								Add a link to let the user learn more why it's locked
							</p>
						</div>
					</div>
					<div className="col-span-full">
						<label
							htmlFor="publicDetailsUrlLabel"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Button Label
						</label>
						<div className="mt-2">
							<input
								value={publicDetailsUrlLabel}
								onChange={(e) => {
									setPublicDetailsUrlLabel(e.target.value);
								}}
								type="text"
								name="publicDetailsUrlLabel"
								placeholder=""
								id="publicDetailsUrlLabel"
								className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md  "
							/>
							<p className="mt-2 text-sm text-gray-500  font-regular">Purchase, Learn More etc.</p>
						</div>
					</div>
				</div>
			</div>

			<div className="flex justify-end sm:pt-5 sm:pb-5 sm:mt-5">
				<div
					type="button"
					className={
						'inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800'
					}
					onClick={async () => {
						const response = await tetherApi.updatePathway(pathwayId, {
							...pathway,
							passcode,
							detailsUrl,
							detailsUrlLabel,
							publicDetailsUrlLabel,
							publicPasscodeDetailsUrl,
						});
						setPathway(response.data);
					}}
				>
					Save
				</div>
			</div>
			<div className="pt-16 sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-3xl font-semibold text-gray-900 ">Additional Codes</h1>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to={`/plans/${pathwayId}/access_codes/new`}
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
					>
						New Code
					</Link>
				</div>
			</div>
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="">
									<tr>
										<th
											scope="col"
											className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
										>
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Code
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Redemptions</span>
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Delete</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{accessCodes.map((accessCode, accessCodeIdx) => (
										<tr key={accessCode.id} className={accessCodeIdx % 2 === 0 ? undefined : ''}>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
												{accessCode.name}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{accessCode.token}
											</td>
											<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
												<Link
													to={`${accessCode.id}/redemptions`}
													className="text-neutral-800 hover:text-neutral-900"
												>
													Redemptions
												</Link>
											</td>
											<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
												<Link
													to={`${accessCode.id}`}
													className="text-neutral-800 hover:text-neutral-900"
												>
													Edit
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(AccessCodesList);
