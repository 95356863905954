import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';

function CollectionItemForm() {
	const {
		register,
		handleSubmit,
		watch,
		formState: { isSubmitting, isDirty },
	} = useForm({
		defaultValues: {
			itemType: 'Pathway',
		},
	});

	const { collectionId, collectionItemId } = useParams();
	const [items, setItems] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');

	let navigate = useNavigate();
	let watchItemType = watch('itemType');

	useEffect(() => {
		fetchItems();

		setSuccessMessage('Item added!');
	}, [watchItemType, orgsStore.currentOrg]);

	const fetchItems = async () => {
		let itemsResponse = null;

		// Fetch either Pathways or Practices
		if (watchItemType === 'Pathway') {
			itemsResponse = await tetherApi.getPathways(1, 100, true);
		} else if (watchItemType === 'OrgHabit') {
			itemsResponse = await tetherApi.getOrgHabits(1, 100);
		} else if (watchItemType === 'LeaderResource') {
			itemsResponse = await tetherApi.getLeaderResources(1, 100);
		} else {
			itemsResponse = await tetherApi.getPractices(1, 100, true);
		}

		if (itemsResponse) {
			if (watchItemType === 'LeaderResource') {
				setItems(itemsResponse.data.leaderResources);
			} else {
				setItems(itemsResponse.data);
			}
		}
	};

	const onSubmit = async (data) => {
		const result = await submitCollectionItem(data);

		if (result) {
			alertService.success(successMessage);

			navigate(`/collections/${collectionId}`);
		}
	};

	const submitCollectionItem = async (data) => {
		const formData = new FormData(document.getElementById('collection-item-form'));

		if (data.id) {
			return await tetherApi.updateCollectionItem(collectionId, data.id, formData);
		} else {
			return await tetherApi.createCollectionItem(collectionId, formData);
		}
	};

	return (
		<form
			className="p-12 space-y-8 divide-y divide-gray-200 "
			id="collection-item-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-3xl font-semibold  leading-6 text-gray-900 ">
							{collectionItemId ? 'Edit' : 'Add'} Item
						</h3>
					</div>

					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="itemType"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Item Type
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<select
									{...register('itemType')}
									className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								>
									<option key="item-type-pathway" value="Pathway">
										Plan
									</option>
									<option key="item-type-practice" value="Practice">
										Practice
									</option>
									<option key="item-type-org-habit" value="OrgHabit">
										Habit
									</option>
									<option key="item-type-resource" value="LeaderResource">
										Resource
									</option>
								</select>
							</div>
						</div>

						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="itemId"
								className="block text-base font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Item
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<select
									{...register('itemId')}
									className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								>
									<option key="folder-blank" value="">
										Select an Item...
									</option>
									{items?.map((item) => (
										<option key={`item-${item.id}`} value={item.id}>
											{item.title || item.name}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<Link
						type="button"
						to={`/collections/${collectionId}`}
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>
		</form>
	);
}

export default observer(CollectionItemForm);
