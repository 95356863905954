import * as tus from 'tus-js-client';
import { observer } from 'mobx-react-lite';

class VimeoApi {
	uploadVideo = async (file, preSignedResponse, onProgress, onSuccess, onError) => {
		if (preSignedResponse?.data) {
			const uploadURI = preSignedResponse.data.uploadLink;
			const vimeoVideoLink = preSignedResponse.data.uri;
			const vimeoId = vimeoVideoLink.split('/').slice(-1)[0];

			const uploader = new tus.Upload(file, {
				uploadUrl: uploadURI,
				endpoint: uploadURI,
				onError: (error) => {
					onError?.(error);
				},
				onProgress: (bytesUploaded, bytesTotal) => {
					onProgress?.(bytesUploaded, bytesTotal);
				},
				onSuccess: () => {
					onSuccess?.(vimeoId);
				},
			});
			uploader.start();
		}
	};
}

const vimeoApi = observer(new VimeoApi());

export default vimeoApi;
