import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { accessRoleLabels, stepTypeLabels } from '../../utils/humanReadableLabels';
import ReactQuill from 'react-quill';

function CourseSectionForm() {
	const {
		register,
		getValues,
		setValue,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({});

	const [authors, setAuthors] = useState([]);
	const { courseId, courseSectionId } = useParams();
	const [courseSection, setCourseSection] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [duplicateConfirm, setDuplicateConfirm] = useState(false);
	const [unmonitoredFieldChanged, setUnmonitoredFieldChanged] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (!authors.length) {
			fetchAuthors();
		}

		if (courseSectionId && !courseSection && authors.length && orgsStore.orgs.length) {
			fetchCourseSection(courseId, courseSectionId);
		}

		setSuccessMessage(courseSectionId ? 'Course Section updated!' : 'Course Section created!');
	}, [authors, courseSection, orgsStore.currentOrg, duplicateConfirm]);

	const fetchAuthors = async () => {
		const authorsResponse = await tetherApi.getAuthors();

		setAuthors(authorsResponse.data);
	};

	const fetchCourseSection = async (courseId, courseSectionId) => {
		const courseSectionResponse = await tetherApi.getCourseSection(courseId, courseSectionId);

		if (courseSectionResponse?.data) {
			setCourseSection(courseSectionResponse.data);
			reset(courseSectionResponse.data);
		}
	};

	const reduceCourseSectionStepIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var courseSectionStep = courseSection.courseSectionSteps[parseInt(currentIndex)];

		await tetherApi.updateCourseSectionStep(courseSectionId, courseSectionStep.id, {
			index: courseSectionStep.index - 1,
		});

		fetchCourseSection(courseId, courseSectionId);
	};

	const increaseCourseSectionStepIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var courseSectionStep = courseSection.courseSectionSteps[parseInt(currentIndex)];

		await tetherApi.updateCourseSectionStep(courseSectionId, courseSectionStep.id, {
			index: courseSectionStep.index + 1,
		});

		fetchCourseSection(courseId, courseSectionId);
	};

	const onSubmit = async (data) => {
		const result = await submitCourseSection(data);

		if (result) {
			alertService.success(successMessage);

			navigate(`/courses/${courseId}`);
		}
	};

	const handleHtmlChange = (content, delta, source, editor) => {
		setUnmonitoredFieldChanged(true);

		setValue('html', editor.getHTML());

		setCourseSection({ ...courseSection });
	};

	const submitCourseSection = async (data) => {
		const formData = new FormData(document.getElementById('course-section-form'));
		if (getValues('html') !== null && getValues('html') !== undefined && getValues('html') !== '') {
			formData.append('html', getValues('html'));
		}

		if (data.id) {
			return await tetherApi.updateCourseSection(courseId, data.id, formData);
		} else {
			return await tetherApi.createCourseSection(courseId, formData);
		}
	};

	const deleteCourseSection = async () => {
		const result = await tetherApi.deleteCourseSection(courseId, courseSectionId);

		if (result) {
			alertService.success('Course Section deleted');

			navigate(`/courses/${courseId}`);
		}
	};

	const duplicateCourseSection = async () => {
		const formData = new FormData(document.getElementById('course-section-form'));
		const result = await tetherApi.duplicateCourseSection(courseId, courseSectionId, formData);

		if (result) {
			alertService.success('Course section duplicated');
			navigate(`/courses/${courseId}`);
		}
	};

	return (
		<form className="p-12 space-y-8" id="course-section-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-3xl font-semibold text-gray-900 ">{courseSectionId ? '' : 'New Lesson'}</h3>
					</div>
					<div className="flex flex-row justify-between items-center w-full mb-16">
						<div>
							<h3 className="text-3xl font-semibold text-gray-900 max-w-2xl truncate">
								{courseSection?.name}
							</h3>
						</div>
						<div>
							<div>
								{!!courseSection?.webUrl && (
									<div>
										<button
											type="button"
											onClick={() => setDuplicateConfirm(true)}
											className={
												courseId
													? 'justify-center py-2 px-4 text-sm font-medium rounded-md text-neutral-800 bg-white shadow-sm border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
													: 'hidden'
											}
										>
											Duplicate
										</button>

										<Link
											to={`https://tether-rails-production.herokuapp.com/api/v3/courses/${courseSection.courseId}/course_sections/${courseSection.id}.pdf`}
											className="justify-center py-2.5 ml-3 px-4 text-sm font-medium rounded-md text-white bg-neutral-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
										>
											Download PDF
										</Link>
										<button
											type="button"
											onClick={() => navigator.clipboard.writeText(courseSection?.webUrl)}
											className="cursor-pointer disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
										>
											Copy URL
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the name, subtitle, author, and more.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
									Name*
								</label>
								<div className="mt-2">
									<input
										{...register('name', {
											required: 'Name is required',
											maxLength: 'Name must be 255 characters or less',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.name?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="name-error"
									>
										{errors.name?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="subtitle" className="block text-sm font-medium leading-6 text-gray-900">
									Subtitle
								</label>
								<div className="mt-2">
									<input
										{...register('subtitle', {
											maxLength: 'Name must be 255 characters or less',
										})}
										type="text"
										name="subtitle"
										id="subtitle"
										placeholder="A short description of the section"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="overview" className="block text-sm font-medium leading-6 text-gray-900">
									Overview
								</label>
								<div className="mt-2">
									<textarea
										{...register('overview')}
										rows={3}
										placeholder="A short overview of the section"
										className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.overview?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="overview-error"
									>
										{errors.overview?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="authorId" className="block text-sm font-medium leading-6 text-gray-900">
									Author
								</label>
								<div className="mt-2">
									<select
										{...register('authorId')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="author-blank" value="">
											Select an Author...
										</option>
										{authors.map((author) => (
											<option key={`author-${author.id}`} value={author.id}>
												{author.name}
											</option>
										))}
									</select>
									<p
										className={errors.author?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="author-error"
									>
										{errors.author?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full"></div>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Body</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the main content body of the course section.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<div className="mt-2 ">
									<ReactQuill
										value={getValues('html') || ''}
										onChange={handleHtmlChange}
										modules={{
											toolbar: [
												[{ header: [false, 1, 2, 3] }],
												['bold', 'italic', 'underline'],
												[
													'link',
													'blockquote',
													{ list: 'ordered' },
													{ list: 'bullet' },
													{ indent: '-1' },
													{ indent: '+1' },
												],
												[{ align: ['', 'center', 'right'] }],
											],
										}}
										formats={[
											'header',
											'bold',
											'italic',
											'link',
											'underline',
											'script',
											'blockquote',
											'list',
											'align',
											'indent',
										]}
										name="html"
										rows="8"
										className="w-full h-80 overflow-scroll shadow-sm block focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-200  rounded-md  font-regular"
										theme="snow"
									/>
									<p
										className={
											errors.overviewSteps?.message
												? 'mt-2 text-sm text-red-600 font-regular'
												: 'hidden'
										}
										id="overviewSteps-error"
									>
										{errors.overviewSteps?.message}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							// courseSectionId && !courseSection?.course?.groupcoursesExist
							courseSectionId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>
					<Link
						type="button"
						to={`/courses/${courseId}`}
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={(!isDirty && !unmonitoredFieldChanged) || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
				{/* <p
					className={
						courseSectionId
							? 'mt-2 text-sm text-gray-500  text-right font-regular'
							: 'hidden'
					}
				>
					Course Sections cannot be deleted after groups have participated in the Course
				</p> */}
			</div>

			<div className={courseSectionId ? '' : 'hidden'}>
				<div className="mt-16 space-y-8 divide-y divide-gray-200  sm:space-y-5">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-2xl font-semibold  text-gray-900 ">All Steps</h1>
						</div>
						<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
							<Link
								to={`/courses/${courseId}/course_sections/${courseSection?.id}/course_section_steps/new`}
								className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
							>
								New Step
							</Link>
						</div>
					</div>
				</div>

				<div className="">
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Type
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Name
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Content
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Role
											</th>
											<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
												<span className="sr-only">Edit</span>
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{courseSection?.courseSectionSteps?.map((courseSectionStep, i) => (
											<tr
												key={courseSectionStep.id}
												className={i % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0 font-regular">
													<ArrowUpCircleIcon
														data-index={i}
														className={
															i === 0
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={i === 0 ? null : reduceCourseSectionStepIndex}
													/>
													<ArrowDownCircleIcon
														data-index={i}
														className={
															i === courseSection?.courseSectionSteps?.length - 1
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={
															i === courseSection?.courseSectionSteps?.length - 1
																? null
																: increaseCourseSectionStepIndex
														}
													/>

													{stepTypeLabels[courseSectionStep.stepType]}
												</td>
												<td className="whitespace-nowrap py-4 px-3 pr-3 text-sm font-medium text-gray-900 max-w-xs truncate">
													{courseSectionStep.title}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xs truncate font-regular">
													{courseSectionStep.body?.replace(/<\/?[^>]+(>|$)/g, '')}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate font-regular">
													{accessRoleLabels[courseSectionStep.accessRole] || 'All Members'}
												</td>
												<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
													<Link
														to={`/courses/${courseId}/course_sections/${courseSectionStep.courseSectionId}/course_section_steps/${courseSectionStep.id}`}
														className="text-neutral-800 hover:text-neutral-900"
													>
														Edit
														<span className="sr-only">, {courseSectionStep.title}</span>
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Course Section"
				body="This will delete all content for the Course Section and cannot be undone. Are you sure?"
				onConfirm={deleteCourseSection}
				onCancel={() => setDeleteConfirm(false)}
			/>
			<Confirm
				isOpen={duplicateConfirm}
				title="Duplicate Course Section"
				body="This will duplicate all content and create a new course section. Are you sure?"
				onConfirm={duplicateCourseSection}
				onCancel={() => setDuplicateConfirm(false)}
			/>
		</form>
	);
}

export default observer(CourseSectionForm);
