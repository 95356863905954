import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import 'react-datepicker/dist/react-datepicker.css';
import Confirm from '../Confirm';
import { alertService } from '../../services/alert.service';
import userStore from '../../stores/UserStore';

function GroupOrgForm() {
	const {
		register,
		reset,
		formState: { isSubmitting, isDirty },
	} = useForm({});

	const { groupId } = useParams();
	const [group, setGroup] = useState(null);
	const [updateConfirm, setUpdateConfirm] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		// Protect form from non-super-users
		if (userStore.user && !userStore.user.isSuperUser) {
			navigate('/groups');
		}

		if (groupId && !group && orgsStore.orgs.length) {
			fetchGroup(groupId);
		}
	}, [group, orgsStore.currentOrg, updateConfirm]);

	const fetchGroup = async (groupId) => {
		const groupResponse = await tetherApi.getGroup(groupId);

		setGroup(groupResponse.data);

		reset(groupResponse.data);
	};

	const updateGroupOrg = async () => {
		const orgId = document.getElementById('group[orgId]').value;

		const result = await tetherApi.updateGroupOrg(groupId, orgId);

		if (result) {
			alertService.success('Group Org updated!');

			navigate('/groups');
		}
	};

	return (
		<form className="p-10" id="group-form">
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-3xl leading-6 font-semibold  text-gray-900 ">Edit Organization</h3>
					</div>

					<div className="mt-8 space-y-6 sm:space-y-5">
						<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
							<div className="col-span-full">
								<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
									Name
								</label>
								<div className="mt-2">{group?.name}</div>
							</div>
						</div>

						<div className="col-span-full">
							<label htmlFor="orgId" className="block text-sm font-medium leading-6 text-gray-900">
								Organization
							</label>
							<div className="mt-2">
								<select
									{...register('orgId')}
									id="group[orgId]"
									className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								>
									<option key="org-blank" value="">
										No Org (Private Group)
									</option>

									{orgsStore.orgs.map((org) => (
										<option key={`org-${org.id}`} value={org.id}>
											{org.name}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<Link
						to="/groups"
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
						type="button"
						onClick={() => setUpdateConfirm(true)}
						disabled={!isDirty || isSubmitting}
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={updateConfirm}
				title="Update Group Org"
				body="This will update the Group's Org, affecting all Users and the content they can access. Are you sure?"
				onConfirm={updateGroupOrg}
				onCancel={() => setUpdateConfirm(false)}
			/>
		</form>
	);
}

export default observer(GroupOrgForm);
