import React, { Fragment, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dialog, Transition, Disclosure, Menu } from '@headlessui/react';
import {
	XMarkIcon,
	CreditCardIcon,
	Bars3BottomLeftIcon,
	LinkIcon,
	UsersIcon,
	HomeIcon,
	Cog6ToothIcon,
	CommandLineIcon,
	SquaresPlusIcon,
	BookOpenIcon,
	QuestionMarkCircleIcon,
	// ChatBubbleOvalLeftEllipsisIcon
} from '@heroicons/react/24/outline';
import { logout, verifyAdmin } from '../utils/auth';
import { Alert } from './Alert';
import { observer } from 'mobx-react-lite';
import userStore from '../stores/UserStore';
import orgsStore from '../stores/OrgsStore';
import BreadCrumbs from './BreadCrumbs';
import BasicBanner from './BasicBanner';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

// eslint-disable-next-line react/prop-types
function Layout({ children }) {
	const location = useLocation();
	const navigate = useNavigate();
	const [sidebarOpen, setSidebarOpen] = useState(false);

	useEffect(() => {
		verifyAdmin();
	}, [location.pathname]);

	const handleSignOutClick = (event) => {
		event.preventDefault();

		logout();
	};

	const path = location.pathname;

	const navigation = [
		{
			name: 'Home',
			icon: HomeIcon,
			current: path.match(/\/dashboard/),
			href: '/dashboard',
			showsFor: ['basic', 'pro', 'super', 'global'],
		},
		{
			name: 'Audience',
			icon: UsersIcon,
			current: path.match(/\/users|groups|invitations|authors/),
			showsFor: ['basic', 'pro', 'super', 'global'],
			children: [
				{
					name: 'Users',
					href: '/users',
					current: path.match(/\/users/),
					showsFor: ['basic', 'pro', 'super', 'global'],
				},
				{
					name: 'Groups',
					href: '/groups',
					current: path.match(/\/groups/),
					showsFor: ['basic', 'pro', 'super', 'global'],
				},
				{
					name: 'Invitations',
					href: '/invitations',
					current: path.match(/\/invitations/),
					hideFrom: ['global'],
					showsFor: ['basic', 'pro', 'super'],
				},
			],
		},
		{
			name: 'Content',
			icon: BookOpenIcon,
			current: path.match(/\/plans|practices|habits|resources|surveys/),
			showsFor: ['pro', 'super', 'global', 'basic'],
			children: [],
		},
		{
			name: 'Links',
			icon: LinkIcon,
			current: path.match(/\/links/),
			showsFor: ['basic', 'pro', 'super', 'global'],
			href: '/links',
		},
		// {
		// 	name: 'Messaging',
		// 	icon: ChatBubbleOvalLeftEllipsisIcon,
		// 	current: path.match(/\/channels/),
		// 	showsFor: ['basic', 'pro', 'super', 'global'],
		// 	href: '/channels',
		// },
		{
			name: 'Collections',
			icon: SquaresPlusIcon,
			current: path.match(/\/collections/),
			showsFor: ['basic', 'pro', 'super', 'global'],
			href: '/collections',
		},
		{
			name: 'Account',
			icon: Cog6ToothIcon,
			current: path.match(/\/settings|exports|authors/),
			showsFor: ['pro', 'super', 'global', 'basic'],
			children: [
				{
					name: 'Settings',
					current: path.match(/\/settings/),
					showsFor: ['basic', 'pro', 'super', 'global'],
					href: '/settings',
				},
				{
					name: 'Authors',
					href: '/authors',
					current: path.match(/\/authors/),
					showsFor: ['basic', 'pro', 'super', 'global'],
				},
				{
					name: 'CSV Exports',
					current: path.match(/\/exports/),
					showsFor: ['basic', 'pro', 'super'],
					href: '/exports',
				},
				{
					name: 'Billing',
					icon: CreditCardIcon,
					// current: path.match(/\/settings/),
					showsFor: ['basic', 'pro', 'super', 'global'],
					href: 'https://billing.stripe.com/p/login/8wM7tZ0dVemy6C4cMM',
				},
			],
		},
		{
			name: 'Help Center',
			icon: QuestionMarkCircleIcon,
			// current: path.match(/\/settings/),
			showsFor: ['basic', 'pro', 'super', 'global'],
			href: 'https://support.withtether.com/en/collections/9699163-for-organizations',
		},
	];

	if (orgsStore?.currentOrg?.appSettings?.enableSurveys) {
		if (navigation[2] && navigation[2].children) {
			navigation[2].children.unshift({
				name: 'Surveys',
				href: '/assessments',
				current: path.match(/\/assessments/),
				showsFor: ['basic', 'pro', 'super', 'global'],
			});
		}
	}

	if (orgsStore?.currentOrg?.appSettings?.enableHabits) {
		if (navigation[2] && navigation[2].children) {
			navigation[2].children.unshift({
				name: 'Habits',
				href: '/habits',
				current: path.match(/\/habits/),
				showsFor: ['basic', 'pro', 'super', 'global'],
			});
		}
	}

	if (orgsStore?.currentOrg?.appSettings?.enableLeaderResources) {
		if (navigation[2] && navigation[2].children) {
			navigation[2].children.unshift({
				name: 'Resources',
				href: '/resources',
				current: path.match(/\/resources/),
				showsFor: ['basic', 'pro', 'super', 'global'],
			});
		}
	}

	if (orgsStore?.currentOrg?.appSettings?.enableCourses) {
		if (navigation[2] && navigation[2].children) {
			navigation[2].children.unshift({
				name: 'Courses',
				href: '/courses',
				current: path.match(/\/courses/),
				showsFor: ['basic', 'pro', 'super', 'global'],
			});
		}
	}

	if (orgsStore?.currentOrg?.appSettings?.enablePractices) {
		if (navigation[2] && navigation[2].children) {
			navigation[2].children.unshift({
				name: 'Practices',
				href: '/practices',
				current: path.match(/\/practices/),
				showsFor: ['basic', 'pro', 'super', 'global'],
			});
		}
	}

	if (orgsStore?.currentOrg?.appSettings?.enablePlans) {
		if (navigation[2] && navigation[2].children) {
			navigation[2].children.unshift({
				name: 'Plans',
				href: '/plans',
				current: path.match(/\/plans/),
				showsFor: ['basic', 'pro', 'super', 'global'],
			});
		}
	}

	const superUserNavigation = [
		{
			name: 'Developer',
			icon: CommandLineIcon,
			current: path.match(/\/orgs|features|prompts|check_in_period_images|stock_images/),
			showsFor: ['super'],
			children: [
				{ name: 'Organizations', href: '/orgs', current: path.match(/\/orgs/), showsFor: ['super', 'global'] },
				{
					name: 'Imports',
					href: '/imports',
					current: path.match(/\/imports/),
					showsFor: ['super'],
				},
				{
					name: 'Features',
					href: '/features',
					current: path.match(/\/features/),
					showsFor: ['super'],
				},
				// {
				// 	name: 'Challenges',
				// 	href: '/challenges',
				// 	current: path.match(/\/challenges/),
				// 	showsFor: ['super'],
				// },
				// {
				// 	name: 'Stock Images',
				// 	href: '/stock_images',
				// 	current: path.match(/\/stock_images/),
				// 	showsFor: ['super'],
				// },
				// { name: 'Prompts', href: '/prompts', current: path.match(/\/prompts/), showsFor: ['super'] },
			],
		},
	];

	// const userNavigation = [
	// 	{ name: 'Sign out', href: '#', onClick: handleSignOutClick },
	// ];

	const onOrgPickerLocation =
		!location.pathname.includes('/orgs') &&
		!location.pathname.includes('/features') &&
		!location.pathname.includes('/check_in_period_images') &&
		!location.pathname.includes('/stock_images') &&
		!location.pathname.includes('/imports');

	const showOrgPicker =
		(userStore.user?.isSuperUser === true && onOrgPickerLocation) ||
		(userStore.user?.isSuperUser === false && orgsStore.orgs.length > 1);

	useEffect(() => {}, [useLocation.pathname]);

	const handleOrgSelect = (event) => {
		event.preventDefault();

		const orgId = parseInt(event.currentTarget.value);

		orgsStore.selectOrg(orgId);

		// Send back to dashboard or list view if switching org on a particular record
		if (location.pathname.includes('/plan')) {
			navigate('/plans');
		} else if (location.pathname.includes('/practice')) {
			navigate('/practices');
		} else if (location.pathname.includes('/prompt')) {
			navigate('/prompts');
		} else if (location.pathname.includes('/author')) {
			navigate('/authors');
		} else {
			navigate('/dashboard');
		}
		//TODO - TMP Workaround for accessing Org Subscription Tier in App.jsx
		setTimeout(() => {
			document.location.reload(true);
		}, 400);
	};
	const getTier = () => {
		if (userStore.user?.isSuperUser) {
			return 'super';
		}
		if (orgsStore.currentOrg?.tier === 'pro') {
			return 'pro';
		}
		return 'basic';
	};

	const shouldHide = (item) => {
		const currentTier = getTier();
		return !item.showsFor.includes(currentTier);
	};

	const orgCheck = (item) => {
		let org = orgsStore.currentOrg;
		return item?.hideFrom?.includes('global') && (org == null || org == undefined);
	};

	const renderGlobalDomain = () => {
		return (
			<p className="font-regular text-sm text-gray-500">
				<a
					className="font-regular text-sm text-gray-500"
					target="_blank"
					rel="noreferrer"
					href="https://app.withtether.com"
				>
					app.withtether.com
				</a>
			</p>
		);
	};
	const renderCustomDomain = () => {
		if (orgsStore.currentOrg?.groupsCustomDomain) {
			return (
				<p className="font-regular text-sm text-gray-500">
					<a
						className="font-regular text-sm text-gray-500"
						target="_blank"
						rel="noreferrer"
						href={`https://${orgsStore.currentOrg.groupsCustomDomain}`}
					>
						{`${orgsStore.currentOrg?.groupsCustomDomain}`}
					</a>
				</p>
			);
		} else if (orgsStore?.currentOrg?.subdomain) {
			return (
				<p className="font-regular text-sm text-gray-500">
					<a
						className="font-regular text-sm text-gray-500"
						target="_blank"
						rel="noreferrer"
						href={`https://${orgsStore.currentOrg.subdomain}.tether.site`}
					>
						{`${orgsStore.currentOrg.subdomain}.tether.site`}
					</a>
				</p>
			);
		}
	};

	return (
		<>
			<div>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
						</Transition.Child>

						<div className="fixed inset-0 flex z-40">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white ">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute top-0 right-0 mr-3 pt-6">
											<button
												type="button"
												className="ml-1 flex items-center justify-center h-8 w-8 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
												onClick={() => setSidebarOpen(false)}
											>
												<span className="sr-only">Close sidebar</span>
												<XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="pl-4 pr-1 border-b border-gray-200">
										<div className="flex flex-shrink-0 items-center pb-3">
											{/* <img className="h-20 w-20" src={'/tether-white-wordmark.svg'} alt="Tether" /> */}
											<img
												className="xs:h-8 xs:w-8 md:h-7 md:w-7 rounded-md mr-3 border"
												src={orgsStore.currentOrg?.imageUrl || '/logo192.png'}
												alt="Tether"
											/>
											<div className="flex-column">
												<h1 className="font-medium text-sm text-black">
													{orgsStore.currentOrg?.name || 'Tether Global'}
												</h1>
												<p className="text-xs truncate max-w-xs">
													{renderCustomDomain() || renderGlobalDomain()}
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 flex-1 overflow-y-auto">
										<nav className="flex-1 space-y-1 bg-white  px-2" aria-label="Sidebar">
											{navigation.map((item) =>
												!item.children ? (
													<div key={item.name}>
														<Link
															to={item.href}
															className={classNames(
																shouldHide(item) ? 'hidden' : '',
																item.current
																	? ' bg-gray-100 text-gray-900 '
																	: 'text-gray-900 hover:bg-gray-50  hover:text-gray-900 ',
																' group w-full flex items-center py-1.5 pl-2 text-base font-medium rounded-md ',
															)}
															onClick={() => setSidebarOpen(false)}
														>
															<item.icon
																className={classNames(
																	item.current
																		? 'text-neutral-900'
																		: 'text-gray-900 group-hover:text-gray-500 ',
																	'mr-3 flex-shrink-0 h-5 w-5',
																)}
																aria-hidden="true"
															/>
															{item.name}
														</Link>
													</div>
												) : (
													<Disclosure
														as="div"
														key={item.name}
														className="space-y-1"
														defaultOpen={item.current}
													>
														{({ open }) => (
															<>
																<Disclosure.Button
																	className={classNames(
																		shouldHide(item) ? 'hidden' : '',
																		'group w-full flex items-center pl-2 pr-1 py-1.5 text-left text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900 ',
																	)}
																>
																	<item.icon
																		className={classNames(
																			item.current
																				? 'text-neutral-900'
																				: 'text-neutral-900 group-hover:text-gray-500 ',
																			'mr-3 flex-shrink-0 h-5 w-5',
																		)}
																		aria-hidden="true"
																	/>
																	<span className="flex-1">{item.name}</span>
																	<svg
																		className={classNames(
																			item.current
																				? 'text-gray-900 rotate-90'
																				: 'text-neutral-500',
																			'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
																		)}
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 20 20"
																		fill="currentColor"
																	>
																		<path d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" />
																	</svg>
																</Disclosure.Button>
																<Transition
																	show={open}
																	enter="transition duration-100 ease-out"
																	enterFrom="transform scale-95 opacity-0"
																	enterTo="transform scale-100 opacity-100"
																	leave="transition duration-75 ease-out"
																	leaveFrom="transform scale-100 opacity-100"
																	leaveTo="transform scale-95 opacity-0"
																>
																	<Disclosure.Panel static className="space-y-1">
																		{item.children.map((subItem) => (
																			<Link
																				key={subItem.name}
																				to={subItem.href}
																				className={classNames(
																					shouldHide(subItem) ? 'hidden' : '',
																					subItem.current
																						? ' bg-gray-100 text-gray-900 '
																						: ' bg-white text-gray-900 hover:bg-gray-50  hover:text-gray-900 ',
																					' group flex w-full items-center rounded-md py-1.5 pl-10 pr-2 text-base font-medium text-gray-900 hover:bg-gray-50  hover:text-gray-900 ',
																				)}
																				onClick={() => setSidebarOpen(false)}
																			>
																				{subItem.name}
																			</Link>
																		))}
																	</Disclosure.Panel>
																</Transition>
															</>
														)}
													</Disclosure>
												),
											)}
											{!!orgsStore?.currentOrg?.launchHub && (
												<div
													onClick={() => window.open(orgsStore.currentOrg.launchHub)}
													className={
														'cursor-pointer bg-white  text-gray-900 hover:bg-gray-50   hover:text-gray-900  group w-full flex items-center pl-2 py-1.5 text-base font-medium rounded-md'
													}
												>
													<LinkIcon
														className="mr-3 h-6 w-6 flex-shrink-0 text-gray-900 group-hover:text-gray-500 "
														aria-hidden="true"
													/>
													Launch Hub
												</div>
											)}
											{userStore.user?.isSuperUser && (
												<div>
													{superUserNavigation.map((item) =>
														!item.children ? (
															<div key={item.name}>
																<Link
																	to={item.href}
																	className={classNames(
																		shouldHide(item) ? 'hidden' : '',
																		item.current
																			? 'bg-gray-100 text-gray-900 '
																			: 'bg-white  text-gray-900 hover:bg-gray-50   hover:text-gray-900 ',
																		'group w-full flex items-center pl-2 py-1.5 text-base font-medium rounded-md',
																	)}
																>
																	<item.icon
																		className={classNames(
																			item.current
																				? 'text-neutral-900'
																				: 'text-neutral-900 group-hover:text-gray-500 ',
																			'mr-3 flex-shrink-0 h-5 w-5',
																		)}
																		aria-hidden="true"
																	/>
																	{item.name}
																</Link>
															</div>
														) : (
															<Disclosure
																as="div"
																key={item.name}
																className="space-y-1"
																defaultOpen={item.current}
															>
																{({ open }) => (
																	<>
																		<Disclosure.Button
																			className={classNames(
																				shouldHide(item) ? 'hidden' : '',
																				'group w-full flex items-center pl-2 pr-1 py-1.5 text-left text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900 ',
																			)}
																		>
																			<item.icon
																				className={classNames(
																					item.current
																						? 'text-neutral-900'
																						: 'text-neutral-900 group-hover:text-gray-500 ',
																					'mr-3 flex-shrink-0 h-5 w-5',
																				)}
																				aria-hidden="true"
																			/>
																			<span className="flex-1">{item.name}</span>
																			<svg
																				className={classNames(
																					item.current
																						? 'text-gray-900 rotate-90'
																						: 'text-neutral-500',
																					'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
																				)}
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 20 20"
																				fill="currentColor"
																			>
																				<path d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" />
																			</svg>
																		</Disclosure.Button>
																		<Transition
																			show={open}
																			enter="transition duration-100 ease-out"
																			enterFrom="transform scale-95 opacity-0"
																			enterTo="transform scale-100 opacity-100"
																			leave="transition duration-75 ease-out"
																			leaveFrom="transform scale-100 opacity-100"
																			leaveTo="transform scale-95 opacity-0"
																		>
																			<Disclosure.Panel
																				static
																				className="space-y-1"
																			>
																				{item.children.map((subItem) => (
																					<Link
																						key={subItem.name}
																						to={subItem.href}
																						className={classNames(
																							shouldHide(subItem)
																								? 'hidden'
																								: '',
																							subItem.current
																								? 'bg-gray-100 text-gray-900 '
																								: 'bg-white  text-gray-900 hover:bg-gray-50   hover:text-gray-900 ',
																							'group flex w-full items-center rounded-md py-1.5 pl-10 pr-2 text-base font-medium text-gray-900 hover:bg-gray-50   hover:text-gray-900 ',
																						)}
																					>
																						{subItem.name}
																					</Link>
																				))}
																			</Disclosure.Panel>
																		</Transition>
																	</>
																)}
															</Disclosure>
														),
													)}
												</div>
											)}
											<div className="px-1">
												<div className={showOrgPicker ? '' : 'hidden'}>
													<label
														htmlFor="Organizations"
														className="block text-sm font-medium leading-4 pl-2 text-neutral-800 pt-8"
													>
														Organizations
													</label>
													<select
														name="Workspace Select"
														className="block focus:ring-blue-100 bg-neutral-15 font-medium focus:border-blue-100 w-full my-3 shadow-sm xs:text-base border-gray-300  text-black rounded-md"
														value={orgsStore.currentOrg?.id}
														onChange={handleOrgSelect}
													>
														<option
															key="org-blank"
															value=""
															className={
																userStore.user.isSuperUser === true ? '' : 'hidden'
															}
														>
															Tether Global
														</option>
														{orgsStore.orgs.map((org) => (
															<option key={`org-${org.id}`} value={org.id}>
																{org.name}
															</option>
														))}
													</select>
												</div>
											</div>
										</nav>
									</div>
									<div className="flex flex-shrink-0 pl-4 pt-6 pb-2 border-t border-gray-200">
										<a
											href={`https://${
												orgsStore.currentOrg?.groupsCustomDomain ||
												orgsStore.currentOrg?.subdomain + '.tether.site'
											}/profile`}
											className="group block w-full flex-shrink-0"
										>
											<div className="flex items-center">
												<div>
													<img
														className="h-11 w-11 rounded-full border"
														src={userStore.user?.profileImageUrl || '/user-avatar.png'}
														alt={userStore.user?.firstName}
													/>
												</div>
												<div className="ml-4">
													<p className="text-base font-medium leading-4 text-netural-800 pt-1">
														{userStore.user?.firstName} {userStore.user?.lastName}
													</p>
													<Link
														onClick={handleSignOutClick}
														className="text-sm font-regular text-neutral-500"
													>
														Sign Out
													</Link>
												</div>
											</div>
										</a>
									</div>
								</Dialog.Panel>
							</Transition.Child>
							<div className="flex-shrink-0 w-14" aria-hidden="true">
								{/* Dummy element to force sidebar to shrink to fit close icon */}
							</div>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="md:fixed hidden md:flex md:w-72 md:flex-col inset-y-0">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex flex-grow flex-col overflow-y-auto bg-white border-r border-gray-200 pt-0 pb-6">
						<div className="pl-4 pr-1 border-b border-gray-200">
							<div className="flex flex-shrink-0 items-center pt-3 pb-3">
								{/* <img className="h-20 w-20" src={'/tether-white-wordmark.svg'} alt="Tether" /> */}
								<img
									className="xs:h-8 xs:w-8 md:h-7 md:w-7 rounded-md mr-3 border"
									src={orgsStore.currentOrg?.imageUrl || '/logo192.png'}
									alt="Tether"
								/>
								<div className="flex-column">
									<h1 className="font-medium text-sm text-black">
										{orgsStore.currentOrg?.name || 'Tether Global'}
									</h1>
									<p className="text-xs max-w-[206px] truncate">
										{renderCustomDomain() || renderGlobalDomain()}
									</p>
								</div>
							</div>
						</div>
						<div className="mt-4 flex flex-grow flex-col pl-2 pr-4">
							<nav className="flex-1 space-y-1 px-2" aria-label="Sidebar">
								{navigation.map((item) =>
									!item.children ? (
										<div key={item.name}>
											<Link
												to={item.href}
												className={classNames(
													shouldHide(item) ? 'hidden' : '',
													item.current
														? 'bg-neutral-100 text-gray-900 font-medium'
														: 'text-gray-900 hover:text-neutral-600 font-medium',
													'group w-full flex items-center pl-3 py-1.5 text-base rounded-md',
												)}
											>
												<item.icon
													className={classNames(
														item.current ? 'text-gray-900 font-medium' : 'text-gray-900',
														'mr-3 ml-0 flex-shrink-0 h-5 w-5',
													)}
													aria-hidden="true"
												/>
												{item.name}
											</Link>
										</div>
									) : (
										<Disclosure
											as="div"
											key={item.name}
											className="space-y-1"
											defaultOpen={item.current}
										>
											{({ open }) => (
												<>
													<Disclosure.Button
														className={classNames(
															shouldHide(item) ? 'hidden' : '',
															'group w-full flex items-center pl-3 pr-2 py-1.5 text-left text-base rounded-md focus:outline-none',
															item.current ? 'text-gray-900 font-medium' : '',
														)}
													>
														<item.icon
															// className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500  focus:text-blue-500"
															className={classNames(
																item.current
																	? 'text-gray-900 font-medium'
																	: 'text-gray-900',
																'mr-3 ml-0 h-5 w-5 flex-shrink-0',
															)}
															aria-hidden="true"
														/>
														{/* Parent Styling */}
														<span
															className={classNames(
																item.current
																	? 'text-gray-900 font-medium'
																	: 'text-gray-900 hover:text-neutral-600 font-medium',
																'flex-1',
															)}
														>
															{item.name}
														</span>
														<svg
															className={classNames(
																item.current
																	? 'text-gray-900 rotate-90'
																	: 'text-neutral-500',
																'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
															)}
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 20 20"
															fill="currentColor"
														>
															<path d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" />
														</svg>
													</Disclosure.Button>
													<Transition
														show={open}
														enter="transition duration-100 ease-out"
														enterFrom="transform scale-95 opacity-0"
														enterTo="transform scale-100 opacity-100"
														leave="transition duration-75 ease-out"
														leaveFrom="transform scale-100 opacity-100"
														leaveTo="transform scale-95 opacity-0"
													>
														{/* Children Styling */}
														<Disclosure.Panel static className="space-y-1">
															{item.children.map((subItem) => (
																<Link
																	key={subItem.name}
																	to={subItem.href}
																	className={classNames(
																		shouldHide(subItem) ? 'hidden' : '',
																		orgCheck(subItem)
																			? 'hidden'
																			: subItem.current
																			? 'text-gray-900 font-medium bg-neutral-100 rounded-md'
																			: 'text-gray-900 hover:text-gray-800 font-medium',
																		'group flex w-full items-center py-1 pr-2 pl-14 text-base',
																	)}
																>
																	{subItem.name}
																</Link>
															))}
														</Disclosure.Panel>
													</Transition>
												</>
											)}
										</Disclosure>
									),
								)}
								{/* Hide Launch Hub */}
								{/* {!!orgsStore?.currentOrg?.launchHub && (
									<div
										onClick={() => window.open(orgsStore.currentOrg.launchHub)}
										className={
											'cursor-pointer text-neutral-500 hover:bg-neutral-800 hover:text-neutral-400 group w-full flex items-center pl-6 py-1.5 text-base font-medium rounded-lg'
										}
									>
										Launch Hub
									</div>
								)} */}
								{userStore.user?.isSuperUser && (
									<div>
										{superUserNavigation.map((item) =>
											!item.children ? (
												<div key={item.name}>
													<Link
														to={item.href}
														className={classNames(
															shouldHide(item) ? 'hidden' : '',
															item.current
																? 'text-gray-900 font-medium'
																: 'text-gray-900 hover:text-neutral-600 font-medium',
															'group w-full flex items-center pl-2 py-1.5 text-base font-medium rounded-md',
														)}
													>
														<item.icon
															className={classNames(
																item.current
																	? 'text-gray-500 '
																	: 'text-gray-300 group-hover:text-gray-400',
																'mr-3 ml-3 flex-shrink-0 h-6 w-6',
															)}
															aria-hidden="true"
														/>
														{item.name}
													</Link>
												</div>
											) : (
												<Disclosure
													as="div"
													key={item.name}
													className="space-y-1"
													defaultOpen={item.current}
												>
													{({ open }) => (
														<>
															<Disclosure.Button
																className={classNames(
																	shouldHide(item) ? 'hidden' : '',
																	'group w-full flex items-center pl-3 pr-2 py-1.5 text-left text-base rounded-md focus:outline-none',
																	item.current
																		? 'bg-neutral-100 text-gray-900 font-medium'
																		: '',
																)}
															>
																<item.icon
																	className={classNames(
																		item.current
																			? 'text-gray-900 font-medium'
																			: 'text-gray-900',
																		'mr-3 ml-0 h-5 w-5 flex-shrink-0',
																	)}
																	aria-hidden="true"
																/>
																<span
																	className={classNames(
																		item.current
																			? 'text-gray-900 font-medium'
																			: 'text-gray-900 hover:text-neutral-600 font-medium',
																		'flex-1',
																	)}
																>
																	{item.name}
																</span>
																<svg
																	className={classNames(
																		item.current
																			? 'text-gray-900 rotate-90'
																			: 'text-neutral-500',
																		'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
																	)}
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 20 20"
																	fill="currentColor"
																>
																	<path d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" />
																</svg>
															</Disclosure.Button>
															<Transition
																show={open}
																enter="transition duration-100 ease-out"
																enterFrom="transform scale-95 opacity-0"
																enterTo="transform scale-100 opacity-100"
																leave="transition duration-75 ease-out"
																leaveFrom="transform scale-100 opacity-100"
																leaveTo="transform scale-95 opacity-0"
															>
																<Disclosure.Panel static className="space-y-1">
																	{item.children.map((subItem) => (
																		<Link
																			key={subItem.name}
																			to={subItem.href}
																			className={classNames(
																				shouldHide(subItem) ? 'hidden' : '',
																				subItem.current
																					? 'text-gray-900 font-medium'
																					: 'text-gray-900 hover:text-gray-800 font-medium',
																				'group flex w-full items-center py-1 pr-2 pl-14 text-base',
																			)}
																		>
																			{subItem.name}
																		</Link>
																	))}
																</Disclosure.Panel>
															</Transition>
														</>
													)}
												</Disclosure>
											),
										)}
									</div>
								)}
							</nav>
						</div>
						<div className="pl-3 pr-3 pb-6">
							<div className={showOrgPicker ? '' : 'hidden'}>
								<label
									htmlFor="location"
									className="block text-sm font-medium leading-4 pl-2 text-neutral-800"
								>
									Organizations
								</label>
								<select
									className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-gray-200 shadow-sm ring-inset focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
									value={orgsStore.currentOrg?.id}
									onChange={handleOrgSelect}
								>
									<option
										key="org-blank"
										value=""
										className={userStore.user.isSuperUser === true ? '' : 'hidden'}
									>
										Tether Global
									</option>
									{orgsStore.orgs.map((org) => (
										<option key={`org-${org.id}`} value={org.id}>
											{org.name}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="flex flex-shrink-0 pl-4 pt-5 pb-0 border-t border-gray-200">
							<a
								href={`https://${
									orgsStore.currentOrg?.groupsCustomDomain ||
									orgsStore.currentOrg?.subdomain + '.tether.site'
								}/profile`}
								className="group block w-full flex-shrink-0"
							>
								<div className="flex items-center">
									<div>
										<img
											className="h-11 w-11 rounded-full border"
											src={userStore.user?.profileImageUrl || '/user-avatar.png'}
											alt={userStore.user?.firstName}
										/>
									</div>
									<div className="ml-4">
										<p className="text-base font-medium leading-4 text-netural-800 pt-1">
											{userStore.user?.firstName} {userStore.user?.lastName}
										</p>
										<Link
											onClick={handleSignOutClick}
											className="text-sm font-regular text-neutral-500"
										>
											Sign Out
										</Link>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div className="flex flex-col md:pl-72 bg-white ">
					<Alert />
					<div className="md:hidden sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white mb-4">
						<button
							type="button"
							className="px-4 text-gray-500  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-50 md:hidden"
							onClick={() => setSidebarOpen(true)}
						>
							<span className="sr-only">Open sidebar</span>
							<Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
						</button>
						<div className="flex-1 px-0 flex justify-between">
							<div className="flex-1 flex"></div>
							<div className="ml-4 flex items-center md:ml-6">
								<Menu as="div" className="ml-3 relative">
									{/* <div className="flex">
										<a
											target="_blank"
											rel="noreferrer"
											className="bg-white  mr-4 py-1.5 px-4 border border-gray-300  rounded-lg shadow-sm text-base font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
											href="https://app.withtether.com/"
										>
											Launch Space
										</a>
									</div> */}
								</Menu>
							</div>
						</div>
					</div>
					<div>
						<div className="md:py-4 bg-white">
							<BreadCrumbs />
						</div>
					</div>
				</div>
				<main className="md:pl-72 bg-white pb-12">
					<BasicBanner />
					{children}
				</main>
			</div>
		</>
	);
}

export default observer(Layout);
