import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';

function GoalsList() {
	const [goals, setGoals] = useState([]);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchGoals();
		}
	}, [orgsStore.currentOrg]);

	const fetchGoals = async () => {
		const goalsResponse = await tetherApi.getGoals();

		setGoals(goalsResponse.data);
	};

	const reduceGoalIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var goal = goals[parseInt(currentIndex)];

		await tetherApi.updateGoal(goal.id, { index: goal.index - 1 });

		fetchGoals();
	};

	const increaseGoalIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var goal = goals[parseInt(currentIndex)];

		await tetherApi.updateGoal(goal.id, { index: goal.index + 1 });

		fetchGoals();
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-3xl font-semibold text-gray-900 ">Goals</h1>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="/goals/new"
						className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
					>
						Add Goal
					</Link>
				</div>
			</div>
			<div className="mt-8 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300 ">
								<thead className="bg-gray-50 ">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6"
										>
											Title
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
										>
											Description
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Edit</span>
										</th>
									</tr>
								</thead>
								<tbody className="bg-white ">
									{goals.map((goal, i) => (
										<tr key={goal.id} className={i % 2 === 0 ? undefined : 'bg-gray-50 '}>
											<td className="py-4 pl-4 pr-3 text-sm text-gray-900  sm:pl-6 max-w-xs truncate">
												<ArrowUpCircleIcon
													data-index={i}
													className={
														i === 0
															? 'opacity-25 inline-block h-5 w-5 mr-4'
															: 'cursor-pointer inline-block h-5 w-5 mr-4'
													}
													onClick={i === 0 ? null : reduceGoalIndex}
												/>
												<ArrowDownCircleIcon
													data-index={i}
													className={
														i === goals.length - 1
															? 'opacity-25 inline-block h-5 w-5 mr-4'
															: 'cursor-pointer inline-block h-5 w-5 mr-4'
													}
													onClick={i === goals.length - 1 ? null : increaseGoalIndex}
												/>

												{goal.title}
											</td>
											<td className="px-3 py-4 text-sm text-gray-500  max-w-sm truncate">
												{goal.description}
											</td>
											<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
												<Link
													to={`/goals/${goal.id}`}
													className="text-neutral-800 hover:text-neutral-900"
												>
													Edit<span className="sr-only">, {goal.name}</span>
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(GoalsList);
