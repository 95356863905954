import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alertService, AlertType } from '../services/alert.service';
import { CheckCircleIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/20/solid';

const propTypes = {
	id: PropTypes.string,
	fade: PropTypes.bool,
};

const defaultProps = {
	id: 'default-alert',
	fade: true,
};

function Alert({ id, fade }) {
	const [alerts, setAlerts] = useState([]);

	useEffect(() => {
		// subscribe to new alert notifications
		const subscription = alertService.onAlert(id).subscribe((alert) => {
			// clear alerts when an empty alert is received
			if (!alert.message) {
				setAlerts((alerts) => {
					// filter out alerts without 'keepAfterRouteChange' flag
					const filteredAlerts = alerts.filter((x) => x.keepAfterRouteChange);

					// remove 'keepAfterRouteChange' flag on the rest
					filteredAlerts.forEach((x) => delete x.keepAfterRouteChange);
					return filteredAlerts;
				});
			} else {
				// add alert to array
				setAlerts((alerts) => [...alerts, alert]);

				// auto close alert if required
				if (alert.autoClose) {
					setTimeout(() => removeAlert(alert), 3000);
				}
			}
		});

		// clean up function that runs when the component unmounts
		return () => {
			// unsubscribe & unlisten to avoid memory leaks
			subscription.unsubscribe();
		};
	}, []);

	function removeAlert(alert) {
		if (fade) {
			// fade out alert
			const alertWithFade = { ...alert, fade: true };
			setAlerts((alerts) => alerts.map((x) => (x === alert ? alertWithFade : x)));

			// remove alert after faded out
			setTimeout(() => {
				setAlerts((alerts) => alerts.filter((x) => x !== alertWithFade));
			}, 250);
		} else {
			// remove alert
			setAlerts((alerts) => alerts.filter((x) => x !== alert));
		}
	}

	function cssColor(alert) {
		if (!alert) {
			return;
		}

		const alertTypeColor = {
			[AlertType.Success]: 'green',
			[AlertType.Error]: 'red',
			[AlertType.Info]: 'blue',
			[AlertType.Warning]: 'yellow',
		};

		return alertTypeColor[alert.type];
	}

	function icon(alert) {
		if (alert.type === AlertType.Success) {
			return <CheckCircleIcon className={`h-5 w-5 text-${cssColor(alert)}-400`} aria-hidden="true" />;
		} else {
			return <ExclamationTriangleIcon className={`h-5 w-5 text-${cssColor(alert)}-400`} aria-hidden="true" />;
		}
	}
	if (!alerts.length) {
		return null;
	}

	return (
		<div className="container fixed z-50 inset-x-1/4 top-10">
			<div className="w-9/12">
				{alerts.map((alert, index) => (
					<div key={index} className={`rounded-md bg-${cssColor(alert)}-50 p-4`}>
						<div className="flex">
							<div className="flex-shrink-0">{icon(alert)}</div>
							<div className="ml-3">
								<p className={`text-base font-medium text-${cssColor(alert)}-800`}>{alert.message}</p>
							</div>
							<div className="ml-auto pl-3">
								<div className="-mx-1.5 -my-1.5">
									<button
										type="button"
										className={`inline-flex bg-${cssColor(
											alert,
										)}-50 rounded-md p-1.5 text-${cssColor(alert)}-500 hover:bg-${cssColor(
											alert,
										)}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${cssColor(
											alert,
										)}-50 focus:ring-${cssColor(alert)}-600`}
									>
										<span className="sr-only">Dismiss</span>
										<XMarkIcon
											className={`h-5 w-5 text-${cssColor(alert)}-800`}
											aria-hidden="true"
											onClick={() => removeAlert(alert)}
										/>
									</button>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
export { Alert };
