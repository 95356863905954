import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { formatMoney, humanize } from '../../utils/stringUtils';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import { alertService } from '../../services/alert.service';
import { useSearchParams } from 'react-router-dom';
import Pagination from '../Pagination';

function BillingPage() {
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [subscription, setSubscription] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();

	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [invoices, setInvoices] = useState(null);

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

	useEffect(() => {
		// Detect callback
		if (searchParams.get('session_id')) {
			alertService.success('Payment method updated! Changes may take a moment to appear.');

			searchParams.delete('session_id');

			setSearchParams(searchParams);
		}

		if (!paymentMethod) {
			fetchPaymentMethod();
		}

		if (!subscription) {
			fetchSubscription();
		}

		if (!invoices) {
			fetchInvoices();
		}
	}, [orgsStore.currentOrg, paymentMethod, subscription, invoices]);

	const fetchPaymentMethod = async () => {
		const paymentMethodsResponse = await tetherApi.getPaymentMethods(1, 1);

		if (paymentMethodsResponse?.data?.[0]) {
			setPaymentMethod(paymentMethodsResponse.data[0]);
		}
	};

	const fetchSubscription = async () => {
		const subscriptionsResponse = await tetherApi.getSubscriptions(1, 1);

		if (subscriptionsResponse?.data?.[0]) {
			setSubscription(subscriptionsResponse.data[0] || '');
		} else {
			setSubscription('');
		}
	};

	const fetchInvoices = async () => {
		const invoicesResponse = await tetherApi.getInvoices(page, perPage);
		if (invoicesResponse) {
			setInvoices(invoicesResponse.data);

			const totalCount = invoicesResponse.total;
			const pagesCount = Math.ceil(totalCount / perPage);

			setTotal(totalCount);
			setNumPages(pagesCount);
			setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
			setEndIndex(perPage * (page - 1) + invoicesResponse.data.length);
		}
	};

	const handleUpdatePaymentClick = async () => {
		// Call your backend to create the Checkout session.
		const response = await tetherApi.createCheckoutSession();

		const sessionId = response.data.id;

		// When the customer clicks on the button, redirect them to Checkout.
		const stripe = await stripePromise;

		const { error } = await stripe.redirectToCheckout({ sessionId });

		if (error) {
			alertService.error(error.message);
		}
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<h3 className="text-lg leading-6 font-medium text-gray-900 ">Billing</h3>

			<div className={orgsStore.currentOrg?.stripeCustomerId ? 'hidden' : 'bg-white  px-4 py-5 sm:px-6'}>
				<h4 className="text-lg font- leading-6 text-gray-900 ">
					Your Tether account is not set up for billing
				</h4>

				<p className="text-md mt-4 ">
					Please email <a href="mailto:support@withtether.com">support@withtether.com</a> to get started.
				</p>
			</div>

			<div className={orgsStore.currentOrg?.stripeCustomerId ? 'bg-white  px-4 py-5 sm:px-6' : 'hidden'}>
				<h4 className="text-lg font- leading-6 text-gray-900 ">Payment Method</h4>
				<div className="overflow-hidden bg-white  px-4 py-4 shadow sm:rounded-md sm:px-6 flex items-center justify-between">
					<p className={paymentMethod ? 'text-lg ' : 'hidden'}>
						{paymentMethod && `${humanize(paymentMethod?.brand)} ending in ${paymentMethod?.lastFour}`}
					</p>

					<p className={paymentMethod ? 'hidden' : 'text-lg '}>No payment method on file</p>

					<p className="right">
						<button
							onClick={handleUpdatePaymentClick}
							className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
						>
							Update Payment Method
						</button>
					</p>
				</div>
			</div>

			<div className={orgsStore.currentOrg?.stripeCustomerId ? 'bg-white  px-4 py-5 sm:px-6' : 'hidden'}>
				<h4 className="text-lg font- leading-6 text-gray-900 ">Current Plan</h4>
				<div className="overflow-hidden bg-white  px-4 py-4 shadow sm:rounded-md sm:px-6">
					<p className={subscription ? 'text-lg ' : 'hidden'}>
						{subscription && `Tether ${humanize(subscription?.tier)} (Annual Contract)`}
					</p>

					<p className={subscription ? 'text-md ' : 'hidden'}>
						{subscription &&
							`${formatMoney(subscription?.invoiceAmountInCents)} ${humanize(
								subscription?.frequency,
							)} - renews on ${moment(subscription?.renewalDate).format('LL')}.`}
					</p>

					<p className={subscription ? 'hidden' : 'text-lg '}>Not currently subscribed</p>

					<p className="mt-2 text-sm text-gray-500 ">
						Email <a href="mailto:support@withtether.com">support@withtether.com</a> to change your plan.
					</p>
				</div>
			</div>

			<div className={orgsStore.currentOrg?.stripeCustomerId ? 'bg-white  px-4 py-5 sm:px-6' : 'hidden'}>
				<h4 className="text-lg font- leading-6 text-gray-900 ">Billing History</h4>

				<p className={invoices?.length ? 'hidden' : 'text-lg mt-4'}>No billing history</p>

				<div className={invoices?.length ? 'mt-8 flex flex-col' : 'hidden'}>
					<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
							<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300 ">
									<thead className="bg-gray-50 ">
										<tr>
											<th
												scope="col"
												className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6"
											>
												Date
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
											>
												Status
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
											>
												Amount
											</th>
											<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
												<span className="sr-only">Edit</span>
											</th>
										</tr>
									</thead>
									<tbody className="bg-white ">
										{invoices?.map((invoice, invoiceIdx) => (
											<tr
												key={invoice.id}
												className={invoiceIdx % 2 === 0 ? undefined : 'bg-gray-50 '}
											>
												<td className="py-4 pl-4 pr-3 text-sm text-gray-900  sm:pl-6 max-w-xs truncate">
													{moment(invoice.periodEndAt).format('LL')}
												</td>
												<td className="px-3 py-4 text-sm text-gray-500  max-w-sm truncate">
													{humanize(invoice.status)}
												</td>
												<td className="px-3 py-4 text-sm text-gray-500  max-w-sm truncate">
													{formatMoney(invoice.totalInCents)}
												</td>
												<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
													<a
														target="_blank"
														rel="noreferrer"
														href={invoice.hostedInvoiceUrl}
														className="text-neutral-800 hover:text-neutral-900"
													>
														View
													</a>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className={invoices?.length ? '' : 'hidden'}>
						<Pagination
							page={page}
							setPage={setPage}
							numPages={numPages}
							startIndex={startIndex}
							endIndex={endIndex}
							total={total}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(BillingPage);
