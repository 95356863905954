export const statusPill = (status) => {
	switch (status) {
		case 'draft':
			return 'bg-orange-100 text-orange-800';
		case 'published':
			return 'bg-green-100 text-green-800';
		case 'published-private':
			return 'bg-purple-100 text-purple-800';
		default:
			return 'bg-gray-100 text-gray-800';
	}
};
