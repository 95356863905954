import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import { humanize } from '../../utils/stringUtils';
import Pagination from '../Pagination';

function PromptsList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [prompts, setPrompts] = useState([]);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchPrompts();
		}
	}, [page, orgsStore.currentOrg]);

	const fetchPrompts = async () => {
		const promptsResponse = await tetherApi.getPrompts(page, perPage);

		setPrompts(promptsResponse.data);

		const totalCount = promptsResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + promptsResponse.data.length);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900 ">Prompts</h1>
					<p className="mt-2 text-sm text-gray-700 ">A list of all Prompts created by your organization.</p>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="/prompts/new"
						className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
					>
						Add Prompt
					</Link>
				</div>
			</div>
			<div className="mt-8 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300 ">
								<thead className="bg-gray-50 ">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6"
										>
											Prompt Type
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
										>
											Body
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
										>
											Featured
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Edit</span>
										</th>
									</tr>
								</thead>
								<tbody className="bg-white ">
									{prompts.map((prompt, promptIdx) => (
										<tr key={prompt.id} className={promptIdx % 2 === 0 ? undefined : 'bg-gray-50 '}>
											<td className="py-4 pl-4 pr-3 text-sm text-gray-900  sm:pl-6">
												{humanize(prompt.promptType)}
											</td>
											<td className="px-3 py-4 text-sm text-gray-500  max-w-sm truncate">
												{prompt.body}
											</td>
											<td className="px-3 py-4 text-sm text-gray-500 ">
												{prompt.isFeatured ? 'Yes' : 'No'}
											</td>
											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
												<Link
													to={`/prompts/${prompt.id}`}
													className="text-neutral-800 hover:text-neutral-900"
												>
													Edit<span className="sr-only">, {prompt.body}</span>
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(PromptsList);
