import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import { humanize } from '../../utils/stringUtils';
import moment from 'moment';
import { alertService } from '../../services/alert.service';
import Confirm from '../Confirm';
import Pagination from '../Pagination';

function ImportsList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [imports, setImports] = useState([]);
	const [importConfirm, setImportConfirm] = useState(false);
	const [processImportId, setProcessImportId] = useState(null);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchImports();
		}
	}, [page, orgsStore.currentOrg]);

	const tz = moment.tz.guess();

	const fetchImports = async () => {
		const importsResponse = await tetherApi.getImports(page, perPage);

		setImports(importsResponse.data);

		const totalCount = importsResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + importsResponse.data.length);
	};

	const processPreview = async (importId) => {
		if (importId) {
			const result = await tetherApi.updateImportProcessPreview(importId);

			if (result) {
				alertService.success('Preview processing!');

				setTimeout(() => fetchImports(), 2000);
			}
		}
	};

	const processImport = async () => {
		if (processImportId) {
			const result = await tetherApi.updateImportProcessImport(processImportId);

			if (result) {
				alertService.success('Import Processing!');

				setProcessImportId(null);
				setImportConfirm(false);

				setTimeout(() => fetchImports(), 2000);
			}
		}
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-3xl font-semibold text-gray-900 ">Imports</h1>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="/imports/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						New Import
					</Link>
				</div>
			</div>
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="">
									<tr>
										<th
											scope="col"
											className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
										>
											Organization
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Type
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Status
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Created
										</th>
										{/* <th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Updated
										</th> */}
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Action</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{imports.map((importRecord, importRecordIdx) => (
										<tr
											key={importRecord.id}
											className={importRecordIdx % 2 === 0 ? undefined : ''}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 max-w-xs truncate">
												{importRecord.org.name}
											</td>
											<td className="px-3 py-4 text-sm text-gray-500 max-w-xs truncate">
												{importRecord.title}
											</td>
											<td className="px-3 py-4 text-sm text-gray-500  max-w-xs truncate">
												{humanize(importRecord.importType)}
											</td>
											<td className="px-3 py-4 text-sm text-gray-500  max-w-sm truncate">
												{humanize(importRecord.status)}
											</td>
											<td className="px-3 py-4 text-sm text-gray-500  max-w-sm truncate">
												{moment.tz(importRecord.createdAt, tz).format('MMMM Do, YYYY')}
											</td>
											{/* <td className="px-3 py-4 text-sm text-gray-500  max-w-sm truncate">
												{moment.tz(importRecord.updatedAt, tz).format('MMMM Do, YYYY')}
											</td> */}
											<td className="relative py-4 pl-3 text-right text-sm font-medium">
												<Link
													to={`/imports/${importRecord.id}`}
													className="text-neutral-800 hover:text-neutral-900 mr-3"
												>
													View
												</Link>
												<button
													type="button"
													onClick={() => {
														processPreview(importRecord.id);
													}}
													className={
														importRecord.status == 'file_uploaded'
															? 'mr-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900'
															: 'hidden'
													}
												>
													Process Preview
												</button>
												<button
													type="button"
													onClick={() => {
														setImportConfirm(true);
														setProcessImportId(importRecord.id);
													}}
													className={
														importRecord.status == 'preview_complete'
															? 'mr-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900'
															: 'hidden'
													}
												>
													Process Import
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>

			<Confirm
				isOpen={importConfirm}
				title="Process Import"
				body="This will create and email Invitation records for the specified Org. Are you sure?"
				onConfirm={processImport}
				onCancel={() => setImportConfirm(false)}
			/>
		</div>
	);
}

export default observer(ImportsList);
