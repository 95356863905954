import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';

function AssessmentQuestionAnswerForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm();
	const { assessmentId, assessmentQuestionId, assessmentQuestionAnswerId } = useParams();
	const [assessmentQuestionAnswer, setAssessmentQuestionAnswer] = useState({});
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (assessmentQuestionAnswerId && !assessmentQuestionAnswer.id && orgsStore.orgs.length) {
			fetchAssessmentQuestionAnswer(assessmentQuestionId, assessmentQuestionAnswerId);
		}

		setSuccessMessage(assessmentQuestionAnswer ? 'Answer updated!' : 'Answer created!');
	}, [assessmentQuestionAnswer, orgsStore.currentOrg]);

	const fetchAssessmentQuestionAnswer = async (assessmentQuestionId, assessmentQuestionAnswer) => {
		const assessmentQuestionAnswerResponse = await tetherApi.getAssessmentQuestionAnswer(
			assessmentQuestionId,
			assessmentQuestionAnswer,
		);

		setAssessmentQuestionAnswer(assessmentQuestionAnswerResponse.data);

		reset(assessmentQuestionAnswerResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitAssessmentQuestionAnswer(data);

		if (result) {
			alertService.success(successMessage);

			navigate(`/assessments/${assessmentId}/assessment_questions/${assessmentQuestionId}`);
		}
	};

	const submitAssessmentQuestionAnswer = async (data) => {
		const formData = new FormData(document.getElementById('assessment-question-answer-form'));

		if (data.id) {
			return await tetherApi.updateAssessmentQuestionAnswer(assessmentQuestionId, data.id, formData);
		} else {
			return await tetherApi.createAssessmentQuestionAnswer(assessmentQuestionId, formData);
		}
	};

	const deleteAssessmentQuestionAnswer = async () => {
		const result = await tetherApi.deleteAssessmentQuestionAnswer(assessmentQuestionId, assessmentQuestionAnswerId);

		if (result) {
			alertService.success('Answer deleted');

			navigate(`/assessments/${assessmentId}/assessment_questions/${assessmentQuestionId}`);
		}
	};

	return (
		<form
			className="p-12 space-y-8 divide-y divide-gray-200 "
			id="assessment-question-answer-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-lg leading-6 font-medium text-gray-900 ">
							{assessmentQuestionAnswer ? 'Edit' : 'New'} Step
						</h3>
					</div>

					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200   sm:pt-5">
							<label
								htmlFor="answer"
								className="block text-sm font-medium text-gray-700  sm:mt-px sm:pt-2"
							>
								Answer
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('answer', { required: 'Answer is required' })}
									type="text"
									className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
								/>
								<p
									className={errors.answer?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="answer-error"
								>
									{errors.answer?.message}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							assessmentQuestionAnswerId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						to={`/assessments/${assessmentId}/assessment_questions/${assessmentQuestionId}`}
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Answer"
				body="This will delete all Assessment responses for this Answer and cannot be undone. Are you sure?"
				onConfirm={deleteAssessmentQuestionAnswer}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(AssessmentQuestionAnswerForm);
