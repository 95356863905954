import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import tetherApi from '../../api/tether';

function AssessmentPeriodResults() {
	const { assessmentId, assessmentPeriodId } = useParams();
	const [assessmentPeriod, setAssessmentPeriod] = useState(null);

	useEffect(() => {
		if (assessmentPeriodId && !assessmentPeriod) {
			fetchAssessmentPeriod(assessmentPeriodId);
		}
	}, [assessmentId, assessmentPeriodId, assessmentPeriod]);

	const fetchAssessmentPeriod = async (assessmentPeriodId) => {
		const assessmentPeriodResponse = await tetherApi.getAssessmentPeriod(assessmentId, assessmentPeriodId);

		if (assessmentPeriodResponse?.data) {
			setAssessmentPeriod(assessmentPeriodResponse.data);
		}
	};

	return (
		<div className="mx-8">
			<div className="border-b border-gray-200  pb-4">
				<h1 className="text-2xl font-semibold  text-gray-900 ">{assessmentPeriod?.assessment?.title}</h1>
				<p className="mt-1 text-sm font-regular text-gray-700 ">
					{moment(assessmentPeriod?.startDate).format('LL')} -{' '}
					{moment(assessmentPeriod?.endDate).format('LL')}
				</p>
			</div>

			<p
				className={
					assessmentPeriod && !assessmentPeriod.userAssessmentsResponsesSummary.length
						? 'text-lg my-8  font-regular'
						: 'hidden'
				}
			>
				No data available
			</p>

			{assessmentPeriod?.userAssessmentsResponsesSummary?.map((summary, index) => (
				<div className="" key={`item-${index}`}>
					<h3 className="text-lg  font-medium leading-6 text-gray-900  mt-4">{summary.question}</h3>
					<div className="overflow-hidden bg-white  shadow sm:rounded-md my-2 w-full max-w-3xl">
						<ul className="divide-y divide-gray-200 ">
							{summary?.responseSummaries?.map((responseSummary) => (
								<li key={responseSummary.assesmentQuestionAnswerId}>
									<div className="">
										<div className="px-4 py-4 sm:px-6 flex flex-row">
											<div
												className={`text-left ${
													summary.questionType === 'short_answer' ? '' : 'w-6/12'
												}`}
											>
												<span className="text-md font-medium text-gray-900 ">
													{responseSummary.answer}
												</span>
											</div>
											{summary.questionType !== 'short_answer' && (
												<div className="text-right w-6/12">
													<span className="truncate text-md font-medium text-gray-900 ">
														{responseSummary.responseCount}
													</span>
												</div>
											)}
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			))}

			<Link
				type="button"
				to={`/assessments/${assessmentId}/assessment_periods`}
				className="bg-white  py-2 px-4 mt-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
			>
				Back
			</Link>
		</div>
	);
}

export default observer(AssessmentPeriodResults);
