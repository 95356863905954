import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';

function ChannelsList() {
	const [channels, setChannels] = useState([]);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchChannels();
		}
	}, [orgsStore.currentOrg]);

	const fetchChannels = async () => {
		const channelsResponse = await tetherApi.getChannels();

		setChannels(channelsResponse.data);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-lg font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-2 lg:pb-0">Messaging</h1>
					<p className="text-sm text-gray-500">Broadcast messages to all or select groups of users</p>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="/channels/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
					>
						New Channel
					</Link>
				</div>
			</div>
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{channels.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No channels to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No channels are available.
										</p>
										<Link
											to="/users/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New Channel
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0 cursor-pointer"
											>
												Name
											</th>
											<th
												scope="col"
												className="py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
											>
												Users
											</th>
											<th
												scope="col"
												className="py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
											>
												All Users?
											</th>
											<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 font-regular">
												<span className="sr-only font-regular">Edit</span>
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{channels.map((channel, i) => (
											<tr key={channel.id} className={i % 2 === 0 ? undefined : 'bg-white'}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{channel.title}
												</td>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{channel.userCount}
												</td>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{channel.isDefault ? 'Yes' : 'No'}
												</td>
												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/channels/${channel.id}`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														Edit
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(ChannelsList);
