import React, { useEffect } from 'react';
import { processJwt, sendCodeToEmail, completeLoginWithEmail, auth0CreateAccount } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import userStore from '../stores/UserStore';
import tetherApi from '../api/tether';
import { Alert } from './Alert';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

const CreateAccountPage = () => {
	const {
		register: registerUser,
		handleSubmit: handleSubmitUser,
		formState: { isSubmitting: isSubmittingUser, isDirty: isDirtyUser, errors: errorsUser },
	} = useForm({});
	const {
		register: registerCode,
		handleSubmit: handleSubmitCode,
		formState: { isSubmitting: isSubmittingCode, isDirty: isDirtyCode, errors: errorsCode },
	} = useForm({});

	const [mode, setMode] = React.useState('inputUser');
	const [phone, setPhone] = React.useState(null);
	const [email, setEmail] = React.useState(null);
	const [processingCallback, setProcessingCallback] = React.useState(true);
	const [jwt, setJwt] = React.useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (window.location.hash !== '') {
			handleCallback();
		} else {
			handleRender();
		}
	}, [processingCallback, jwt]);

	const handleRender = async () => {
		if (userStore.getJwt()) {
			// Existing User visiting the page
			await userStore.fetchUser();

			if (userStore.user.orgs.length > 0) {
				return navigate('/');
			} else if (userStore.user.orgs.length === 0) {
				return navigate('/create-organization');
			}
		} else if (jwt) {
			// New user authenticated but needs to create User record
			const userData = JSON.parse(sessionStorage.getItem('user-data'));

			sessionStorage.removeItem('user-data');

			const result = await tetherApi.createUser(userData);

			if (result) {
				userStore.setJwt(jwt);

				await userStore.fetchUser();

				navigate('/create-organization');
			}
		}

		setProcessingCallback(false);
	};

	const handleCallback = async () => {
		setProcessingCallback(true);

		processJwt(auth0CreateAccount, async (jwtResult) => {
			setJwt(jwtResult);

			window.location.hash = '';
			window.history.replaceState('', '', window.location.pathname);
		});
	};

	const onUserSubmit = (data) => {
		// Temporarily store this since the Auth0 process reloads the page during callback
		sessionStorage.setItem('user-data', JSON.stringify(data));

		setPhone(data.phone);
		setEmail(data.email);

		sendCodeToEmail(auth0CreateAccount, data.email);

		setMode('inputCode');
	};

	const onCodeSubmit = (data) => {
		console.log(phone);
		completeLoginWithEmail(auth0CreateAccount, email, data.verificationCode);
	};

	return (
		<div
			className={
				processingCallback
					? 'hidden'
					: 'min-h-full h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-neutral-25 '
			}
		>
			<Alert />

			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<img
					className="mx-auto h-3.5 w-auto"
					src={
						window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
							? './tether-white-wordmark.svg'
							: './tether-wordmark-black.svg'
					}
					alt="Tether"
				/>
				<h2 className="mt-16 text-center xs:text-3xl text-4xl font-semibold tracking-tight text-black ">
					Create your account
				</h2>
				<p className={'mt-3 text-center font-medium text-base text-gray-600'}>
					or{' '}
					<a href="/login" className="font-medium text-base text-zinc-600 hover:text-zinc-500">
						back to sign in
					</a>
				</p>
			</div>

			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<div className="py-6 px-4 sm:rounded-lg sm:px-10">
					<form
						key={1}
						className={mode === 'inputUser' ? 'space-y-6' : 'hidden'}
						id="user-form"
						onSubmit={handleSubmitUser(onUserSubmit)}
					>
						<div>
							<label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
								First Name
							</label>
							<div className="mt-1">
								<input
									{...registerUser('firstName', { required: true })}
									type="text"
									autoComplete="firstName"
									placeholder="First Name"
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
								/>
								<p
									className={errorsUser.firstName ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="firstName-error"
								>
									{errorsUser.firstName &&
										errorsUser.firstName.type === 'required' &&
										'First Name is required'}
								</p>
							</div>
						</div>
						<div>
							<label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
								Last Name
							</label>
							<div className="mt-1">
								<input
									{...registerUser('lastName', { required: true })}
									type="text"
									autoComplete="lastName"
									placeholder="Last Name"
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
								/>
								<p
									className={errorsUser.lastName ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="lastName-error"
								>
									{errorsUser.lastName &&
										errorsUser.lastName.type === 'required' &&
										'Last Name is required'}
								</p>
							</div>
						</div>
						<div>
							<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
								Email
							</label>
							<div className="mt-1">
								<input
									{...registerUser('email', {
										required: true,
										pattern: /\S+@\S+\.\S+/,
									})}
									type="text"
									autoComplete="email"
									placeholder="Email"
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
								/>
								<p
									className={errorsUser.email ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="email-error"
								>
									{errorsUser.email && errorsUser.email.type === 'required' && 'Email is required'}
									{errorsUser.email &&
										errorsUser.email.type === 'pattern' &&
										'Email must be correctly formatted'}
								</p>
							</div>
						</div>
						<div>
							<label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
								Phone Number
							</label>
							<div className="mt-1">
								<input
									{...registerUser('phone', {
										required: 'Phone is required',
										maxLength: 10,
										pattern: /\d{10}/,
									})}
									type="text"
									autoComplete="phone"
									placeholder="Phone Number"
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
								/>
								<p
									className={errorsUser.phone ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="phone-error"
								>
									{errorsUser.phone && errorsUser.phone.type === 'required' && 'Phone is required'}
									{errorsUser.phone &&
										errorsUser.phone.type === 'pattern' &&
										'Phone must be exactly ten digits'}
								</p>
							</div>
						</div>

						<div>
							<button
								type="submit"
								disabled={!isDirtyUser || isSubmittingUser}
								className="disabled:opacity-25 flex w-full justify-center rounded-md bg-neutral-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
							>
								Continue
							</button>
						</div>
					</form>

					<form
						key={2}
						className={mode === 'inputCode' ? 'space-y-6' : 'hidden'}
						id="code-form"
						onSubmit={handleSubmitCode(onCodeSubmit)}
					>
						<div>
							<label
								htmlFor="verificationCode"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Code
							</label>
							<div className="mt-1">
								<input
									{...registerCode('verificationCode', {
										required: true,
										pattern: /\d{6}/,
									})}
									type="numeric"
									placeholder="Enter Code"
									className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
								/>
								<p
									className={errorsCode.verificationCode ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="verificationCode-error"
								>
									{errorsCode.verificationCode &&
										errorsCode.verificationCode.type === 'required' &&
										'Code is required'}
									{errorsCode.verificationCode &&
										errorsCode.verificationCode.type === 'pattern' &&
										'Code must be exactly six digits'}
								</p>
							</div>
							<p className="text-gray-500 pt-4 text-sm">A six-digit code was just sent to: {email}</p>
						</div>

						<div>
							<button
								type="submit"
								disabled={!isDirtyCode || isSubmittingCode}
								className="disabled:opacity-25 flex w-full justify-center rounded-md bg-neutral-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
							>
								Submit
							</button>

							<button
								type="reset"
								className="mt-4 disabled:opacity-25 flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-neutral-800 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600 border border-gray-200"
								onClick={() => {
									setMode('inputUser');
								}}
							>
								Back
							</button>
						</div>
					</form>
				</div>
			</div>
			<div className="mt-2 sm:mx-auto sm:max-w-sm justify-center">
				<p className="mt-2 text-center text-sm text-gray-800  font-regular">
					By creating an account, you agree to our{' '}
					<a
						href="https://www.withtether.com/legal/terms-and-conditions"
						className="text-zinc-900 hover:text-zinc-500 font-medium"
					>
						Terms and Conditions
					</a>
					&nbsp;and&nbsp;
					<a
						href="https://www.withtether.com/legal/privacy-policy"
						className="text-zinc-900 hover:text-zinc-500 font-medium"
					>
						Privacy Policy
					</a>
				</p>
			</div>
		</div>
	);
};

export default observer(CreateAccountPage);
